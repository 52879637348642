/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import APIRequest from '+services/api-services';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Table from '+shared/Table';
import { getDate, getTime, history, logError, capitalizeFirst } from '+utils';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import Modal from '+shared/Modal';
import '../index.scss';

const apiRequest = new APIRequest();

const Assignees = ({ roleDetails }) => {
  const { id: roleId } = useParams();
  const { feedbackInit } = useFeedbackHandler();
  const [page, setPage] = React.useState(1);
  const [state, setState] = useState({
    sortingParams: {},
    limit: 10
  });
  const [modal, setModal] = useState({
    modalType: null,
    modalInfo: null
  });

  const {
    data: resolvedData,
    refetch,
    isFetching
  } = useQuery(
    [`ASSIGNEES_${roleId}`, page, state.limit, state.sortingParams],
    () => apiRequest.getAllRoleAssignees(roleId, page, state.limit, state.sortingParams),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          title: 'Assignees',
          message: 'There has been an error getting assignees',
          type: 'danger',
          action: {
            action: () => {
              refetch();
            },
            name: 'Try again'
          }
        });
      }
    }
  );

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging || [];

  const removeUserFromRoleUserMutation = useMutation(({ value }) => apiRequest.removeUserFromRole(value), {
    onError: (error) => {
      logError(error);
      feedbackInit({
        message: `There has been an error removing this user.`,
        type: 'danger'
      });
    }
  });

  const navigateToUserDetails = (userId) => history.push(`/dashboard/users/${userId}`);

  const setUnassignModal = (id, userName) => {
    setModal({
      modalType: 'unassign',
      modalInfo: {
        heading: 'Remove assignee from role?',
        content: (
          <p>
            Please confirm that you want to unassign <span style={{ fontWeight: 700 }}> {userName}</span>. Their role would become
            undefined.
          </p>
        ),
        completedHeading: 'Removed!',
        completedDescription: `You have successfully unassigned ${userName} from the ${capitalizeFirst(roleDetails.name || '')}`,
        action: () => {
          return removeUserFromRoleUserMutation.mutateAsync({
            user_id: id,
            roles: [roleId]
          });
        },
        secondButtonText: 'Yes, Remove',
        completedModalSize: 'base',
        equalFooterBtn: true,
        headerBottomBorder: false
      }
    });
  };

  const assignees = {
    className: '  --white assignee-table div-table-assignee',
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no assignees yet.',
    annotations: 'assignee(s)',
    fields: (iter) => {
      const name = `${iter?.firstName} ${iter?.lastName}`;
      return {
        data: {
          full_name: <span>{name}</span>,
          email: (
            <span className="smaller no-ellipsis" role="button">
              {iter?.email}
            </span>
          ),
          date_assigned: (
            <>
              <span>{getDate(iter?.dateAssigned)}</span>
              <span className="annotation" style={{ marginLeft: '5px', color: 'rgba(74, 74, 74, 0.5)' }}>
                {getTime(iter?.dateAssigned)}
              </span>
            </>
          ),
          option: (
            <span className="more-option">
              <span
                role="button"
                tabIndex={0}
                onClick={() => navigateToUserDetails(iter?.userId)}
                onKeyDown={() => navigateToUserDetails(iter?.userId)}
              >
                See More
              </span>
              <span className="option-divider" />
              <span
                role="button"
                onClick={() => setUnassignModal(iter?.userId, name)}
                tabIndex={0}
                onKeyDown={() => setUnassignModal(iter?.userId, name)}
              >
                Remove
              </span>
            </span>
          )
        }
      };
    }
  };

  const tableDataKeys = Object.keys(assignees.fields({}).data);
  return (
    <div>
      {isFetching ? (
        <LoadingPlaceholder type="table" />
      ) : (
        <Table
          className={assignees.className || ''}
          loading={isFetching}
          data={data}
          renderFields
          hasPagination
          tableHeadings={tableDataKeys}
          annotation={assignees.annotations}
          current={state.paginationPage}
          pageSize={paging?.page_size || 0}
          totalItems={paging?.total_items || 0}
          limitAction={(limit) => setState({ limit })}
          actionFn={(current) => setPage(current)}
          emptyStateHeading={assignees.emptyStateHeading || ''}
          tableWrapperClassName="table-responsive"
          emptyStateMessage={assignees.emptyStateMessage || ''}
          persistState
          type="payouts_history"
          filterTotalCount={paging?.total_items}
          hasFilter={false}
        >
          {assignees.fields}
        </Table>
      )}
      <Modal
        visible={modal.modalType}
        secondButtonColor="red"
        close={() => setModal({ modalType: null, modalInfo: {} })}
        heading={modal.modalInfo?.heading}
        description={modal.modalInfo?.description}
        content={modal.modalInfo?.content}
        size="mdBase"
        secondButtonText={modal.modalInfo?.secondButtonText}
        secondButtonAction={() => modal.modalInfo?.action()}
        completedHeading={modal.modalInfo?.completedHeading}
        completedDescription={modal.modalInfo?.completedDescription}
        secondaryCompletedModal
        completedModalSize={modal.modalInfo?.completedModalSize}
        equalFooterBtn={modal.modalInfo?.equalFooterBtn}
        headerBottomBorder={modal.modalInfo?.headerBottomBorder}
      />
    </div>
  );
};

export default Assignees;
