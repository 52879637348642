import DropdownIcon from '+assets/img/dashboard/down.svg';
import SettingsIcon from '+assets/img/dashboard/settings.svg';
import useClickOutside from '+hooks/useClickOutside';
import useSetUserAccess from '+hooks/useSetUserAccess';
import { isAllowed } from '+utils';
import React, { useState } from 'react';

interface IActionModalProps {
  setModal?: () => void;
  details?: Record<string, any>;
}

const ActionModal = ({
  setModal = () => null,
  details,
}: IActionModalProps) => {
  const [actionModal, setActionModal] = useState(false);
  const userAccess = useSetUserAccess();
  const wrapperRef = useClickOutside(() => {
    setActionModal(false);
  });

  const sentinalStatus = {
    undefined: 'choose-merchant-class',
    true: 'disable-sentinal',
    false: 'enable-sentinal'
  }

  return (
    <div ref={wrapperRef}>
      {isAllowed(userAccess, ['merchant_status.update']) ? (
        <button
          type="button"
          className="ellipsis__icon"
          data-testid="manage-merchant"
          onClick={() => {
            setActionModal((value) => !value);
          }}
        >
          <div className="header-actions">
            <img alt="" src={SettingsIcon} />
            <span>Manage Merchant</span>
            <img alt="" src={DropdownIcon} />
          </div>
        </button>
      ) : (
        ''
      )}

      {actionModal && (
        <ul className="element-box ellipsis__nav merchant-ellipsis">
          <li
            role="presentation"
            className="ellipsis__item"
            onClick={() => {
              setModal({ modalType: 'change-risk' });
              setActionModal(false);
            }}
          >
            <span>Change Risk Rating</span>
          </li>
          {details?.status === 'active' && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: 'suspend-merchant' });
                setActionModal(false);
              }}
            >
              <span>Suspend Merchant</span>
            </li>
          )}

          <li
            role="presentation"
            className="ellipsis__item"
            onClick={() => {
              setModal({ modalType: sentinalStatus[details.sentinalEnabled] });
              setActionModal(false);
            }}
          >
            <span>{details.sentinalEnabled ? 'Deactivate' : 'Activate'} Sentinal</span>
          </li>

          {details?.status === 'active' && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: 'deactivate-merchant' });
                setActionModal(false);
              }}
            >
              <span style={{ color: '#F32345', fontWeight: 600 }}>Deactivate Merchant</span>
            </li>
          )}

          {['deactivated', 'unsupported'].includes(details?.status) && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: 'activate-merchant' });
                setActionModal(false);
              }}
            >
              <span style={{ color: '#7447FD', fontWeight: 600 }}>Activate Merchant</span>
            </li>
          )}
          {details?.status === 'suspended' && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: 'revoke-merchant' });
                setActionModal(false);
              }}
            >
              <span style={{ color: '#7447FD', fontWeight: 600 }}>Revoke Suspension</span>
            </li>
          )}
          {details?.status === 'active' && !['rejected', 'verified'].includes(details?.kyc_status) && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: 'reject-merchant' });
                setActionModal(false);
              }}
            >
              <span>Reject Merchant KYC</span>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default ActionModal;
