// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';
// import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import { createRoot } from 'react-dom/client';

import AppWrapper from './App';
// import ErrorPage from './containers/Dashboard/Shared/ErrorPage';

import '+styles/main.scss';
import 'bootstrap/dist/css/bootstrap.css';

// datadogRum.init({
//   applicationId: process.env.REACT_APP_DARKHORSE_BASE ?? '',
//   clientToken: process.env.REACT_APP_DARKHORSE_CLIENT ?? '',
//   site: process.env.REACT_APP_DARKHORSE_URL ?? 'us5.datadoghq.com',
//   service: 'internal-dashboard',
//   env: process.env.REACT_APP_DARKHORSE_AREA ?? 'staging',
//   version: '1.0.0',
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 100,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: 'mask-user-input'
// });

// datadogRum.startSessionReplayRecording();
// Bugsnag.start({
//   apiKey: process.env.REACT_APP_HERMES_WINGS,
//   plugins: [new BugsnagPluginReact()],
//   onError(event) {
//     event.addMetadata('application', {
//       name: 'BvnkNG Admin Dashboard'
//     });
//   }
// });
// this is to prevent console.log from working on production
// if (process.env.NODE_ENV === 'production') {
//   console.log = function () { };
// }
// const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);
root.render(
  // <ErrorBoundary FallbackComponent={ErrorPage}>
  <AppWrapper />
  // </ErrorBoundary>
);
