/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import BusinessAvatar from '+assets/img/dashboard/customer-avatar.svg';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import AdvanceExportModal from '+shared/AdvanceExportModal';
import EmptyState from '+shared/EmptyState';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Modal from '+shared/Modal';
import SecondaryDetails from '+shared/SecondaryDetails';
import Table from '+shared/Table';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  daysfromToday,
  formatAmount,
  getDate,
  getTime,
  history,
  isAllowed,
  logError
} from '+utils';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import '../index.scss';

const api = new APIRequest();

export default function SettlementDetails() {
  const { id } = useParams();
  const { feedbackInit } = useFeedbackHandler();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const location = useLocation();
  const userAccess = useSetUserAccess(location);

  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || 1;

  const {
    data: detailsData,
    isLoading: isFetchingDetails,
    refetch: refetchDetails
  } = useQuery(`${id.toUpperCase()}_DETAILS`, () => api.getSettlement(id), {
    onError: () => {
      feedbackInit({
        message: "There has been an error getting this settlement's information",
        type: 'danger'
      });
    }
  });

  const { data: settlementTrxns, isFetching } = useQuery(
    [`${id.toUpperCase()}_TRANSACTIONS`, page],
    () => api.getSingleSettlementTransactions(id, page),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: 'There has been an error getting merchant settlement transactions',
          type: 'danger'
        });
      }
    }
  );

  const successfulHandler = (type, message) => {
    refetchDetails();
    feedbackInit({
      title: type === 'approve' ? 'Approve Settlements:' : 'Process Settlements:',
      message,
      type: 'success'
    });
  };

  const errorHandler = (error, type) => {
    logError(error);
    setIsConfirmModalVisible(false);
    feedbackInit({
      title: type === 'approve' ? 'Approve Settlements:' : 'Process Settlements:',
      message: error.response.data.message,
      type: 'danger'
    });
    throw error;
  };

  const approveSettlementMutation = useMutation((settlementId) => api.approveSettlement(settlementId), {
    retry: false,
    onSuccess: (res) => successfulHandler('approve', res.message),
    onError: (e) => errorHandler(e, 'approve')
  });

  const processSettlementMutation = useMutation((settlementId) => api.processSettlement(settlementId), {
    retry: false,
    onSuccess: (res) => successfulHandler('process', res.message),
    onError: (e) => errorHandler(e, 'process')
  });

  const paging = settlementTrxns?.paging || {};
  const transactions = settlementTrxns?.data || [];
  const data = detailsData?.data || {};

  const handleSettlement = async (type) => {
    if (type === 'approve') {
      approveSettlementMutation.mutate(id);
    } else {
      processSettlementMutation.mutate(id);
    }
  };

  const exportSettlementTransactions = async (format) => {
    try {
      const res = await api.exportSettlementTransactions(id, format);
      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Settlement ${id} transactions at ${getDate(Date.now())}`, type);
    } catch (error) {
      logError(error);
      setExportModalVisible(false);
      feedbackInit({
        message: `There has been an error exporting your transactions`,
        type: 'danger'
      });
    }
  };

  const getSettlementStatus = ({ is_processed, is_approved }) => {
    if (is_processed) return 'Settled';
    if (is_approved) return 'Approved';
    return 'Pending';
  };

  const settlementStatus = getSettlementStatus(data);
  const settlementDetails = [
    {
      'Total Transaction Amount': `${formatAmount(data.transactions_amount)} ${data.currency}`,
      'Transaction Fees': `${formatAmount(data.fee)} ${data.currency}`,
      'Transaction VAT': `${formatAmount(data.vat)} ${data.currency}`,
      ...(settlementStatus !== 'Settled' && {
        'Settlement Amount': `${formatAmount(data.amount || 0)} ${data.currency}`,
        'Amount to Settle': (
          <span>
            <strong>{`${formatAmount(data.amount_settled || 0)} ${data.currency}`}</strong>
          </span>
        )
      }),
      ...(settlementStatus === 'Settled' && {
        'Settlement Amount': `${formatAmount(data.amount_settled || 0)} ${data.currency}`,

        'Settlement Fee (+ VAT)': `${formatAmount(
          parseFloat(data.settlement_payout?.fee || 0, 10) + parseFloat(data.settlement_payout?.vat || 0, 10)
        )} ${data.currency}`
      }),
      ...(data?.sentinal_settlement && {
        'Sentinal VAT': `${formatAmount(+data.sentinal_settlement.total_vat)} ${data.currency}`,
        'Sentinal Fee': `${formatAmount(+data.sentinal_settlement.total_processing_fee)} ${data.currency}`
      })
    },
    {
      'Settlement Status': (
        <>
          <span
            className={`status-pill smaller align-baseline mr-2 ${data.is_processed ? 'green' : data.is_approved ? 'blue' : 'yellow'}`}
          />
          {settlementStatus}
        </>
      ),
      Merchant: data.account?.name ? capitalize(data.account?.name) : 'Not Available',
      'Payment Method': data.payment_method
        ? `${capitalizeRemovedash(data.payment_method)}${data.payment_method === 'card' ? ' Payment' : ''}`
        : data.category
          ? `${capitalize(data.category)} Payment`
          : 'Not Available',
      'Settlement Cycle': capitalizeRemovedash(data.type || 'Not Available'),
      'Date Created': (
        <>
          {getDate(data.createdAt)}
          <span className="smaller lighter" style={{ marginLeft: '5px' }}>
            {getTime(data.createdAt)}
          </span>
        </>
      ),
      ...(settlementStatus !== 'Settled' && {
        'Expected Date': data.expected_settlement_date ? daysfromToday(data.expected_settlement_date) : 'Not Available'
      }),
      ...(settlementStatus === 'Settled' && {
        'Settled To':
          data.destination && data.destination.type === 'settlement_account' ? (
            <span className="smaller lighter">
              {data.destination.value ? `${data.destination.value.bank_name} - ${data.destination.value.account_number}` : 'Not Available'}
            </span>
          ) : (
            'BvnkNG Balance'
          ),
        'Date Settled': data.processed_at ? (
          <>
            {getDate(data.processed_at)}
            <span className="smaller lighter" style={{ marginLeft: '5px' }}>
              {getTime(data.processed_at)}
            </span>
          </>
        ) : (
          'Not yet settled'
        )
      })
    }
  ];

  const reserveDetails = [
    {
      'Reserve Percentage': `${data?.rolling_reserve?.hold_rate}%`,
      'Reserved Amount': (
        <span>
          <strong>{`${formatAmount(data?.rolling_reserve?.amount)} ${data.currency}`}</strong>
        </span>
      ),
      'Hold/Reserve Period': `${data?.rolling_reserve?.hold_period || 0} Day${data?.rolling_reserve?.hold_period === 1 ? '' : 's'}`
    },
    {
      [data?.rolling_reserve?.reserved_at ? 'Reserve Date' : 'Created Date']: (
        <>
          {getDate(data?.rolling_reserve?.reserved_at || data?.rolling_reserve?.created_at)}
          <span className="smaller lighter" style={{ marginLeft: '5px' }}>
            {getTime(data?.rolling_reserve?.reserved_at || data?.rolling_reserve?.created_at)}
          </span>
        </>
      ),
      'Expected Release Date': (
        <>
          {getDate(data?.rolling_reserve?.available_on)}
          <span className="smaller lighter" style={{ marginLeft: '5px' }}>
            {getTime(data?.rolling_reserve?.available_on)}
          </span>
        </>
      )
    }
  ];

  const settlementTransactions = {
    className: '--settlement-txn-table',
    rowURL: '/dashboard/pay-ins',
    rowKey: 'reference',
    emptyStateHeading: 'No transactions yet',
    emptyStateMessage: 'There are no transactions in this settlement record.',
    annotations: 'transaction(s)',
    fields: (iter) => ({
      data: {
        transaction_id: <span className="trxn-id">{iter.payment?.reference?.toUpperCase()}</span>,
        customer: <span className="lighter">{iter.payment?.customer?.name}</span>,
        transaction_date: (
          <>
            <span>{getDate(iter.created_at)}</span>
            <span className="annotation">{getTime(iter.created_at)}</span>
          </>
        ),
        collected: (
          <>
            <span className="semibold">{formatAmount(iter.amount_charged)}</span>
            <span className="annotation">{iter.currency}</span>
          </>
        ),
        'Fee (+ Vat)': (
          <>
            <span className="semibold">{formatAmount(parseFloat(iter.fee || 0) + parseFloat(iter.vat || 0))}</span>
            <span className="annotation">{iter.currency}</span>
          </>
        ),
        net_amount: (
          <>
            <span className="semibold">{formatAmount(iter.amount_paid)}</span>
            <span className="annotation">{iter.currency}</span>
          </>
        )
      }
    })
  };

  const tableDataKeys = Object.keys(settlementTransactions.fields({}).data);

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {!isFetchingDetails && (
            <button type="button" className="btn btn-link" onClick={history.goBack}>
              <i className="phosphor-icon phosphor-icon-arrow-left" />
              <span>Back</span>
            </button>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="content-details-box">
                <div className="content-details-head --stl-details-head">
                  <div className="content-details-title">
                    <div className="user-avatar-w">
                      <div className="user-avatar">
                        <img alt="" src={BusinessAvatar} />
                      </div>
                    </div>
                    <div className="cd-title ml-3">
                      <h3 className="cd-maintitle" style={{ textTransform: 'uppercase' }}>
                        {data.reference}
                      </h3>
                      <div className="cd-subtitle">{data.account?.name}</div>
                    </div>
                  </div>
                  <div className="cd-action">
                    {isAllowed(userAccess, ['settlement.export']) ? (
                      <button
                        type="button"
                        className="btn btn-secondary"
                        hidden={transactions.length === 0}
                        onClick={() => setExportModalVisible(true)}
                      >
                        <i className="phosphor-icon phosphor-icon-arrow-up-right" />
                        <span>Export Transactions</span>
                      </button>
                    ) : (
                      ''
                    )}
                    {settlementStatus !== 'Settled' && (
                      <>
                        <span className="divider-sm" />

                        {(data.is_processed
                          ? true
                          : data.is_approved
                            ? isAllowed(userAccess, ['settlement.process'])
                            : isAllowed(userAccess, ['settlement.approve'])) && (
                            <button
                              className={`btn ${data.is_approved ? 'btn-success' : 'btn-primary'}`}
                              type="button"
                              onClick={() => setIsConfirmModalVisible(true)}
                            >
                              <i className="phosphor-icon phosphor-icon-check" />
                              <span className="ml-2">
                                {data.is_processed ? 'Settled' : data.is_approved ? 'Settle' : 'Approve settlement'}
                              </span>
                            </button>
                          )}
                      </>
                    )}
                  </div>
                </div>
                {isFetchingDetails ? (
                  <LoadingPlaceholder type="text" content={4} />
                ) : (
                  <>
                    {Object.keys(data || {}).length > 0 ? (
                      <>
                        <SecondaryDetails title="Settlement Summary" data={settlementDetails} />
                        {settlementStatus === 'Settled' && Object.keys(data?.rolling_reserve || {}).length > 0 && (
                          <SecondaryDetails title="Reserve Summary" data={reserveDetails} />
                        )}
                      </>
                    ) : (
                      <EmptyState message="There is an issue getting this settlement's information" />
                    )}
                  </>
                )}

                <div className="content-details-body mt-4 --stl-details-body">
                  <Table
                    header={`Transactions (${paging?.total_items || 'null'})`}
                    className={settlementTransactions.className || ''}
                    loading={isFetching}
                    data={transactions}
                    renderFields
                    hasPagination
                    tableHeadings={tableDataKeys}
                    annotation={settlementTransactions.annotations}
                    current={page}
                    rowKey={settlementTransactions.rowKey}
                    rowURL={settlementTransactions.rowURL}
                    pageSize={paging?.page_size || 0}
                    totalItems={paging?.total_items || 0}
                    actionFn={(current) => searchQuery.setQuery({ page: current })}
                    emptyStateHeading={settlementTransactions.emptyStateHeading || ''}
                    tableWrapperClassName="table-responsive"
                    emptyStateMessage={settlementTransactions.emptyStateMessage || ''}
                    filterExportAction={exportSettlementTransactions}
                    type="settlement"
                    hasFilter={false}
                  >
                    {settlementTransactions.fields}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {exportModalVisible && (
        <AdvanceExportModal
          openExport={exportModalVisible}
          setOpenExport={setExportModalVisible}
          exportAction={exportSettlementTransactions}
          type="settlement-detail"
          status={settlementStatus}
        />
      )}
      {isConfirmModalVisible && (
        <Modal
          close={() => setIsConfirmModalVisible(false)}
          heading="Confirm Settlement"
          description={
            <>
              <p className="my-3">
                Are you sure you want to{' '}
                {data.is_approved ? 'disburse this settlement batch to the merchant?' : 'approve this settlement batch?'}
              </p>
              <p className="m-0">This action cannot be undone.</p>
            </>
          }
          size="sm"
          secondButtonText="Yes, Proceed"
          secondButtonAction={() => (data.is_approved ? handleSettlement('process') : handleSettlement('approve'))}
          completedHeading={data.is_approved ? 'Settlement processed!' : 'Settlement approved!'}
          completedDescription={
            data.is_approved
              ? 'This settlement batch has been successfully disbursed to the merchant'
              : 'This settlement batch has been approved'
          }
        />
      )}
    </>
  );
}
