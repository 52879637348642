import { useQuery } from 'react-query';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import { capitalize, capitalizeFirst, truncateString } from '+utils';

const apiRequest = new APIRequest();
export default function useGetRoles(type: string) {
  const { feedbackInit } = useFeedbackHandler();
  const { data: resolvedData, refetch } = useQuery(['ROLES'], () => apiRequest.getAllRoles(1, 1000, {}, true), {
    select: (data) =>
      data &&
      data?.data?.map(({ name, id }: { name: string; id: string }) => ({
        label: capitalizeFirst(truncateString(`${capitalize(name.replace(/custom-/gi, ''))}`, 18)),
        value: id
      })),
    onError: () => {
      feedbackInit({
        title: 'Users',
        message: 'There has been an error getting roles',
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    },
    enabled: ['users', 'roles'].includes(type)
  });
  const roleData = resolvedData || [];
  return { roleData };
}
