/* eslint-disable prettier/prettier */
import FileUpload from '+assets/img/dashboard/file-upload.svg';
import InfoIcon from '+assets/img/dashboard/information-button.svg';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import CSVFileReader, { CSVParsedDataT } from '+shared/CSVFileReader';
import ReactSelectDropdown from '+shared/ReactSelectDropdown';
import ToolTip from '+shared/Tooltip';
import useChargebackStore, { IChargebackData, chargebackContentT } from '+store/chargebackStore';
import { processorT } from '+types';
import { cleanInput, history, switchCurrency } from '+utils';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import './index.scss';

const apiRequest = new APIRequest();

const currencyOptions = Object.entries(switchCurrency).map((currency) => {
  return {
    label: currency[1],
    value: currency[0],
    isDisabled: !['NGN', 'USD'].includes(currency[0])
  };
});
const MultipleChargeback = () => {
  const { feedbackInit } = useFeedbackHandler();
  const [csvData, setCSVData] = useState<chargebackContentT[]>([]);
  const [formData, setFormData] = useState<IChargebackData>({
    currency: '',
    processor: '',
    deadline: '',
    chargebacks: []
  });

  const setChargebackData = useChargebackStore((state) => state.setChargebackData);
  const clearChargebackData = useChargebackStore((state) => state.clearChargebackData);

  const {
    data: processorOptions,
    refetch,
    isLoading
  } = useQuery([`PROCESSORLIST`], () => apiRequest.getProcessorList(), {
    keepPreviousData: true,
    refetchOnMount: 'always',
    select: (value: { data: processorT[] }) => {
      const options = value.data.map((item) => ({ label: item.name, value: item.slug }));
      return options;
    },
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching processors`,
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    }
  });

  const parseData = (value: string | null) => (Object.is(value, null) ? value : cleanInput(value || ''));

  const formatData = (data: CSVParsedDataT) => {
    const result = data.map((item: string[]) => {
      return {
        id: uuidv4(),
        processor_reference: parseData(item[0])?.trim(),
        amount: parseData(item[1]),
        reason: parseData(item[2])
      };
    });
    return result as chargebackContentT[];
  };

  const validateForm = () => {
    const { currency, processor, deadline } = formData;
    if (!currency || !processor || !csvData.length || !deadline) {
      return true;
    }
    return false;
  };

  const handleGoBack = () => {
    clearChargebackData();
    history.goBack();
  };

  const handleNavigateToSummary = () => {
    setChargebackData({
      ...formData,
      deadline: new Date(new Date(formData.deadline).toUTCString()).toISOString().split('.')[0],
      chargebacks: csvData as chargebackContentT[]
    });
    history.push('/dashboard/disputes/chargeback/summary');
  };
  return (
    <div className="element-box">
      <div className="row">
        <div className="col-sm-12">
          <button type="button" className="btn btn-link" onClick={handleGoBack}>
            <i className="phosphor-icon phosphor-icon-arrow-left" />
            <span style={{ fontWeight: 500 }}>Go Back</span>
          </button>
        </div>
      </div>
      <div className=" upload_wrapper">
        <div className="col-lg-6">
          <div className="upload-header-wrapper">
            <span>
              <img src={FileUpload} alt="file-upload" />
            </span>
            <h5>Upload Chargeback CSV file</h5>
            <p>Upload a CSV with the appropriate entry information in each cell.</p>
          </div>
          <div className="upload-form-wrapper">
            <div className="form-group">
              <ReactSelectDropdown
                label="Currency"
                onChange={(value) => setFormData({ ...formData, currency: value as string })}
                placeholder="-- Select currency --"
                options={currencyOptions}
                value={formData?.currency}
              />
            </div>

            {!isLoading && (
              <div className="form-group">
                <ReactSelectDropdown
                  label={() => (
                    <span style={{ display: 'flex', alignItems: 'baseline' }}>
                      <span className="dark">Processor</span>
                      <ToolTip type="permissions" image={InfoIcon} message="Payment facilitator of the requested the chargeback(s)." />
                    </span>
                  )}
                  onChange={(value) => setFormData({ ...formData, processor: value as string })}
                  placeholder="-- Select processor --"
                  options={processorOptions || []}
                  value={formData?.processor}
                />
              </div>
            )}
            <div className="form-group">
              <label htmlFor="deadline" className="withdraw-label">
                <span className="dark">Chargeback deadline</span>
              </label>
              <input
                type="datetime-local"
                className="form-control"
                name="deadline"
                value={formData?.deadline}
                min={`${new Date().toISOString().split(':')[0]}:${new Date().toISOString().split(':')[1]}`}
                placeholder="-- Select a date --"
                onChange={(e) => {
                  const formattedAmount = cleanInput(e.target.value);
                  setFormData({ ...formData, deadline: `${formattedAmount}:00` });
                }}
              />
            </div>
            <div className="form-group">
              <CSVFileReader onChange={(value: CSVParsedDataT) => setCSVData(formatData(value))} />
              <p className="upload-info">Please note that only a maximum of 50 chargeback entries are allowed in a single upload</p>
            </div>
          </div>
          <div className="button_wrapper">
            <button className="btn btn-primary" type="button" onClick={handleGoBack}>
              <span>Cancel</span>
            </button>
            <button className="btn btn-primary" type="button" onClick={handleNavigateToSummary} disabled={validateForm()}>
              <span>Add Chargeback</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleChargeback;
