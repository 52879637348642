/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';
import { logError, getDate, getTime, durationToday, durationMonth, durationWeek } from '+utils';
import Table from '+dashboard/Shared/Table';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import '../../index.scss';

const api = new APIRequest();
interface IUserActivityProps {
  userId?: string | number | null;
  userName?: string | null;
}

const UserActivity = ({ userId, userName } : IUserActivityProps ) => {
  const { feedbackInit } = useFeedbackHandler();

  const [state, setState] = useState({
    paginationPage: 1,
    tabs: {
      today: durationToday(),
      this_week: durationWeek(),
      this_month: durationMonth(),
      all_time: [null, null]
    },
    activeTab: 'all_time',
    limit: 25
  });

  const {
    data: audits,
    isLoading,
    isFetching,
    refetch
  } = useQuery(
    [`AUDIT_LOGS_${userId}`, state.paginationPage, state.limit, ...state.tabs[state.activeTab]],
    () => api.getLogs(state.paginationPage, state.limit, ...state.tabs[state.activeTab], userId),
    {
      keepPreviousData: true,
      onError: (error) => {
        if (error) {
          logError(error);
          feedbackInit({
            message: `There has been an error getting this ${userName ?? 'user'} activity log`,
            type: 'danger',
            action: {
              action: () => refetch(),
              name: 'Try again'
            }
          });
        }
      },
      enabled: !!userId
    }
  );

  const paging = audits?.paging;
  const paginate = (currentPage) => {
    setState({ ...state, paginationPage: currentPage });
  };
  const handlePageSize = (pageSize) => {
    setState({ ...state, limit: pageSize });
  };

  const renderEvents = () => {
    return (
      <>
        <table className="table table-lightborder recentActivityTable">
          <tbody>
            {audits?.data?.map((event) => (
              <tr key={Math.floor(1000 + Math.random() * 9000)}>
                <td style={{ width: '22%', color: '#354253' }}>
                  {`${getDate(event.created_at)} `} <span className="--activity-time">{getTime(event.created_at)}</span>
                </td>
                <td style={{ width: '52%', color: '#354253' }}>{event.description}</td>
                <td className="text-right lighter">{event.event_id}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {audits?.data?.map((event) => (
          <div key={Math.floor(1000 + Math.random() * 9000)} className="div-table --row auditLogTable mobile">
            <div className="--txn-status">
              <span className="body-row-header">Timestamp:</span>
              <span>{`${getDate(event.createdAt)}, ${getTime(event.createdAt)}`}</span>
            </div>
            <div>
              <span className="body-row-header">Event:</span>
              <span>{event.description}</span>
            </div>
            <div>
              <span className="body-row-header">Event ID:</span>
              <span>{event.event_id}</span>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="userdetails-container">
      <div className="tab-pane active" id="tab_activity">
        <Table
          className="table table-lightborder auditLogTable"
          hasPagination
          header={false}
          tableHeadings={['Timestamp', 'Event', 'Event ID']}
          totalItems={paging?.total_items || 0}
          pageSize={paging?.page_size || 0}
          loading={isLoading || isFetching}
          loaderBackground="#f5f6f6"
          current={paging?.current}
          actionFn={paginate}
          limitAction={handlePageSize}
          annotation="actions"
          tableWrapperClassName="table-responsive"
          emptyStateHeading="There are no logs yet"
          hasFilter={false}
        >
          {renderEvents()}
        </Table>
      </div>
    </div>
  );
};

export default UserActivity;
