/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '+shared/Modal';
import APIRequest from '+services/api-services';
import { logError, capitalizeFirst, isAllowed } from '+utils';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import UsersPermissionsTable from '../../components/UsersPermissionsTable';
import '../index.scss';

interface IRolePermissionProps {
  roleDetails: Record<string, any>;
}

const apiRequest = new APIRequest();
const RolePermission = ({ roleDetails }: IRolePermissionProps ) => {
  const queryClient = useQueryClient();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const roleName = roleDetails?.name;
  const roleId = roleDetails?.id;
  const [modalVisible, setModalVisible] = useState(false);
  const [updatedPermissions, setUpdatedPermissions] = useState();

  const isSystemRole = roleDetails?.category === 'system';

  const updateRoleMutation = useMutation(
    () =>
      apiRequest.updateRole(roleId, {
        name: roleName,
        permissions: updatedPermissions
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`${roleId}_ROLE_DETAILS`]);
        setUpdatedPermissions();
      },
      onError: (error) => {
        logError(error);
        feedbackInit({
          message: `There has been an error updating this role`,
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const switchModal = () => {
    const content = {
      heading: `Update role?`,
      content: (
        <p>{`Please confirm that you want to update ${isSystemRole ? 'system' : 'custom'} role with name ${capitalizeFirst(
          roleName ?? ''
        )}`}</p>
      ),
      size: 'md',
      firstButtonText: 'Cancel',
      secondButtonText: 'Yes, Update',
      secondButtonAction: () => {
        closeFeedback();
        return updateRoleMutation.mutateAsync();
      },
      equalFooterBtn: true,
      headerBottomBorder: false,
      completedModalSize: 'base',
      completedHeading: 'Updated!',
      completedDescription: (
        <p>{`You have successfully updated ${isSystemRole ? 'system' : 'custom'} role - ${capitalizeFirst(roleName ?? '')}`}</p>
      ),
      secondaryCompletedModal: true
    };

    return content;
  };

  const onUpdateRole = () => {
    setModalVisible(true);
  };

  return (
    <div className="user-new-invite">
      <div className="col-sm-12">
        <UsersPermissionsTable
          userDetails={roleDetails}
          getSelectedPermission={setUpdatedPermissions}
          readOnly={isSystemRole}
          type="role"
        />
        {isAllowed(userAccess, ['custom_roles.update', 'my_custom_roles.update', 'system_roles.update']) && !isSystemRole && (
          <button type="button" className="btn btn-save-role" onClick={onUpdateRole} disabled={!updatedPermissions}>
            Update Role
          </button>
        )}
      </div>
      <Modal visible={modalVisible} {...switchModal()} close={() => setModalVisible(false)} />
    </div>
  );
};

export default RolePermission;