/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  capitalize,
  capitalizeRemovedash,
  formatProductConfigStatus,
  getDate,
  getTime,
  productMapping,
  switchStatus,
  filteredOutObjectProperty,
  queriesParams
} from '+utils';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import { currencyType, productCategoriesType, productConfigTableDataType, productType } from '+types';
import RiskIndicator from '../../components/RiskIndicator';

const api = new APIRequest();
const MerchantsAccessTable = () => {
  const { currency, product, method } = useParams<{ currency: currencyType; product: productCategoriesType; method: productType }>();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();

  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || 25;
  const keyword = searchQuery.value.keyword || undefined;
  const selectedConfig = searchQuery.value.selectedConfig || undefined;
  const status = searchQuery.value.status || undefined;

  const sortingParams = {
    limit,
    page,
    type: selectedConfig,
    merchant_name: keyword,
    payment_type: productMapping[product],
    payment_method: method,
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.tab,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.dateFrom,
      queriesParams.dateTo,
      queriesParams.status,
      queriesParams.keyword,
      queriesParams.selectedConfig
    ])
  };

  const { data, refetch, isLoading } = useQuery(
    [`${currency}_MERCHANTS`, limit, page, sortingParams],
    () => api.getMerchantList(currency, sortingParams),
    {
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error in getting this currency's merchants`,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const merchantsCurrency = {
    className: '--merchant-currency-table',
    emptyStateHeading: 'No Merchants yet',
    data: data?.data || [],
    emptyStateMessage: 'There are no Merchants',
    rowKey: 'id',
    fields: (each: productConfigTableDataType) => ({
      data: {
        Merchant: <span id="merchant-name">{capitalize(each.name)}</span>,
        Email: <span id="merchant-email">{capitalize(each.email)}</span>,
        Risk_Level: <RiskIndicator riskLevel={each.risk_level} />,
        Currency_Status: (
          <>
            <span className={`status-pill smaller ${switchStatus(formatProductConfigStatus(each.status))}`} />
            <span>{capitalizeRemovedash(formatProductConfigStatus(each.status))}</span>
          </>
        ),
        Configuration_Type: <span className="lighter">{capitalize(each.configuration_type)}</span>,
        Date_Added: (
          <>
            <span>{getDate(each.created_at)}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {getTime(each.created_at)}
            </span>
          </>
        )
      }
    })
  };

  return (
    <div className="table-section">
      <Table
        className={merchantsCurrency.className}
        data={merchantsCurrency?.data || []}
        tableHeadings={['Merchant', 'Email', 'Risk Level', 'Currency Status', 'Configuration Type', 'Date Added']}
        loading={isLoading}
        renderFields
        hasPagination
        rowKey={merchantsCurrency?.rowKey}
        pageSize={data?.paging?.page_size}
        totalItems={data?.paging?.total_items}
        current={parseInt(page, 10)}
        hideTable={merchantsCurrency?.data?.length === 0}
        filterKeywordPlaceholder="Search merchant..."
        tableWrapperClassName="table-responsive table-wrapper"
        emptyStateHeading={merchantsCurrency?.emptyStateHeading || ''}
        emptyStateMessage={merchantsCurrency.emptyStateMessage || ''}
        actionFn={(currentPage) => searchQuery.setQuery({ page: String(currentPage) })}
        limitAction={(currentLimit) => searchQuery.setQuery({ limit: String(currentLimit) })}
        filterHasAdvancedFilter={false}
        filterName={`merchants have access to ${currency} currency`}
        type="product-config"
        filterShowExport={false}
      >
        {merchantsCurrency.fields}
      </Table>
    </div>
  );
};

export default MerchantsAccessTable;
