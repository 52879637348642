import React, { useState } from 'react';
import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';
import { logError, getDate, getTime, durationToday, durationMonth, durationWeek } from '+utils';
import Table from '+shared/Table';
import TabSwitch from '+shared/TabSwitch';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import { useSearchQuery } from '+hooks';
import { Nullable } from 'src/types';
import './index.scss';

interface IEvent {
  created_at: string;
  event: string;
  event_id: string;
  description: string;
}

type TabsT = 'Last 24 Hours' | 'Last 7 Days' | 'Last 30 Days' | 'All Time';

const api = new APIRequest();

const AuditLogs = () => {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '25';
  const activeTab = searchQuery.value.activeTab || 'All Time';

  const tabs: Record<TabsT, Nullable<string>[]> = {
    'Last 24 Hours': durationToday(),
    'Last 7 Days': durationWeek(),
    'Last 30 Days': durationMonth(),
    'All Time': [null, null]
  };

  const {
    data: audits,
    isLoading,
    isFetching,
    refetch
  } = useQuery(['AUDIT_LOGS', page, limit, ...tabs[activeTab as TabsT]], () => api.getLogs(page, limit, ...tabs[activeTab as TabsT]), {
    keepPreviousData: true,
    onError: (error) => {
      if (error) {
        logError(error);
        feedbackInit({
          message: `There has been an error getting this admin activity log`,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  });

  const paging = audits?.paging;
  const paginate = (currentPage) => {
    searchQuery.setQuery({ page: currentPage });
  };

  const renderEvents = (event: IEvent) => {
    return {
      data: {
        created_at: (
          <div style={{ margin: 0, padding: 0 }}>
            {getDate(event?.created_at)}&nbsp;<span style={{ color: '#A9AFBC' }}>{getTime(event.created_at)}</span>
          </div>
        ),
        event: (
          <div style={{ margin: 0, padding: 0 }}>
            <span style={{ fontWeight: 500 }}>{event?.event}</span> : {event?.description}
          </div>
        ),
        event_id: <>{event?.event_id}</>
      }
    };
  };

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="os-tabs-w mb-3">
          <div className="os-tabs-controls">
            <ul className="nav">
              <li className="nav-item">
                <h4 className="element-header audit__header d-flex align-items-center" style={{ color: '#94a7b7', fontSize: '16px' }}>
                  Events
                </h4>
              </li>
            </ul>
            {Object.keys(tabs).length > 0 && (
              <TabSwitch
                options={Object.keys(tabs || {})}
                activeTab={activeTab}
                setTab={(value: TabsT) => searchQuery.setQuery({ activeTab: value})}
                className="ml-3"
                id="audit__logs"
              />
            )}
          </div>
          <div className="tab-content">
            <div className="tab-pane active" id="tab_activity">
              <Table
                className="--audit-log-table"
                hasPagination
                header={false}
                tableHeadings={['Timestamp', 'Event', 'Event ID']}
                totalItems={paging?.total_items || 0}
                pageSize={paging?.page_size || 0}
                loading={isFetching}
                current={parseInt(page, 10)}
                actionFn={paginate}
                annotation="actions"
                emptyStateHeading="There are no audit logs yet"
                emptyStateMessage="You do not have any audit logs at the moment."
                tableWrapperClassName="table-responsive"
                hasFilter={false}
                renderFields
                data={audits?.data || []}
              >
                {renderEvents}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditLogs;
