/* eslint-disable react/jsx-props-no-spreading */
import calendar from '+assets/img/dashboard/calendar.svg';
import { useClickOutside, useReducerState } from '+hooks';
import { IDateFilterProps } from '+types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { LegacyRef, useEffect } from 'react';
import { CustomDateRangeCalendar, formatDate } from './CustomDateRangeCalendar';

import 'react-calendar/dist/Calendar.css';
import './SecondaryFilter.scss';

dayjs.extend(customParseFormat);

const dateOptions = ['Today', 'Last 7 days', 'Last 30 days', 'Last 90 days', 'All Time', 'Custom Range'] as const;

interface IDateFilterState {
  type: (typeof dateOptions)[number] | string;
  visible: boolean;
  startDate?: string;
  endDate?: string;
}

const DateFilter = (props: IDateFilterProps) => {
  const { selectedDate, onDateChange } = props;

  const getDateType = (startDate: string, endDate: string) => {
    if (!startDate && !endDate) return null;
    if (dayjs().diff(dayjs(startDate), 'day') === 0) return 'Today';
    if (dayjs().diff(dayjs(startDate), 'day') === 7) return 'Last 7 days';
    if (dayjs().diff(dayjs(startDate), 'day') === 30) return 'Last 30 days';
    if (dayjs().diff(dayjs(startDate), 'day') === 90) return 'Last 90 days';
    if (startDate === 'all_time' && endDate === 'all_time') return 'All Time';
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  };
  const [state, setState] = useReducerState<IDateFilterState>({
    type: dateOptions[0],
    visible: false,
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate
  });
  useEffect(() => {
    setState({ type: getDateType(selectedDate.startDate, selectedDate.endDate) ?? dateOptions[0] });
  }, [selectedDate.startDate, selectedDate.endDate]);

  useEffect(() => {
    if (props.isCleared) {
      setState({ type: dateOptions[0], startDate: '', endDate: '' });
      onDateChange({ startDate: '', endDate: '' });
    }
  }, [props.isCleared]);

  const wrapperRef = useClickOutside(() => {
    setState({ ...state, visible: false });
  });

  const handleSelectDateOption = (dateOption: (typeof dateOptions)[number]) => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    switch (dateOption) {
      case 'Today':
        setState({ type: dateOption, endDate: currentDate, startDate: currentDate, visible: false });
        onDateChange({ startDate: currentDate, endDate: currentDate });
        break;
      case 'Last 7 days':
        setState({
          type: dateOption,
          endDate: currentDate,
          startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
          visible: false
        });
        onDateChange({
          startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
          endDate: currentDate
        });
        break;
      case 'Last 30 days':
        setState({
          type: dateOption,
          endDate: currentDate,
          startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          visible: false
        });
        onDateChange({
          startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          endDate: currentDate
        });
        break;
      case 'Last 90 days':
        setState({
          type: dateOption,
          endDate: currentDate,
          startDate: dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
          visible: false
        });
        onDateChange({
          startDate: dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
          endDate: currentDate
        });
        break;
      case 'All Time':
        setState({ type: dateOption, startDate: 'all_time', endDate: 'all_time', visible: false });
        onDateChange({ startDate: 'all_time', endDate: 'all_time' });
        break;
      case 'Custom Range':
        setState({ type: dateOption, visible: false });
        break;
      default:
        break;
    }
  };

  return (
    <div ref={wrapperRef as LegacyRef<HTMLDivElement>} className="secondary-datefilter">
      <label
        htmlFor="dateFilter"
        className="form-group filter-object filter-object-xxl flex-grow-1 w-auto --no-max-width --search-container"
        role="presentation"
        onClick={() => setState({ visible: !state.visible })}
        onKeyDown={() => setState({ visible: !state.visible })}
      >
        <img src={calendar} alt="calendar icon" aria-hidden aria-label="calendar" />
        <input
          title="dateFilter"
          data-testid="dateFilter"
          aria-label="dateFilter"
          name="dateFilter"
          className="form-control"
          type="text"
          value={state.type}
          readOnly
        />
        <i className="phosphor-icon phosphor-icon-arrow-down2" />
      </label>
      {state.visible && (
        <section className="secondary-datefilter--option element-box ">
          <ul>
            {dateOptions.map((dateOption) => (
              <li
                key={dateOption}
                role="presentation"
                onClick={() => handleSelectDateOption(dateOption)}
                onKeyDown={() => handleSelectDateOption(dateOption)}
              >
                <span>{dateOption}</span>
              </li>
            ))}
          </ul>
        </section>
      )}
      <CustomDateRangeCalendar
        visible={state.type === 'Custom Range'}
        close={() => setState({ ...state, type: '' })}
        selectedDate={selectedDate}
        setSelectedDateRange={(value) => {
          setState({ ...state, ...value });
          onDateChange({
            startDate: value.startDate,
            endDate: value.endDate
          });
        }}
      />
    </div>
  );
};

export default DateFilter;
