import useBreakpoint from '+hooks/useBreakPoint';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import { APIDownload } from '+utils';
import React, { useState } from 'react';

import DownloadIcon from '+assets/img/dashboard/download.svg';
import FileViewer from './FileViewer';

const api = new APIRequest();
const queries = {
  small: '(max-width: 450px)',
  normal: '(min-width: 700px)'
};

interface IViewAndDownloadProps {
  url: string;
  type: string;
  filename: string;
  info: string;
  disableView?: boolean;
  disableDownload?: boolean;
}
function ViewAndDownload({ url, type, filename, info, disableView, disableDownload }: IViewAndDownloadProps) {
  const { feedbackInit } = useFeedbackHandler();
  const [showView, setShowView] = useState(false);
  const matchPoints = useBreakpoint(queries);

  console.log(url);
  const download = async () => {
    try {
      const res = await api.downloadFile(url);
      APIDownload(res, `${info}: ${filename}`, type);

      feedbackInit({
        title: 'Download successful',
        message: (
          <>
            {' '}
            - Successfully downloaded{' '}
            <strong>
              {filename}.{type}
            </strong>
          </>
        ),
        type: 'success'
      });
    } catch (error) {
      feedbackInit({
        title: 'Download failed',
        message: `There has been an error in downloading your file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  return (
    <>
      {matchPoints?.small || type === 'pdf' ? (
        <a
          className="download_button"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          download
          onClick={(e) => (disableView ? e.preventDefault() : null)}
          disabled={disableView}
        >
          View
        </a>
      ) : (
        <button type="button" className="download_button" href={url} onClick={() => setShowView(true)} disabled={disableView}>
          View
        </button>
      )}

      <span className="divider-sm" />
      <button type="button" className="download_button" onClick={download} disabled={disableDownload}>
        <span>Download</span>
        <img alt="" src={DownloadIcon} disabled={disableDownload} />
      </button>

      <FileViewer
        url={url}
        showView={showView}
        setShowView={setShowView}
      />
    </>
  );
}

export default ViewAndDownload;
