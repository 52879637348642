/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Modal from '+shared/Modal';
import {
  availableProductType,
  currencyType,
  productCategoriesType,
  ProductConfigType,
  TransactionLimitType,
  updateProductConfigData,
  updateProductConfigDataType,
  updateProductConfigDataTypeWithoutEnabled
} from '+types/productConfig';
import {
  capitalize,
  capitalizeAllCharacters,
  capitalizeRemovedash,
  cleanInput,
  formatAmount,
  isAllowed,
  logError,
  productMapping
} from '+utils';

import InformationIcon from '+assets/img/dashboard/information-button-enable.svg';

import './index.scss';

const apiRequest = new APIRequest();
const channels = {
  api: 'API',
  web: 'Dashboard',
  modal: 'Checkout'
} as const;

type ModalType = 'transaction_limit' | 'channels' | 'all' | null;
type channelsType = keyof typeof channels;

const limitsLabel = {
  min: 'Minimum limit per transaction',
  max: 'Maximum limit per transaction'
};

const radioLabel = {
  default: 'for only merchants under the default configuration',
  all_merchants: 'for all merchants'
} as const;

type radioLabelType = keyof typeof radioLabel;
type limitsLabelType = keyof typeof limitsLabel;

type EditDetailsCardPropsType = {
  title: Exclude<updateProductConfigDataType, 'enabled'>;
  content: ProductConfigType[keyof availableProductType]['channels'] | ProductConfigType[keyof availableProductType]['transaction_limit'];
  merchantId?: string;
  paymentMethod: availableProductType;
  currency: currencyType;
  category: productCategoriesType;
  type: string;
  disableEdit: boolean;
};

const EditDetailsCard = ({
  title,
  content,
  type,
  category,
  merchantId,
  paymentMethod,
  currency,
  disableEdit
}: EditDetailsCardPropsType) => {
  const queryClient = useQueryClient();
  const userAccess = useSetUserAccess();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [modal, setModal] = useState<ModalType>(null);
  const [selected, setSelected] = useState<radioLabelType | null>(null);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [consent, setConsent] = useState(false);
  const [limits, setLimits] = useState({
    min: 0,
    max: 0
  });

  useEffect(() => {
    const max = +limits.max;
    const min = +limits.min;
    if (max <= min) {
      feedbackInit({
        message: 'Minimum transaction limit must be less than maximum transaction limit',
        componentLevel: true,
        type: 'warning',
        isClosable: false
      });
    } else if (max > min) {
      closeFeedback();
    }
  }, [limits.max, limits.min]);

  const description = {
    channels: `Here you can find the checkout and API products for this merchant’s ${capitalizeRemovedash(
      type
    )} configuration. You can modify these payment channels configuration here.`,
    transaction_limit: `Here you can find the limits for this merchant’s ${capitalizeRemovedash(
      type
    )} configuration. You can modify these limits configuration here.`,
    can_send_to_any_merchant: `Here you can find merchant's config to send payment to other merchants through this product.`
  };

  useEffect(() => {
    if (Array.isArray(content) && content.length > 0 && modal === 'channels') {
      setSelectedChannels(prev => [...prev, ...content]);
    }
  }, [content, modal]);

  useEffect(() => {
    if (!Array.isArray(content)) {
      setLimits({
        min: content.min,
        max: content.max
      });
    }
  }, [modal]);

  const updateProductConfiguration = useMutation((value: updateProductConfigData) => apiRequest.updateProductConfiguration(value), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${currency}_PRODUCT`, merchantId, currency, category]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency, `${category?.toLowerCase()}`]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency, `${category.toLowerCase()}`, paymentMethod]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency]);
      queryClient.invalidateQueries(`${currency}_MERCHANTS`);
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `${error.response.data.message || "There has been an error updating merchant's configuration"}`,
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const allAccessContent = () => {
    return (
      <div className="currency-modal__content">
        <div className="radio_container">
          {['default', 'all_merchants'].map(item => {
            return (
              <label key={item}>
                <input checked={item === selected} type="radio" onChange={() => setSelected(item as radioLabelType)} />
                {`Edit ${radioLabel[item as radioLabelType]}`}
              </label>
            );
          })}
        </div>
      </div>
    );
  };
  const removeChannel = (current: keyof typeof channels) => {
    if (category === 'pay-ins') {
      if (['card', 'mobile_money', 'bank_transfer'].includes(paymentMethod)) {
        if (!['api', 'modal'].includes(current)) return true;
      } else if (['pay_with_bank'].includes(paymentMethod)) {
        if (!['modal'].includes(current)) return true;
      } else if (['disbursement_wallet'].includes(paymentMethod)) {
        if (!['web'].includes(current)) return true;
      } else if (['virtual_bank_account'].includes(paymentMethod)) {
        if (!['api'].includes(current)) return true;
      }
    } else if (['bank_transfer', 'bank_account'].includes(paymentMethod)) {
      if (!['api', 'web'].includes(current)) return true;
    } else if (['disbursement_wallet', 'mobile_money'].includes(paymentMethod)) {
      if (!['web'].includes(current)) return true;
    } else if (['bulk_bank_account'].includes(paymentMethod)) {
      if (!['api'].includes(current)) return true;
    }
    return false;
  };

  const channelContent = () => {
    const handleSelection = (value: channelsType) => {
      if (selectedChannels.includes(value)) {
        setSelectedChannels(selectedChannels.filter(item => item !== value));
      } else {
        setSelectedChannels([...selectedChannels, value]);
      }
    };

    return (
      <div className="currency-modal__content">
        <div className="radio_container --channels">
          {Object.entries(channels).map(([key, value]) => {
            return (
              <label key={key} className="channel-item">
                <input
                  checked={selectedChannels.includes(key)}
                  type="checkbox"
                  onChange={() => handleSelection(key as channelsType)}
                  disabled={removeChannel(key as keyof typeof channels)}
                  style={{
                    cursor: removeChannel(key as keyof typeof channels) ? 'not-allowed' : 'pointer'
                  }}
                />
                {key === 'api' ? capitalizeAllCharacters(value) : capitalize(value)}
              </label>
            );
          })}
        </div>
        <div className="info-wrapper --channel-info">
          <img src={InformationIcon} alt="warning" />
          <p className="enable">
            <span>Important:</span> The changes you have made here will affect all merchants under the default configuration
          </p>
        </div>
        <label className="prod-consent-wrapper">
          <input checked={consent} type="checkbox" onChange={() => setConsent(!consent)} />
          <span>Yes, I understand the implications of this action</span>
        </label>
      </div>
    );
  };

  const limitsContent = () => {
    const handleChange = (item: limitsLabelType, value: string) => {
      setLimits({
        ...limits,
        [item]: value
      });
    };
    return (
      <div className="currency-modal__content">
        <div className="radio_container --channels">
          {['min', 'max'].map(item => {
            return (
              <div key={item} className="channel-input">
                <span>{limitsLabel[item as limitsLabelType]}</span>
                <input
                  value={limits[item as limitsLabelType]}
                  placeholder={`Enter ${item} value`}
                  type="number"
                  onChange={e => handleChange(item as limitsLabelType, cleanInput(e.target.value) as string)}
                />
              </div>
            );
          })}
        </div>
        <div className="info-wrapper --channel-info">
          <img src={InformationIcon} alt="warning" />
          <p className="enable">
            <span>Important:</span> The changes you have made here will affect all merchants under the default configuration
          </p>
        </div>
        <label className="prod-consent-wrapper">
          <input checked={consent} type="checkbox" onChange={() => setConsent(!consent)} />
          <span>Yes, I understand the implications of this action</span>
        </label>
      </div>
    );
  };

  const modalDetails = (types: ModalType) => {
    let contents;
    switch (types) {
      case 'all':
        contents = {
          heading:
            title === 'transaction_limit'
              ? `Edit ‘Limits’ for ${currency} ${capitalizeRemovedash(paymentMethod)}`
              : 'Edit channels product config',
          description: 'Please select an option to continue',
          secondButtonText: 'Continue',
          content: allAccessContent(),
          secondButtonDisable: !selected,
          secondButtonActionIsTerminal: false,
          secondButtonAction: () => {
            if (title === 'transaction_limit') {
              setModal('transaction_limit');
            } else {
              setModal('channels');
            }
          }
        };
        break;
      case 'transaction_limit':
        contents = {
          heading: `Edit ‘Limits’ for ${currency} ${capitalizeRemovedash(paymentMethod)}`,
          description: 'You can edit the limits for this product category by clicking into the input fields. ',
          secondButtonText: 'Confirm & Edit',
          completedDescription: 'You have made changes to the payment channels under this payment category.',
          content: limitsContent(),
          secondButtonDisable: !(limits.max && limits.min && consent) || limits.max <= limits.min,
          secondButtonAction: async () => {
            return updateProductConfiguration.mutateAsync({
              currency,
              payment_type: productMapping[category],
              payment_method: paymentMethod,
              type: selected || 'single_merchant',
              data: {
                enabled: true,
                transaction_limit: {
                  min: limits.min,
                  max: limits.max
                }
              },
              account_id: merchantId
            });
          }
        };
        break;
      case 'channels':
        contents = {
          heading: 'Edit channels product config',
          description: 'Please select the payment channels products you would like to enable under this payment category',
          secondButtonText: 'Confirm & Edit',
          content: channelContent(),
          completedDescription: 'You have made changes to the payment channels under this payment category.',
          secondButtonDisable: !(selectedChannels.length && consent),
          secondButtonAction: async () => {
            return updateProductConfiguration.mutateAsync({
              currency,
              payment_type: productMapping[category],
              payment_method: paymentMethod,
              type: selected || 'single_merchant',
              data: {
                enabled: true,
                channels: selectedChannels
              },
              account_id: merchantId
            });
          }
        };
        break;
      default:
        contents = {};
    }

    return {
      close: () => {
        setModal(null);
        setSelected(null);
        setSelectedChannels([]);
        setConsent(false);
        setLimits({
          min: 0,
          max: 0
        });
      },
      secondaryCompletedModal: true,
      ...contents
    };
  };

  const editDetailsContent = (value: updateProductConfigDataTypeWithoutEnabled) => {
    switch (value) {
      case 'channels':
        return Object.entries(channels).map(([key, values]) => {
          if (removeChannel(key as channelsType)) return null;
          return (
            <div key={key} className="menu">
              <p>{`Payment via ${values}`}:</p>{' '}
              <span>{[...new Set(content as channelsType[])].includes(key as channelsType) ? 'Enabled' : 'Disabled'}</span>
            </div>
          );
        });
      case 'transaction_limit':
        return Object.entries(content as TransactionLimitType).map(([key, values]) => (
          <div key={key} className="menu">
            <p>{limitsLabel[key as limitsLabelType]}:</p> <span>{formatAmount(Number(values))}</span>
          </div>
        ));
      default:
        return null;
    }
  };
  return (
    <>
      <div className="editable-card">
        <h6 className="editable-card__header">{capitalizeRemovedash(title)}</h6>
        <div className="editable-card__body">
          <div className="first-section">
            <p className="card-title">{description[title]}</p>
            {isAllowed(userAccess, ['transaction_config_details.update']) && disableEdit && (
              <span
                role="button"
                className="card-action"
                onClick={() => setModal((!merchantId ? 'all' : title) as ModalType)}
                onKeyDown={() => setModal((!merchantId ? 'all' : title) as ModalType)}
                tabIndex={0}
              >
                Edit
              </span>
            )}
          </div>
          <div className="second-section">{editDetailsContent(title as updateProductConfigDataTypeWithoutEnabled)}</div>
        </div>
      </div>

      <Modal
        visible={!!modal}
        size="md"
        completedModalSize="base"
        equalFooterBtn
        showCompleteActionText
        {...modalDetails(modal as ModalType)}
      />
    </>
  );
};

export default EditDetailsCard;
