import React, { ReactNode } from 'react';
import Select, { CSSObjectWithLabel, FormatOptionLabelMeta, MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';

type OptionsT = { label: string; value: string | number; isDisabled?: boolean };
type ReactSelectDropdownT<T = unknown> = {
  label: string | (() => ReactNode);
  placeholder: string | React.ReactNode;
  onChange?: (value: (string | number)[] | string | number) => void;
  options: (OptionsT & T)[];
  value?: string | number | null;
  type?: 'multi' | 'single';
  menuIsOpen?: boolean;
  onFocus?: () => void;
  formatOptionLabel?: (data: OptionsT & T, formatOptionLabelMeta: FormatOptionLabelMeta<OptionsT & T>) => ReactNode;
  isOptionDisabled?: (option: OptionsT & T) => boolean;
  isLoading?: boolean;
};

const ReactSelectDropdown = <T = unknown,>({
  label,
  placeholder,
  options,
  onChange,
  value,
  type = 'single',
  menuIsOpen,
  onFocus,
  formatOptionLabel,
  isOptionDisabled,
  isLoading
}: ReactSelectDropdownT<T>) => {
  const animatedComponents = makeAnimated();
  return (
    <div className="form-group filter-object filter-object-lg" style={{ zIndex: 1000 }}>
      <label htmlFor="description" className="withdraw-label">
        <span className="dark">{typeof label === 'string' ? `${label}` : label()}</span>
      </label>
      <Select
        id={typeof label !== 'string' ? 'select' : label}
        aria-label={typeof label !== 'string' ? 'select' : label}
        placeholder={placeholder}
        value={options?.filter(item => item.value === value)[0]}
        components={animatedComponents}
        options={options}
        formatOptionLabel={formatOptionLabel}
        isOptionDisabled={isOptionDisabled}
        isLoading={isLoading}
        styles={{
          control: (styles, { isFocused }) =>
          ({
            ...styles,
            fontSize: '13px',
            fontWeight: 300,
            border: isFocused ? null : '2px solid #dde2ec'
          } as CSSObjectWithLabel),
          option: (styles, { data, isFocused, isSelected }) => {
            return {
              ...styles,
              fontSize: '13px',
              fontWeight: 400,
              zIndex: 1000,
              backgroundColor: isSelected ? '#7447FD' : 'inherit',
              '&:hover': { backgroundColor: isSelected ? '#7447FD' : 'rgb(222, 235, 255)' }
            };
          }
        }}
        onChange={selected =>
          onChange?.(type !== 'multi' ? (selected as OptionsT).value : (selected as MultiValue<OptionsT>).map(item => item.value))
        }
        menuIsOpen={menuIsOpen}
        onFocus={onFocus}
      />
    </div>
  );
};

export default ReactSelectDropdown;
