import React from 'react';

import { IDateFilterProps } from '+types';

import DateFilter from './DateFilter';
import SecondarySearch from './SecondarySearch';

import './SecondaryFilter.scss';

const SecondaryFilter = ({
  selectedDate,
  onDateChange,
  type,
  handleSearch,
  state
}: IDateFilterProps & {
  type: 'pay-in' | 'pay-outs';
  handleSearch: (value: Record<string, unknown>) => void;
  state: Record<string, unknown>;
}) => {
  return (
    <div className="secondary-filter--container">
      <DateFilter selectedDate={selectedDate} onDateChange={onDateChange} isCleared={state.clear as boolean} />
      <SecondarySearch type={type} handleSearch={handleSearch} state={state} />
    </div>
  );
};

export default SecondaryFilter;
