import KpyLoader from '+assets/img/dashboard/pts-loader.svg';
import React, { ElementRef, useRef } from 'react';
import Modal from './Modal';

export default function FileViewer({ showView, setShowView, url, size = 'lg' }: { showView: boolean; setShowView: (e: boolean) => void; url: string; size?: 'sm' | 'lg' | 'md' }) {
  const loaderRef = useRef<ElementRef<'image'>>(null);
  return (
    showView ? (
      <Modal
        close={() => setShowView(false)}
        size={size}
        heading="File Viewer"
        content={
          <section className="iframe-container">
            <img src={KpyLoader} alt="loader" ref={loaderRef} width="40px" />
            <iframe
              src={url}
              title="file-viewer"
              sandbox=""
              width="100%"
              height="100%"
              style={{ border: 'none' }}
              onLoad={() => {
                if (loaderRef.current) loaderRef.current.style.display = 'none';
              }}
            />
          </section>
        }
        showButtons={false}
        description=''
        secondButtonText=''
      />
    ) : null
  );
}
