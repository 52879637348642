import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export const ScrollToTopSection = () => {
  if (document.body.clientWidth < 1024) return null;
  const defaultScroll = () => window.scroll(0, 0);
  return (
    <div>
      <button className="btn btn-sm" type="button" onClick={defaultScroll}>
        <span>Back to top</span>
        <i className="phosphor-icon phosphor-icon-arrow-up6" />
      </button>
    </div>
  );
};
