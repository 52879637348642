import React from 'react';
import Modal from './Modal';

interface ILargeExportModalProps {
  close: () => void;
  email: string;
  visible: boolean;
}

export default function LargeExportModal({ close, email, visible }: ILargeExportModalProps) {
  return (
    <div>
      <Modal
        close={close}
        visible={visible}
        size="md"
        heading="Large file Download"
        showButtons={false}
        secondButtonActionIsTerminal={false}
        content={
          <>
            <p
              style={{
                color: '#414F5F'
              }}
            >
              The requested export file is too large and will be sent shortly to your email <strong>{email}</strong>. Click the link in your
              email to download your file.
            </p>
            <button
              onClick={close}
              type="button"
              style={{ backgroundColor: '#7447FD', width: '100%', borderRadius: '8px', padding: '8px', color: 'white', border: 'none' }}
            >
              Okay, Got it!
            </button>
          </>
        }
      />
    </div>
  );
}