import useQueryStorage from '+hooks/useQueryStorage';
import CurrencyPicker from '+shared/CurrencyPicker';
import Table from '+shared/Table';
import { storageDataKey } from '+utils';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

export default function BankAccounts() {
  const { id } = useParams();
  const { getQueryData } = useQueryStorage();
  const [activeCurrency, setActiveCurrency] = useState('NGN');
  const { settlement_accounts: settlementAccounts } = getQueryData(`${id}_MERCHANT_KYC`)?.data?.info || {};
  const { reserved_bank_account: virtual } = getQueryData(storageDataKey.SINGLE_MERCHANT) || {};

  const sortedRBAs = virtual?.reserved_bank_accounts?.sort((a) => {
    if (a?.preferred) return -1;
    return 1;
  });

  const getTableData = (type) => ({
    className: '--merchant-account-table',
    emptyStateHeading: type === 'rba' ? 'No Reserved Bank Accounts yet' : 'No Saved Settlement Accounts yet',
    emptyStateMessage: 'It looks like there are no accounts yet.',
    fields: (each) => ({
      data: {
        account_number: type === 'rba' ? each.account_number : each.account_details?.account_number,
        bank: type === 'rba' ? each.bank_name : each.bank?.name,
        account_name: type === 'rba' ? each.account_name : each.account_details?.account_name
      }
    })
  });
  const tableDataKeys = Object.keys(getTableData('rba').fields({}).data);

  return (
    <div className="nav-content active">
      <div className="element-box">
        <div>
          <h5 className="form-header">Reserved Bank Account</h5>
          <div className="form-desc">Merchants fund their balance by deposting money into a fixed BvnkNG account number.</div>
          <Table
            className={getTableData('rba').className}
            data={sortedRBAs || []}
            renderFields
            hasPagination={0}
            tableHeadings={tableDataKeys}
            annotation={getTableData('rba').annotations}
            emptyStateHeading={getTableData('rba').emptyStateHeading || ''}
            emptyStateMessage={getTableData('rba').emptyStateMessage || ''}
            totalItems={(sortedRBAs || [])?.length || 0}
            filterHasAdvancedFilter={false}
          >
            {getTableData('rba').fields}
          </Table>
        </div>

        {Object.keys(settlementAccounts || {}).length > 0 && (
          <>
            <h5 className="form-header mt-5">Settlement Account</h5>
            <div className="form-desc">Merchant designated account for receiving withdrawals and cashbacks on request.</div>
            <div className="mb-3">
              <CurrencyPicker
                options={Object.keys(settlementAccounts)}
                onChange={(value) => {
                  setActiveCurrency(value);
                }}
                activeCurrency={activeCurrency}
                label={<strong>Showing accounts for:</strong>}
              />
            </div>

            <Table
              className={getTableData('settlement').className}
              data={settlementAccounts[activeCurrency] || []}
              renderFields
              hasPagination={0}
              tableHeadings={tableDataKeys}
              annotation={getTableData('settlement').annotations}
              emptyStateHeading={getTableData('settlement').emptyStateHeading || ''}
              emptyStateMessage={getTableData('settlement').emptyStateMessage || ''}
              totalItems={(settlementAccounts[activeCurrency] || [])?.length || 0}
              filterHasAdvancedFilter={false}
            >
              {getTableData('settlement').fields}
            </Table>
          </>
        )}
      </div>
    </div>
  );
}
