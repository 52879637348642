import React, { useEffect, useRef, useState } from 'react';
import Icon from '../Icons';
import './index.scss';

function Scrollable({ children }: { children: React.ReactNode }) {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);

  const scrollContent = (offset: number) => {
    const element = scrollableRef.current;
    if (!element) return;
    element.scrollLeft += offset;
  };

  const checkScrollPosition = () => {
    const element = scrollableRef.current;
    if (!element) return;
    setAtStart(element.scrollLeft < 100);
    setAtEnd(element.scrollLeft + element.clientWidth > element.scrollWidth - 100);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const element = scrollableRef.current;
    if (!element) return;
    setIsOverflowing(element?.scrollWidth > element?.clientWidth);
    setAtStart(element.scrollLeft < 100);
    setAtEnd(element.scrollLeft + element.clientWidth > element.scrollWidth - 100);
  }, [children, windowWidth]);

  useEffect(() => {
    const element = scrollableRef.current;
    if (!element) return;
    element.addEventListener('scroll', checkScrollPosition);

    return () => {
      element.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  return (
    <div className="scrollable">
      {isOverflowing && (
        <button className="scrollable-btn" onClick={() => scrollContent(-200)}>
          <Icon name="chevronLeft" fill={atStart ? undefined : '#7447FD'} />
        </button>
      )}
      <div className="scrollable-content" ref={scrollableRef}>
        {children}
      </div>
      {isOverflowing && (
        <button className="scrollable-btn" onClick={() => scrollContent(200)}>
          <Icon name="chevronRight" fill={atEnd ? undefined : '#7447FD'} />
        </button>
      )}
    </div>
  );
}

export default Scrollable;
