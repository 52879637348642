import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useFeedbackHandler } from '+hooks';
import { capitalize, currentProductConfigSettings, productMapping } from '+utils';
import APIRequest from '+services/api-services';
import {
  availableProductType,
  currencyType,
  productCategoriesPropTypeContentWithoutEnabled,
  productCategoriesType,
  updateProductConfigDataTypeWithoutEnabled
} from '+types';
import LoadingPlaceholder from '+dashboard/Shared/LoadingPlaceHolder';
import EditDetailsCard from '../../components/EditDetailsCard';

import '../index.scss';

const api = new APIRequest();

const DefaultConfiguration = () => {
  const { feedbackInit } = useFeedbackHandler();
  const { currency, product, method } = useParams<{
    currency: currencyType;
    product: productCategoriesType;
    method: availableProductType;
  }>();
  const params = {
    payment_type: productMapping[product],
    payment_method: method
  };

  const {
    data: configData,
    refetch: refetchConfig,
    isLoading
  } = useQuery([`${currency}_PRODUCT_CONFIG`, currency, product, method], () => api.getProductConfiguration(currency, params), {
    refetchOnMount: 'always',
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this merchant's details`,
        type: 'danger',
        action: {
          action: () => refetchConfig(),
          name: 'Try again'
        }
      });
    }
  });

  return (
    <div className="default-config --container">
      {isLoading ? (
        <LoadingPlaceholder type="text" background="#f5f6f6" />
      ) : (
        configData &&
        Object.entries(configData?.data?.setting).map(([key, value]) => {
          if (
            !currentProductConfigSettings.includes(key) ||
            (['pay-ins'].includes(product) && ['transaction_limit'].includes(key) && ['disbursement_wallet'].includes(method))
          )
            return null;
          return (
            <Fragment key={key}>
              <EditDetailsCard
                title={key as updateProductConfigDataTypeWithoutEnabled}
                content={value as unknown as productCategoriesPropTypeContentWithoutEnabled}
                paymentMethod={method as availableProductType}
                currency={currency}
                category={product}
                type={capitalize(product)}
                disableEdit={configData?.data?.setting?.enabled}
              />
            </Fragment>
          );
        })
      )}
    </div>
  );
};

export default DefaultConfiguration;
