import React, { useEffect } from 'react';
import { isAllowed, queriesParams } from '+utils';
import { useSetUserAccess, useSearchQuery } from '+hooks';
import MerchantsCompliance from './MerchantsCompliance';
import MerchantsGeneral from './MerchantsGeneral';

function MerchantsComponent() {
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const activeTab = searchQuery.value.tab || '';
  useEffect(() => {
    if (isAllowed(userAccess, ['merchants.view', 'merchants.export'])) {
      searchQuery.setQuery({ tab: searchQuery?.value?.tab || queriesParams.general });
    } else if (isAllowed(userAccess, ['merchant_compliance.view'])) {
      searchQuery.setQuery({ tab: searchQuery?.value?.tab || queriesParams.compliance });
    }
  }, [userAccess]);

  useEffect(() => {
    if (!searchQuery.value.tab) {
      if (isAllowed(userAccess, ['merchants.view', 'merchants.export'])) {
        searchQuery.setQuery({ tab: searchQuery?.value?.tab || 'general' });
      } else if (isAllowed(userAccess, ['merchant_compliance.view'])) {
        searchQuery.setQuery({ tab: searchQuery?.value?.tab || queriesParams.compliance });
      }
    }
  }, [searchQuery.value.tab]);

  if (!userAccess) return <div />;

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs">
              {isAllowed(userAccess, ['merchants.view', 'merchants.export']) && (
                <li className="nav-item">
                  <span
                    role="button"
                    aria-expanded="false"
                    className={activeTab === 'general' ? 'nav-link active' : 'nav-link'}
                    data-toggle="tab"
                    tabIndex={0}
                    onClick={() => searchQuery.setQuery({ tab: 'general' }, true)}
                    onKeyDown={() => searchQuery.setQuery({ tab: 'general' }, true)}
                  >
                    <span className="tab-label">General</span>
                  </span>
                </li>
              )}
              {isAllowed(userAccess, ['merchant_compliance.view']) && (
                <li className="nav-item">
                  <span
                    role="button"
                    aria-expanded="false"
                    className={activeTab === 'compliance' ? 'nav-link active' : 'nav-link'}
                    data-toggle="tab"
                    tabIndex={0}
                    onClick={() => searchQuery.setQuery({ tab: 'compliance' }, true)}
                    onKeyDown={() => searchQuery.setQuery({ tab: 'compliance' }, true)}
                  >
                    <span className="tab-label">Compliance</span>
                  </span>
                </li>
              )}
            </ul>
          </div>
          <div>
            {activeTab === 'general' && <MerchantsGeneral />}
            {activeTab === 'compliance' && <MerchantsCompliance />}
          </div>
        </div>
      </div>
    </div>
  );
}


export default MerchantsComponent;
