/* eslint-disable no-nested-ternary */
import React from 'react';
import { Formik, Field } from 'formik';
import CustomRadio from './CustomRadio';
import CustomCheckbox from './CustomCheckbox';


interface IOldValue {
  type: string;
  range: number[];
  vat_inclusive: boolean;
  value: number;
  cap: number;
}

interface IEditFeesComponentProps {
  setDisabled: (disabled: boolean) => void;
  setValues: (values: any) => void;
  oldValue: IOldValue;
}
export default function EditFeesComponent({ setDisabled, setValues, oldValue } : IEditFeesComponentProps) {
  return (
    <Formik
      initialValues={{
        type: oldValue.type,
        minimum: oldValue.range[0],
        maximum: oldValue.range[1],
        percent: oldValue.type !== 'flat' && oldValue.value,
        amount: oldValue.type === 'flat' && oldValue.value,
        cap: oldValue.cap || 0,
        vat: oldValue.vat_inclusive || false
      }}
      validate={values => {
        const errors = {};
        if (values.cap < 0) errors.cap = 'A valid fee cap must be greater than or equal to 0';
        if ((!values.percent && values.type === 'percent') || parseFloat(values.percent) <= 0 || parseFloat(values.percent) >= 100)
          errors.percent = 'A valid percent rule is required';
        if (!values.vat && parseFloat(values.percent) + 7.5 >= 100)
          errors.percent = 'Since vat is not included, this percent rule exceeds 100';

        if (values.amount === '' && values.type === 'flat') errors.amount = 'A valid flat amount rule is required';
        if (parseInt(values.amount, 10) < 0 && values.type === 'flat')
          errors.amount = 'A valid flat amount must be greater than or equal to 0';

        if (Number.isNaN(parseFloat(values.minimum))) errors.minimum = 'A minimum value has to be set for this fee rule';
        if (Number.isNaN(parseFloat(values.maximum)) || parseFloat(values.maximum) < 1)
          errors.maximum = 'A valid maximum value has to be set for this fee rule';
        if (parseInt(values.minimum, 10) < 0) errors.minimum = 'A minimum value must be greater than or equal to 0';
        if (parseInt(values.maximum, 10) < 0) errors.maximum = 'A maximum value must be greater than or equal to 0';

        if (!values.vat && values.type === 'flat' && 1.075 * parseInt(values.amount, 10) > parseInt(values.maximum, 10))
          errors.amount = 'A valid flat amount (vat inclusive) must be less than the maximum value';

        if (values.cap && values.cap > parseInt(values.maximum, 10)) errors.cap = 'A valid fee cap must be less than the maximum value';
        if (values.type === 'flat' && parseInt(values.amount, 10) > parseInt(values.maximum, 10))
          errors.amount = 'A valid flat amount must be less than the maximum value';

        if (values.minimum === values.maximum || values.minimum > values.maximum)
          errors.maximum = 'Maximum amount must be greater than the minimum amount';

        const disabled = () => {
          if (
            (values.type === 'percent' && errors.percent) ||
            (values.type === 'flat' && errors.amount) ||
            errors.minimum ||
            errors.maximum ||
            errors.cap
          )
            return true;
          return false;
        };
        setDisabled(disabled());
        setValues(values);
        return errors;
      }}
    >
      {({ values, errors, touched, setFieldValue }) => {
        return (
          <div className="form-center col-sm-12">
            <fieldset className="my-1">
              <div className="row">
                <div className="col-sm-12">
                  <p>What transaction range should this fee apply to?</p>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="minimum">
                      {({ field }) => (
                        <>
                          <label htmlFor="percent">From</label>
                          <input
                            {...field}
                            name="minimum"
                            value={values.minimum}
                            placeholder={values.currency}
                            className="form-control"
                            type="number"
                          />
                          {touched.minimum && errors.minimum && (
                            <div className="input__errors">
                              <p>{errors.minimum}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="maximum">
                      {({ field }) => (
                        <>
                          <label htmlFor="amount">To</label>
                          <input {...field} placeholder={values.currency} name="maximum" className="form-control" type="number" />
                          {touched.maximum && errors.maximum && (
                            <div className="input__errors">
                              <p>{errors.maximum}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <hr className="mt-1" />

              <div className="form-group">
                <div className="row">
                  <div className="col-sm-12">
                    <p>What type of fee are you charging?</p>
                  </div>
                  <div className="col-sm-3">
                    <CustomRadio
                      text="Flat fee"
                      name="type"
                      checked={values.type === 'flat'}
                      onChange={() => {
                        setFieldValue('type', 'flat');
                        setFieldValue('cap', 0);
                        setFieldValue('percent', '');
                        setFieldValue('amount', '');
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <CustomRadio
                      text="Percentage Fee"
                      name="type"
                      checked={values.type === 'percent'}
                      onChange={() => {
                        setFieldValue('type', 'percent');
                        setFieldValue('cap', 0);
                        setFieldValue('percent', '');
                        setFieldValue('amount', '');
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row" hidden={values.type === 'flat'}>
                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="percent">
                      {({ field }) => (
                        <>
                          <label htmlFor="percent">Transaction fee (%)</label>
                          <input {...field} name="percent" className="form-control" type="number" placeholder={0} />
                          {touched.percent && errors.percent && (
                            <div className="input__errors">
                              <p>{errors.percent}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="cap">
                      {({ field }) => (
                        <>
                          <label htmlFor="cap">Max. Fee Limit (Capped at)</label>
                          <input {...field} name="cap" className="form-control" type="number" placeholder={values.currency} />
                          {touched.cap && errors.cap && (
                            <div className="input__errors">
                              <p>{errors.cap}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <div className="form-group" hidden={values.type === 'percent'}>
                <Field name="amount">
                  {({ field }) => (
                    <>
                      <label htmlFor="amount">Flat Transaction Fee</label>
                      <input {...field} name="amount" className="form-control" type="number" placeholder={values.currency} />
                      {touched.ammount && errors.amount && (
                        <div className="input__errors">
                          <p>{errors.amount}</p>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </div>

              <div className="form-group mb-3">
                <CustomCheckbox
                  text="Tax (VAT) has been considered and included in this fee"
                  checked={values.vat}
                  onChange={value => {
                    setFieldValue('vat', value);
                  }}
                />
              </div>

              <hr />

              <div className="sub-info-detail">
                <span>
                  {values.vat
                    ? 'This fee already includes the VAT of 7.5%, which means that the VAT will not be added to the fee when the customer is charged.'
                    : `This fee doesn't include the VAT of 7.5%, which means that the VAT will be added to the fee when the customer is
                    charged.`}
                </span>
              </div>
            </fieldset>
          </div>
        );
      }}
    </Formik>
  );
}