import React from 'react';
import Flags from 'country-flag-icons/react/3x2';

import EmptyState from '+shared/EmptyState';
import Tooltip from '+shared/Tooltip';
import { capitalizeRemovedash } from '+utils';

import Check from '+assets/img/dashboard/check-greenround.svg';
import Info from '+assets/img/dashboard/information-button-disable.svg';

import './index.scss';

interface IBusinessProfileProps {
  details: Record<string, any> | null;
  profile: Record<string, any> | null;
  validation: Record<string, any> | null;
}

export default function BusinessProfile({ 
  details,
  profile,
  validation 
}: IBusinessProfileProps) {
  const Flag = Flags[details?.merchant?.country?.iso2] || (() => null);

  return details ? (
    <div className={`details-wrapper ${profile.kyc_status === 'rejected' ? 'is-disabled' : ''}`}>
      <div className="entry-group">
        <div className="entry">
          <span className="bk-text">Email</span>
          <span className="gy-text">{details.merchant?.email}</span>
        </div>
        <div className="entry">
          <span className="bk-text">Business Type</span>
          <span className="gy-text">{capitalizeRemovedash(details.info?.business_type || '')}</span>
        </div>
        {details.info?.business_profile?.industry && (
          <div className="entry">
            <span className="bk-text">Industry</span>
            <span className="gy-text">{details.info?.business_profile?.industry?.label}</span>
          </div>
        )}
        {details.info?.business_type === 'ngo' && (
          <div className="entry">
            <span className="bk-text">NGO Type</span>
            <span className="gy-text">{details.info?.business_profile?.incorporated_trustee_category?.label}</span>
          </div>
        )}
        <div className="entry">
          <span className="bk-text">Country</span>
          <span className="gy-text">
            {details.merchant?.country?.name}
            {details.merchant?.country?.iso2 && Flag && (
              <span className="ml-2">
                <Flag />
              </span>
            )}
          </span>
        </div>
        <div className="entry">
          <span className="bk-text">
            Phone Number
            {validation?.bvn?.phone && (
              <Tooltip
                image={validation?.bvn?.phone?.match ? Check : Info}
                type="phone-number-validation"
                message={
                  <p>{validation?.bvn?.phone?.match ? 'Verified' : 'Phone number provided does not match number attached to BVN.'}</p>
                }
              />
            )}
          </span>
          <span className={`${(validation?.bvn?.phone && (validation?.bvn?.phone?.match ? 'gn-text' : 'red-text')) || 'gy-text'}`}>
            {details.info?.contact?.support_phone}
          </span>
        </div>
        {details.info?.business_profile?.website && (
          <div className="entry">
            <span className="bk-text">Website</span>
            <a className="gy-link" href={details.info?.business_profile?.website || ''} target="_blank" rel="noopener noreferrer">
              {details.info?.business_profile?.website || 'Not available'}
            </a>
          </div>
        )}
        {details.info?.business_type === 'individual' && (
          <div className="entry">
            <span className="bk-text">
              Date of Birth
              {validation && (
                <Tooltip
                  image={validation?.bvn?.dob?.match ? Check : Info}
                  type="phone-number-validation"
                  message={
                    <p>{validation?.bvn?.dob?.match ? 'Verified' : 'Date of birth provided does not match date attached to BVN.'}</p>
                  }
                />
              )}
            </span>
            <span className="gy-text">{details.info?.business_profile?.date_of_birth}</span>
          </div>
        )}
        {details.info?.business_type === 'registered_business_sme' && (
          <div className="entry">
            <span className="bk-text">SME Type</span>
            <span className="gy-text">{details.info?.business_profile?.sme_type?.label}</span>
          </div>
        )}
        {details.info?.business_type === 'registered_business_sme' && (
          <div className="entry">
            <span className="bk-text">Expected Monthly Income</span>
            <span className="gy-text">
              {`${details.info?.business_profile?.expected_monthly_income?.currency} ${details.info?.business_profile?.expected_monthly_income?.amount}`}
            </span>
          </div>
        )}
        {details.info?.business_type === 'registered_business_sme' && (
          <div className="entry">
            <span className="bk-text">Source of Funds</span>
            <span className="gy-text">{details.info?.business_profile?.source_of_funds}</span>
          </div>
        )}
        {details.info?.business_profile?.business_address && (
          <div className="entry">
            <span className="bk-text">Business Address</span>
            <span className="gy-text">{details.info?.business_profile?.business_address}</span>
          </div>
        )}
      </div>
      <div className="entry entry__two">
        <span className="bk-text">Business Description</span>
        <span className="gy-text">{details.merchant?.description}</span>
      </div>
    </div>
  ) : (
    <EmptyState message="No business profile information available." />
  );
}
