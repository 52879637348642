/* eslint-disable camelcase */
import React, { useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import FilterComponent from '+shared/Filter';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import {
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  getDate,
  getTime,
  history,
  isAllowed,
  queriesParams,
  switchStatus
} from '+utils';
import Table from '../../Shared/Table';

const apiRequest = new APIRequest();

const Users = () => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const [tabs, setTabs] = useState([]);

  const activeTab = searchQuery?.value?.tab || 'users';
  const page = searchQuery?.value?.page || 1;
  const limit = searchQuery?.value?.limit || 10;

  const valuesToBeRemoved = [queriesParams.tab, queriesParams.page, queriesParams.limit, queriesParams.activeTab];
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  useLayoutEffect(() => {
    if (
      userAccess &&
      isAllowed(userAccess, ['admin_users.view', 'admin_user_details.view', 'admin_users.update', 'admin_user_audit_logs.view'])
    ) {
      setTabs((prev) => [...prev, { name: 'Users', key: 'users' }]);
    }
    if (userAccess && isAllowed(userAccess, ['admin_user_invitations.view'])) {
      setTabs((prev) => [...prev, { name: 'Invited Users', key: 'invited_users' }]);
    }
  }, [userAccess]);

  useLayoutEffect(() => {
    if (tabs.length > 0) {
      searchQuery.setQuery({ tab: searchQuery?.value?.tab ?? tabs[0].key });
      if (activeTab === 'invited_users') {
        searchQuery.setQuery({ status: 'pending' });
      }
    }
  }, [tabs.length]);

  const {
    data: resolvedData,
    refetch,
    isFetching
  } = useQuery(
    [`USERS_${activeTab?.toUpperCase()}`, page, limit, sortingParams],
    () =>
      activeTab === 'users' ? apiRequest.getAllUsers(page, limit, sortingParams) : apiRequest.getAllInvitedUser(page, limit, sortingParams),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          title: 'Users',
          message: 'There has been an error getting users',
          type: 'danger',
          action: {
            action: () => {
              refetch();
            },
            name: 'Try again'
          }
        });
      },
      enabled: !!activeTab
    }
  );

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging || [];

  const navigateToUser = (id) => {
    if (
      (activeTab === 'users' && isAllowed(userAccess, ['admin_user_details.view', 'admin_users.update', 'admin_user_audit_logs.view'])) ||
      (activeTab === 'invited_users' && isAllowed(userAccess, ['admin_user_details.view', 'admin_user_invitation.delete']))
    ) {
      history.push(`/dashboard/users/${id}`, { from: activeTab });
    }
  };

  const handleTabChange = (selectedTab) => {
    searchQuery.setQuery({ tab: selectedTab, page: 1, status: selectedTab === 'invited_users' ? 'pending' : undefined }, true);
  };

  const isUserTab = (currentActiveTab) => currentActiveTab === 'users';

  const formatUserName = (name) => {
    if (!name) return '';
    return name;
  };

  const renderUsers = () => (
    <>
      {data.map((user) => {
        return (
          <div
            key={`user_${isUserTab(activeTab) ? user?.user?.id : user?.id}`}
            className="div-table --users-table --row div-table-setup"
            role="button"
            tabIndex={0}
            onClick={() => navigateToUser(isUserTab(activeTab) ? user?.user?.id : user?.code)}
            onKeyUp={() => navigateToUser(isUserTab(activeTab) ? user?.user?.id : user?.code)}
          >
            <div style={{ display: 'flex' }}>
              <span className="body-row-header">Status:</span>
              <span
                className={`status-pill smaller ${switchStatus(isUserTab(activeTab) ? user?.user_status : user?.status)}`}
                style={{ marginTop: '6px' }}
              />
              <span>{capitalizeRemovedash(isUserTab(activeTab) ? user?.user_status : user?.status || 'N/A')}</span>
            </div>
            <div>
              <span className="body-row-header">Name/Email:</span>
              <span className="smaller no-ellipsis" style={{ color: 'rgb(116, 71, 253)' }} role="button" tabIndex="0">
                {(
                  <span>
                    {`${isUserTab(activeTab) ? formatUserName(user?.user?.first_name) : ''} ${isUserTab(activeTab) ? formatUserName(user?.user?.last_name) : ''
                      }`}{' '}
                    <span className="user-email-list" style={{ color: '#979797' }}>
                      {isUserTab(activeTab) ? `(${user?.user?.email})` : user?.email}
                    </span>
                  </span>
                ) || 'Not available'}
              </span>
            </div>
            <div>
              <span className="body-row-header">Role:</span>
              <span className="smaller no-ellipsis">
                {capitalize(isUserTab(activeTab) ? user?.user?.adminRoles[0]?.name : user?.user_role?.name)}
              </span>
            </div>
            <div>
              <span className="body-row-header">Last Active:</span>
              <span>{user?.user?.last_login ? getDate(user?.user?.last_login) : 'N/A'}</span>
              {user?.user?.last_login && <span className="annotation">{getTime(user?.user?.last_login)}</span>}
            </div>
          </div>
        );
      })}
    </>
  );

  const usersTable = () => {
    return (
      <div className="nav-content active">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs">
              {tabs.map((tab) => (
                <li
                  className="nav-item"
                  key={tab.key}
                  style={{ position: 'relative' }}
                  onClick={() => handleTabChange(tab.key)}
                  onKeyUp={() => handleTabChange(tab.key)}
                  role="tab"
                  tabIndex={0}
                >
                  <button type="button" className={`nav-link ${activeTab === tab.key && 'active'}`}>
                    {tab.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <Table
          className="--users-table div-table-setup"
          hasPagination
          header={false}
          tableHeadings={['Status', 'Name/Email', 'Role', 'Last Active']}
          totalItems={paging?.total_items || 0}
          pageSize={paging?.page_size || 0}
          loading={isFetching}
          current={parseInt(page, 10)}
          actionFn={(num) => searchQuery.setQuery({ page: num })}
          limitAction={(no) => searchQuery.setQuery({ limit: no })}
          annotation="users"
          tableWrapperClassName="table-responsive"
          hasFilter={false}
        >
          {renderUsers()}
        </Table>
      </div>
    );
  };

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12 users__page">
            <div className="element-wrapper">
              <h6 className="element-header">Dashboard Users</h6>
              {activeTab && (
                <FilterComponent
                  type={activeTab}
                  hasAdvancedFilter={false}
                  exportAction={() => { }}
                  totalCount={paging?.total_items}
                />
              )}

              <div>{isFetching ? <LoadingPlaceholder type="table" /> : usersTable()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
