/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import Modal, { IModalProps } from '+shared/Modal';
import ReactSelectDropdown from '+shared/ReactSelectDropdown';
import { chargebackDataT, chargebackErrorT, processorT } from '+types';
import { advanceCleanInput, backwardAmountInput, cleanInput, logError, switchCurrency } from '+utils';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import './index.scss';

type ModalStepT = 'confirm' | 'init' | 'edit-confirm' | 'add-to-csv-confirm' | 'delete-confirm' | 'errors' | 'success';

type ChargebackUploadModalTypeT<T> = {
  close: () => void;
  action?: (value?: T) => void;
  mode: 'add' | 'edit' | 'add-to-csv' | 'delete' | 'error' | 'success';
  data?: Partial<T>;
  errors?: chargebackErrorT[];
  successContent?: string[];
};

const currencyOptions = Object.entries(switchCurrency).map((currency) => {
  return {
    label: currency[1],
    value: currency[0],
    isDisabled: !['NGN', 'USD'].includes(currency[0])
  };
});

const apiRequest = new APIRequest();
const ChargebackUploadModal = <T,>({ close, action, mode = 'add', data, errors, successContent }: ChargebackUploadModalTypeT<T>) => {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [type, setType] = useState<ModalStepT>('init');
  const [formError, setFormError] = useState({ amount: false });
  const [formData, setFormData] = useState({
    processor_reference: '',
    reason: '',
    currency: '',
    processor: '',
    amount: '',
    deadline: ''
  });
  useEffect(() => {
    if (mode === 'delete') {
      setType('delete-confirm');
    }
    if (mode === 'error') {
      setType('errors');
    }
    if (mode === 'success') {
      setType('success');
    }
  }, [mode]);

  useEffect(() => {
    if (data && Object.entries(data).length > 0 && mode === 'edit') {
      setFormData(data as unknown as typeof formData);
    }
  }, [data]);

  const validateForm = () => {
    const { processor_reference, reason, currency, processor, amount, deadline } = formData;
    if ((!processor_reference || !reason || !currency || !processor || !amount || +amount < 1 || !deadline) && mode === 'add') {
      return true;
    }
    if ((!processor_reference || !reason || !amount || Number.isNaN(+amount) || +amount < 1) && ['add-to-csv', 'edit'].includes(mode)) {
      return true;
    }
    return false;
  };

  const { data: processorOptions, refetch } = useQuery([`PROCESSORLIST`], () => apiRequest.getProcessorList(), {
    keepPreviousData: true,
    refetchOnMount: 'always',
    select: (value: { data: processorT[] }) => {
      const options = value.data.map((item) => ({ label: item.name, value: item.slug }));
      return options;
    },
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching processors`,
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    }
  });

  const addChargebackMutation = useMutation((value: Omit<chargebackDataT, 'chargebacks'>) => apiRequest.uploadChargeback(value), {
    onSuccess: () => {
      queryClient.invalidateQueries(`CHARGEBACK`);
    },
    onError: (error) => {
      logError(error);
      feedbackInit({
        message: `${error.response.data.message || 'There has been an error adding chargeback'}${'  '}`,
        type: 'danger',
        componentLevel: true
      });
    }
  });
  const defaultChargebackContent = () => {
    return (
      <>
        <div className="form-group">
          <label htmlFor="processor_reference" className="withdraw-label">
            <span className="dark">Provider reference</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="processor_reference"
            value={formData?.processor_reference}
            placeholder="Enter provider reference"
            onChange={(e) => setFormData({ ...formData, processor_reference: cleanInput(e.target.value) })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reason" className="withdraw-label">
            <span className="dark">Chargeback reason</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="reason"
            value={formData?.reason}
            placeholder="Enter chargeback reason"
            onChange={(e) => setFormData({ ...formData, reason: advanceCleanInput(e.target.value) })}
            maxLength={50}
          />
        </div>
        {mode === 'add' && (
          <div className="form-group">
            <ReactSelectDropdown
              label="Currency"
              onChange={(value) => setFormData({ ...formData, currency: value as string })}
              placeholder="-- Select currency --"
              options={currencyOptions}
            />
          </div>
        )}
        {mode === 'add' && (
          <div className="form-group">
            <ReactSelectDropdown
              label="Processor"
              onChange={(value) => setFormData({ ...formData, processor: value as string })}
              placeholder="-- Select processor --"
              options={processorOptions || []}
            />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="amount" className="withdraw-label">
            <span className="dark">Chargeback Amount</span>
          </label>

          <input
            type="number"
            className={`form-control ${formError.amount ? 'error' : ''}`}
            name="amount"
            value={formData?.amount}
            placeholder="Enter chargeback amount"
            onChange={(e) => {
              const formattedAmount = backwardAmountInput(cleanInput(e.target.value)) as string;
              setFormError({ amount: +formattedAmount < 1 });
              setFormData({ ...formData, amount: formattedAmount });
            }}
          />
          {formError.amount && <span className="form-error">Amount must be greater than 0</span>}
        </div>
        {mode === 'add' && (
          <div className="form-group">
            <label htmlFor="deadline" className="withdraw-label">
              <span className="dark">Chargeback deadline</span>
            </label>
            <input
              type="datetime-local"
              className="form-control"
              name="deadline"
              value={formData?.deadline}
              placeholder="-- Select a date --"
              min={`${new Date().toISOString().split(':')[0]}:${new Date().toISOString().split(':')[1]}`}
              onChange={(e) => {
                const formattedTime = cleanInput(e.target.value);
                setFormData({ ...formData, deadline: `${formattedTime}:00` });
              }}
            />
          </div>
        )}
      </>
    );
  };

  const chargebackErrorContent = () => {
    return (
      <div>
        {errors?.map((error) => {
          return (
            <div className="chargeback_error_content" key={error.message}>
              <p className="chargeback_error_content--header">{`${error.message}:`}</p>
              <span style={{ display: 'flex', alignItems: 'flex-start' }}>
                <span className="chargeback_error_content--content">
                  {error.references.map((value) => (
                    <p key={value}>{value}</p>
                  ))}
                </span>
                <Copyable
                  text={String(error?.references?.toString())}
                  copyText="References copied"
                  showText={false}
                  spanClassName=""
                  buttonClassName=""
                />
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const chargebackSuccessContent = () => {
    return (
      <div style={{ marginTop: '1rem' }}>
        <div className="chargeback_error_content">
          <p className="chargeback_error_content--header">These chargebacks were created successfully:</p>
          {successContent?.map((content) => {
            return (
              <span className="chargeback_error_content--content">
                <p>{content}</p>
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  const switchChargebackModal = (kind: ModalStepT) => {
    let content;
    switch (kind) {
      case 'confirm':
        content = {
          heading: 'Add Chargeback',
          content: (
            <p>
              Are you sure you want to raise a chargeback of <strong>{`${formData?.currency} ${formData?.amount}`}</strong> on the listed
              transaction?
            </p>
          ),
          secondButtonText: 'Yes, Proceed',
          secondButtonAction: async () => {
            const newData = {
              currency: formData.currency,
              processor: formData.processor,
              deadline: new Date(new Date(formData.deadline).toUTCString()).toISOString().split('.')[0],
              processor_reference: formData.processor_reference,
              amount: parseFloat(formData.amount),
              reason: formData.reason
            } as Omit<chargebackDataT, 'chargebacks'>;
            return addChargebackMutation.mutateAsync(newData);
          },
          completedHeading: 'Chargeback added',
          completedDescription: 'The merchant will be notified of this chargeback.',
          secondButtonDisable: validateForm(),
          size: 'sm',
          equalFooterBtn: true,
          headerBottomBorder: false,
          showCompleteActionText: false
        };
        break;
      case 'edit-confirm':
      case 'add-to-csv-confirm':
        content = {
          heading: mode === 'add-to-csv' ? 'Add Chargeback' : 'Edit Chargeback',
          content: (
            <p>
              Are you sure you want to {mode === 'add-to-csv' ? 'add' : 'edit'} chargeback of{' '}
              <strong>{formData?.processor_reference}</strong> on the listed summary?
            </p>
          ),
          secondButtonText: 'Yes, Proceed',
          secondButtonAction: () => {
            const newData = {
              id: mode === 'add-to-csv' ? uuidv4() : data?.id,
              processor_reference: formData.processor_reference,
              amount: parseFloat(formData.amount),
              reason: formData.reason
            };
            return action && action(newData as T);
          },
          secondButtonActionIsTerminal: true,
          completedHeading: mode === 'add-to-csv' ? 'Chargeback added' : 'Success',
          completedDescription: mode === 'add-to-csv' ? 'Entry Added' : 'Entry updated',
          secondButtonDisable: validateForm(),
          size: 'sm',
          equalFooterBtn: true,
          headerBottomBorder: false,
          showCompleteActionText: false
        };
        break;
      case 'delete-confirm':
        content = {
          heading: 'Delete Entry?',
          content: <p>Are you sure you want to delete this entry from the list? this can not be undone.</p>,
          secondButtonText: 'Yes, Delete',
          firstButtonText: 'No, Cancel',
          secondButtonAction: () => {
            return action && action();
          },
          secondButtonActionIsTerminal: true,
          completedHeading: 'Deleted',
          completedDescription: 'You have successfully deleted the entry from the list.',
          secondButtonDisable: false,
          size: 'sm',
          equalFooterBtn: true,
          headerBottomBorder: false,
          secondButtonColor: '#F32345',
          showCompleteActionText: false
        };
        break;
      case 'errors':
        content = {
          heading: 'Backend Errors',
          content: chargebackErrorContent(),
          size: 'md',
          showButtons: false
        };
        break;
      case 'success':
        content = {
          heading: 'Successful Chargebacks Created',
          content: chargebackSuccessContent(),
          size: 'md',
          showButtons: false
        };
        break;
      default:
        content = {
          heading: ['add', 'add-to-csv'].includes(mode) ? `Add Chargeback` : 'Edit Chargeback',
          content: defaultChargebackContent(),
          firstButtonText: 'Back',
          secondButtonText: ['add', 'add-to-csv'].includes(mode) ? 'Add' : 'Update',
          secondButtonAction: async () => {
            if (mode === 'add') {
              setType('confirm');
            } else if (mode === 'edit') {
              setType('edit-confirm');
            } else {
              setType('add-to-csv-confirm');
            }
          },
          size: 'md',
          secondButtonDisable: ['add', 'add-to-csv', 'edit'].includes(mode) ? validateForm() : false
        };
        break;
    }

    return {
      close: () => {
        setType('init');
        close();
      },
      secondButtonActionIsTerminal: kind === 'confirm',
      secondaryCompletedModal: true,
      ...content
    };
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Modal {...(switchChargebackModal(type) as IModalProps)} />;
};

export default ChargebackUploadModal;
