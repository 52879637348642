import React, { LegacyRef, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import { useClickOutside, useSearchQuery } from '+hooks';

import search from '+assets/img/dashboard/search-thin.svg';

import './SecondaryFilter.scss';

const defaultOption = {
  'pay-in': {
    reference: 'Transaction Reference / BvnkNG Reference',
    merchant: 'Merchant',
    amount: 'Amount',
    customerName: 'Customer Name',
    customerEmail: 'Customer Email',
    processorReference: 'Processor Reference',
    transactionId: 'Transaction ID',
    phoneNumber: 'Phone Number'
  },
  'pay-outs': {
    transactionId: 'Transaction ID',
    merchant: 'Merchant',
    amount: 'Amount',
    customerName: 'Customer Name',
    customerEmail: 'Customer Email',
    processorReference: 'Processor Reference',
    uniqueReference: 'BvnkNG Reference'
  },
  partners_balance: {
    reference: 'Reference',
    processor_reference: 'Processor Reference',
    amount: 'Amount',
    initiator_email: 'Initiator Email',
    session_id: 'Session ID'
  }
} as const;

const options = {
  'pay-in': {
    mobile_money: {
      mobileNetwork: 'Mobile Network'
    },
    pay_with_bank: {
      payWithBankAccountNumber: 'Account Number'
    },
    bank_transfer: {
      accountNumber: 'Account Number',
      accountName: 'Account Name'
    },
    card: {
      stan: 'STAN',
      lastFourDigits: 'Partially masked pan (e.g. 5399 83** **** 2429) - Last 4 digits'
    }
  },
  'pay-outs': {
    mobile_money: {
      phoneNumber: 'Mobile Number',
      mobileNetwork: 'Mobile Network'
    },
    bank_transfer: {
      accountNumber: 'Account Number'
    }
  }
} as const;

const tabs = {
  'pay-in': {
    mobile_money: 'Mobile Money',
    pay_with_bank: 'Pay With Bank',
    bank_transfer: 'Bank Transfer',
    card: 'Card'
  },
  'pay-outs': {
    mobile_money: 'Mobile Money',
    bank_transfer: 'Bank Transfer'
  }
} as const;

const SecondarySearch = ({
  type,
  handleSearch,
  state
}: {
  type: 'pay-in' | 'pay-outs';
  handleSearch: (value: Record<string, unknown>) => void;
  state: Record<string, unknown>;
}) => {
  const searchQuery = useSearchQuery();
  type tabType = keyof (typeof tabs)[typeof type];
  type defaultKeywordValueType = keyof (typeof defaultOption)[typeof type] | keyof (typeof options)[typeof type];
  type defaultKeywordLabelType =
    | (typeof defaultOption)[typeof type][keyof (typeof defaultOption)[typeof type]]
    | (typeof options)[typeof type][keyof (typeof options)[typeof type]][keyof (typeof options)[typeof type][keyof (typeof options)[typeof type]]];

  const defaultKeywordValue = Object.keys(defaultOption[type])[0] as defaultKeywordValueType;
  const defaultKeywordLabel = Object.values(defaultOption[type])[0] as defaultKeywordLabelType;

  const [keyword, setKeyword] = useState<{
    value: defaultKeywordValueType;
    label: defaultKeywordLabelType;
  }>({
    value: defaultKeywordValue,
    label: defaultKeywordLabel
  });
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const defaultTab = (tabs[type] ? Object.keys(tabs[type])[0] : []) as tabType;
  const [tab, setTab] = useState<tabType>(defaultTab);
  const [keywordValue, setKeywordValue] = useState('');

  useEffect(() => {
    if (state.clear) {
      setKeyword({ value: defaultKeywordValue, label: defaultKeywordLabel });
      setTab(defaultTab);
      setKeywordValue('');
      handleSearch({});
    }
  }, [state.clear]);

  useEffect(() => {
    getPreviousKeywordValueAndLabel();
  }, []);

  const wrapperRef = useClickOutside(() => {
    setDropdownVisible(false);
  });

  useDebounce(
    () => {
      const result = getPreviousKeywordValueAndLabel(false, state);
      let previousValues = {};
      if (Object.entries(result).length > 0) {
        Object.keys(result).forEach(key => {
          if (key !== keyword.value) {
            previousValues = { ...previousValues, [key]: '' };
          }
        });
        handleSearch({ [keyword.value]: removeSpecialCharacters(keywordValue), ...previousValues });
      } else {
        handleSearch({ [keyword.value]: removeSpecialCharacters(keywordValue) });
      }
    },
    200,
    [keywordValue]
  );

  const handleTabChange = (currentTab: tabType) => setTab(currentTab);
  const removeSpecialCharacters = (value: string) => {
    if (keyword.value === 'amount') return value.replace(/[!<>%$?!&^()"'{}[|`~#%*_=+,;\]\n\r\\]/gi, '');
    return value.replace(/[!<>%$?!&^()"'{}[|`~#%*=+,;\]\n\r\\]/gi, '');
  };

  const compressOptions = () => {
    const allOptions = { ...options[type] };
    const result = Object.keys(allOptions).map(key => allOptions[key as keyof (typeof options)[typeof type]]);
    return result.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  };

  const getPreviousKeywordValueAndLabel = (setToState = true, object: Record<string, unknown> | undefined = undefined) => {
    const allOptions = { ...defaultOption[type], ...compressOptions() };
    const previousKeyword = Object.keys(allOptions).filter(key =>
      Object.keys(object || searchQuery.value).includes(key)
    ) as defaultKeywordValueType[];

    let result = {};
    for (let i = 0; i < previousKeyword.length; i += 1) {
      if (previousKeyword[i] in allOptions) {
        if (setToState) {
          setKeyword({
            value: previousKeyword[i],
            label: allOptions[previousKeyword[i] as keyof (typeof defaultOption)[typeof type]]
          });
          setKeywordValue(String(searchQuery.value[previousKeyword[i]]));
        } else {
          result = {
            ...result,
            [previousKeyword[i]]: object ? String(object[previousKeyword[i]]) : String(searchQuery.value[previousKeyword[i]])
          };
        }
      }
    }
    return result;
  };

  const handleOptionChange = (value: defaultKeywordValueType, label: defaultKeywordLabelType) => {
    setKeyword({ value, label });
    setDropdownVisible(false);
    setKeywordValue('');
  };

  const formatInputValue = (value: string) => {
    if (value === 'Transaction Reference / BvnkNG Reference') return 'Reference';
    if (value === 'Partially masked pan (e.g. 5399 83** **** 2429) - Last 4 digits') return 'Last 4 digits';
    return value;
  };

  return (
    <div ref={wrapperRef as LegacyRef<HTMLDivElement>} className="secondary-search">
      <span className="secondary-search--inputs">
        <div
          role="presentation"
          className="form-group  filter-object filter-object-xxl flex-grow-2 w-auto --no-max-width --search-container"
          onClick={() => setDropdownVisible(!dropdownVisible)}
          onKeyDown={() => setDropdownVisible(!dropdownVisible)}
        >
          <img src={search} alt="search icon" aria-hidden aria-label="search" />
          <input
            readOnly
            id="search"
            data-testid="searchKey"
            type="search"
            className="form-control"
            placeholder="Enter Keyword(s)..."
            value={`Search By: ${formatInputValue(keyword.label)}`}
          />
          <i className="phosphor-icon phosphor-icon-arrow-down2" />
        </div>
        <input
          data-testid="searchValue"
          type="search"
          className="form-control"
          placeholder={`Enter ${formatInputValue(keyword.label)} ...`}
          value={keywordValue}
          onChange={e => setKeywordValue(e.target.value)}
        />
      </span>
      {dropdownVisible && (
        <div className="secondary-search--option element-box">
          <ul>
            {Object.entries(defaultOption[type]).map(([key, value]) => (
              <li
                key={key}
                role="presentation"
                onClick={() => handleOptionChange(key as defaultKeywordValueType, value as defaultKeywordLabelType)}
                onKeyDown={() => handleOptionChange(key as defaultKeywordValueType, value as defaultKeywordLabelType)}
              >
                <span>
                  <input title="search" type="radio" name="search" value={key} checked={key === keyword.value} />
                  {value}
                </span>
              </li>
            ))}
            {tabs[type] && (
              <>
                <div className="secondary-search--separator" />
                <div className="secondary-search--option-tab">
                  <div>
                    {Object.entries(tabs[type]).map(([key, value]) => (
                      <span
                        className={`secondary-search--option-tabItem${tab === key ? '--active' : ''}`}
                        role="button"
                        tabIndex={0}
                        key={key}
                        onClick={() => handleTabChange(key as tabType)}
                        onKeyDown={() => handleTabChange(key as tabType)}
                      >
                        <p>{value}</p>
                      </span>
                    ))}
                  </div>
                </div>

                {Object.entries(options[type as keyof typeof options][tab as keyof (typeof options)[typeof type]] || {}).map(
                  ([key, value]) => (
                    <li
                      key={key}
                      role="presentation"
                      onClick={() => handleOptionChange(key as defaultKeywordValueType, value as defaultKeywordLabelType)}
                      onKeyDown={() => handleOptionChange(key as defaultKeywordValueType, value as defaultKeywordLabelType)}
                    >
                      <span>
                        <input title="search" type="radio" name="search" value={key} checked={key === keyword.value} />
                        {value}
                      </span>
                    </li>
                  )
                )}
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SecondarySearch;
