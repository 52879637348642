import React, { Fragment, useEffect, useState } from 'react';
import {
  ProductConfigType,
  availableProductType,
  currencyType,
  modalState,
  productCategoriesPropTypeContentWithoutEnabled,
  productCategoriesType,
  updateProductConfigDataTypeWithoutEnabled
} from '+types';
import {
  availableCurrency,
  capitalize,
  capitalizeRemovedash,
  currentProductConfigSettings,
  filteredOutObjectProperty,
  productMapping
} from '+utils';
import { useReducerState } from '+hooks';
import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import EditDetailsCard from '../../components/EditDetailsCard';
import MerchantCurrencyModal from '../../MerchantsTable/components/MerchantCurrencyModal';

import './index.scss';

type productCategoriesPropType =
  | {
      name: availableProductType;
      content: ProductConfigType;
    }
  | undefined;

const ProductCategories = ({
  config,
  type,
  currency,
  merchantId,
  merchantsStatus
}: {
  config: ProductConfigType;
  type: productCategoriesType;
  currency: currencyType;
  merchantId: string;
  merchantsStatus: boolean;
}) => {
  const [selectedTab, setSelectedTab] = useState<productCategoriesPropType | undefined>();
  const [state, setState] = useReducerState<{ activeModal: modalState }>({
    activeModal: null
  });
  const itemsToRemove = ['enabled'];

  useEffect(() => {
    if (config) {
      if ([availableCurrency.USD].includes(currency)) {
        itemsToRemove.push('bank_account');
      }
      const newConfig = filteredOutObjectProperty(config, itemsToRemove);
      setSelectedTab({
        name: Object.keys(newConfig)[0] as availableProductType,
        content: newConfig[Object.keys(newConfig)[0]]
      });
    }
  }, [config]);

  const handleSelectTab = (key: Exclude<availableProductType, 'boolean'>, value: Exclude<ProductConfigType, 'boolean'>) =>
    setSelectedTab((prev) => ({
      ...prev,
      name: key,
      content: value
    }));
  return (
    <section className="product-cat__container">
      {Object.entries(filteredOutObjectProperty(config, itemsToRemove)).length > 0 ? (
        <>
          <div className="product-cat__container-menu">
            <p>Product categories</p>
            <ul>
              {config &&
                Object.entries(config).map(([key, value]) => {
                  if (key === 'enabled' || (['bank_account'].includes(key) && [availableCurrency.USD].includes(currency))) return null;
                  return (
                    <li className={selectedTab?.name === key ? '--active' : ''} key={key}>
                      <span
                        role="button"
                        onClick={() =>
                          handleSelectTab(
                            key as Exclude<availableProductType, 'boolean'>,
                            value as unknown as Exclude<ProductConfigType, 'boolean'>
                          )
                        }
                        onKeyDown={() =>
                          handleSelectTab(
                            key as Exclude<availableProductType, 'boolean'>,
                            value as unknown as Exclude<ProductConfigType, 'boolean'>
                          )
                        }
                        tabIndex={0}
                      >
                        {capitalizeRemovedash(key)}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>

          {selectedTab && selectedTab.content && selectedTab.name ? (
            <div className="product-cat__container-content">
              <h5 className="content-header">{`${capitalizeRemovedash(selectedTab?.name)} Configuration`}</h5>
              <label className="content-action">
                <input
                  checked={selectedTab.content?.enabled}
                  type="checkbox"
                  onChange={() => setState({ activeModal: selectedTab.content?.enabled ? 'disable-channel' : 'enable-channel' })}
                  disabled={!merchantsStatus || !config.enabled}
                  style={{ cursor: `${!merchantsStatus || !config.enabled ? 'not-allowed' : 'pointer'}` }}
                />
                <span>{`Allow this merchant to receive ${capitalizeRemovedash(selectedTab?.name)} ${type} in ${currency}`}</span>
              </label>
              {Object.entries(selectedTab.content).map(([key, value]) => {
                if (
                  !currentProductConfigSettings.includes(key) ||
                  (['pay-ins'].includes(type) && ['transaction_limit'].includes(key) && ['disbursement_wallet'].includes(selectedTab.name))
                )
                  return null;
                return (
                  <Fragment key={key}>
                    <EditDetailsCard
                      title={key as updateProductConfigDataTypeWithoutEnabled}
                      content={value as unknown as productCategoriesPropTypeContentWithoutEnabled}
                      paymentMethod={selectedTab.name}
                      type={capitalize(selectedTab.name)}
                      category={type}
                      merchantId={merchantId}
                      currency={currency}
                      disableEdit={merchantsStatus && config.enabled && selectedTab.content?.enabled}
                    />{' '}
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <EmptyStateComponent message="Channels Not Available" />
          )}
        </>
      ) : (
        <EmptyStateComponent message="Product Categories Not Available" />
      )}
      {state.activeModal && (
        <MerchantCurrencyModal
          activeModal={state.activeModal}
          setActiveModal={() => setState({ activeModal: null })}
          currency={currency}
          merchantId={merchantId}
          productType={productMapping[type]}
          method={selectedTab?.name}
          hideButton
        />
      )}
    </section>
  );
};

export default ProductCategories;
