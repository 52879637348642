import BusinessAvatar from '+assets/theme/img/biz-avatar.png';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSearchQuery from '+hooks/useSearchQuery';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  getDate,
  getTime,
  isAllowed,
  logError,
  queriesParams,
  switchStatus
} from '+utils';
import React from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import './index.scss';

const api = new APIRequest();

function MerchantsGeneral() {
  const location = useLocation();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;

  const valuesToBeRemoved = [queriesParams.tab, queriesParams.page, queriesParams.limit];
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const initialData = {
    data: [],
    paging: { current: 1, next: 2, count: 0, page_size: 10, total_items: 0 }
  };

  const userAccess = useSetUserAccess(location);
  const getMerchants = (currentPage, currentSortingParams, currentLimit) => {
    const values = ['', currentPage, currentSortingParams, currentLimit];
    return useQuery(['MERCHANTS', ...values], () => api.getMerchants(...values), {
      refetchOnMount: true,
      onError: (error) => {
        logError(error);
        feedbackInit({
          message: 'There has been an error fetching all merchants, Please try again',
          type: 'danger'
        });
      }
    });
  };

  const { data: merchants, isFetching: allMerchantsLoading } = getMerchants(page, sortingParams, limit);

  const allMerchants = merchants?.data ? merchants?.data : initialData;

  const { data: merchantsStats, isFetching: merchantsStatsLoading } = useQuery(`MERCHANT_STATISTICS`, () => api.getMerchantStatistics(), {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching merchants statistics.`,
        type: 'danger'
      });
    }
  });

  const isFetching = allMerchantsLoading || merchantsStatsLoading;

  const renderPagination = () => {
    return [allMerchants.paging.total_items, allMerchants.paging.page_size];
  };

  const paginate = (currentPage) => {
    searchQuery.setQuery({ page: currentPage });
  };

  const exportMerchantList = async (format, close, fieldsToExport) => {
    try {
      const res = await api.exportMerchants(fieldsToExport, format, sortingParams);
      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `All Merchant list at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            {' '}
            - Successfully exported <strong>{renderPagination()[0]} records.</strong>
          </>
        ),
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your merchant list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const Merchants = {
    className: '--merchant-table',
    emptyStateHeading: 'No Merchants yet',
    data: allMerchants?.data || [],
    emptyStateMessage: 'There are no Merchants',
    rowURL: '/dashboard/merchant',
    rowKey: 'id',
    fields: (each) => ({
      data: {
        Merchant: (
          <>
            <img alt="business avatar" src={BusinessAvatar} />
            <span id="merchant-name">{capitalize(each.name)}</span>
          </>
        ),
        Status: (
          <>
            <span className={`status-pill smaller ${switchStatus(each.status)}`} />
            <span>{capitalizeRemovedash(each.status || 'N/A')}</span>
          </>
        ),
        merchant_id: each.id,
        Contact: <span className="lighter">{each.email}</span>,
        Date_Created: (
          <>
            <span>{getDate(each.created_at)}</span>
            <span className="annotation merchant-table-date-created-container" >
              {getTime(each.created_at)}
            </span>
          </>
        )
      }
    })
  };

  return (
    <div className="element-box">
      <div className="element-box-heading settlements-settings__heading">
        <div className="heading-box-mmd">
          <h5 className="form-header">Merchants Overview</h5>
          <div className="form-desc no-underline mb-3">
            An overview of all the merchant’s with and without access to BvnkNG services.
          </div>
        </div>
      </div>
      <section className="merchants-summary">
        <div>
          <p>Total Merchants</p>
          <p>{merchantsStats?.total || '--'}</p>
          <p>All merchants who have signed up</p>
        </div>
        <div>
          <p>Active Merchants</p>
          <p>{merchantsStats?.status?.active || '--'}</p>
          <p>Merchants with service access</p>
        </div>
        <div>
          <p>Deactivated Merchants</p>
          <p>{merchantsStats?.status?.deactivated || '--'}</p>
          <p>Merchants without service access</p>
        </div>
      </section>
      <legend>
        <span />
      </legend>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="element-wrapper pt-0">
                  <div className="element-box-tp">
                    <Table
                      className={Merchants.className}
                      loading={isFetching}
                      data={Merchants?.data || []}
                      renderFields
                      hasPagination
                      rowKey={Merchants?.rowKey}
                      rowURL={Merchants?.rowURL}
                      tableHeadings={['Merchant', 'Status', 'Merchant ID', 'Contact', 'Date Created']}
                      current={parseInt(page, 10)}
                      totalItems={renderPagination()[0]}
                      pageSize={renderPagination()[1]}
                      tableWrapperClassName="table-responsive table-wrapper"
                      type="merchant"
                      filterKeywordPlaceholder="Search..."
                      filterQueryIDPlaceholder="Merchant ID"
                      filterExportAction={exportMerchantList}
                      filterTotalCount={renderPagination()[0]}
                      filterShowExport={isAllowed(userAccess, ['merchants.export'])}
                      filterExportModalHeading="Export Merchants"
                      filterExportModalDescription="Choose how you would like to export these merchants"
                      hideTable={allMerchants?.data?.length === 0}
                      actionFn={(currentPage) => paginate(currentPage)}
                      limitAction={(currentLimit) => searchQuery.setQuery({ limit: currentLimit })}
                      emptyStateHeading={Merchants?.emptyStateHeading || ''}
                      emptyStateMessage={Merchants.emptyStateMessage || ''}
                      subType="merchant_general"
                      storedState={{ state: userAccess }}
                    >
                      {Merchants?.fields}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantsGeneral;
