import React from 'react';
import TabSwitch from '+containers/Dashboard/Shared/TabSwitch';
import './index.scss';

interface MerchantCompliancePickerProps {
  onChange: (tab: string) => void;
  activeTab: string;
  options: string[];
  id?: string;
  tabCount?: Record<string, number>;
}

const MerchantCompliancePicker = ({ onChange, activeTab, options, id, tabCount }: MerchantCompliancePickerProps) => {
  return (
    <div style={{ marginBottom: '30px' }}>
      <div>
        <TabSwitch options={options} activeTab={activeTab} setTab={onChange} id={id} tabCount={tabCount} modalHeader='Compliance Stage' />
      </div>
    </div>
  );
};

export default MerchantCompliancePicker;
