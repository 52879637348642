/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import close from '+assets/img/dashboard/close-red.svg';
import deleted from '+assets/img/dashboard/deleted.svg';
import edit from '+assets/img/dashboard/edit.svg';
import warning from '+assets/img/dashboard/warning-orange.svg';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useQueryStorage from '+hooks/useQueryStorage';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import APIServiceError from '+services/error-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import EditFeesContent from '+shared/EditFeesComponent';
import HoverMenu from '+shared/HoverMenu';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Modal from '+shared/Modal';
import NewFeesContent from '+shared/NewFeesComponent';
import queryKeys from '+store/constants/queryKeys';
import { capitalize, defaultScrollToTop, formatAmount, isAllowed, logError } from '+utils';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import './index.scss';

const api = new APIRequest();

export default function MerchantFees() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const { getQueryData } = useQueryStorage();
  const currencies = getQueryData(queryKeys.currencies);

  const [values, setValues] = useState({});
  const [fees, setFees] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({
    modalVisible: false,
    type: 'delete'
  });
  const [activeCurrency, setActiveCurrency] = useState('NGN');
  const location = useLocation();
  const userAccess = useSetUserAccess(location);

  const { data, refetch: refetchFees, isLoading } = useQuery(['MERCHANT_FEES', id], () => api.getMerchantFee(id), {
    staleTime: 30 * 60 * 1000,
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        message: 'There has been an error fetching the fees for this merchant.',
        type: 'danger'
      });
    }
  });

  const merchantFeeUpdate = useMutation(({ ids, datas }) => api.updateMerchantFee(ids, datas), {
    onError: error => {
      if (error instanceof APIServiceError) {
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['MERCHANT_FEES']);
    }
  });

  const merchantFeePut = (ids, datas) => {
    return merchantFeeUpdate.mutateAsync({ ids, datas });
  };

  const resetmerchantFee = useMutation(({ ids, feeId }) => api.resetFeeRules(ids, feeId), {
    onError: error => {
      if (error instanceof APIServiceError) {
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['MERCHANT_FEES']);
    }
  });

  const merchantFeeReset = (ids, feeId) => {
    return resetmerchantFee.mutateAsync({ ids, feeId });
  };

  const resetAllmerchantFee = useMutation(({ ids }) => api.resetFeeRules(ids), {
    onError: error => {
      if (error instanceof APIServiceError) {
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['MERCHANT_FEES']);
    }
  });

  const merchantFeeResetAll = ids => {
    return resetAllmerchantFee.mutateAsync({ ids });
  };

  const feesData = data?.data;
  const currencyFeesData = fees?.[activeCurrency];

  useEffect(() => {
    const newFees = Object.keys(feesData?.fees || {}).reduce((allFees, currency) => {
      const currencyFees = feesData?.fees[currency];
      if (currencyFees.charge) {
        currencyFees.charge = currencyFees.charge.map(fee => {
          fee.definition = fee.definition.map(def => {
            def.id = Math.floor(1000 + Math.random() * 9000);
            return def;
          });
          return fee;
        });
      }
      if (currencyFees.disburse) {
        currencyFees.disburse = currencyFees.disburse.map(fee => {
          fee.definition = fee.definition.map(def => {
            def.id = Math.floor(1000 + Math.random() * 9000);
            return def;
          });
          return fee;
        });
      }
      return {
        ...allFees,
        [currency]: currencyFees
      };
    }, {});
    setFees(newFees);
    if (feesData && !newFees?.[activeCurrency]) {
      setActiveCurrency(Object.keys(newFees)[0]);
    }
  }, [feesData]);

  const addFee = async () => {
    const fee = feesData?.fees?.[values.currency]?.[values.kind].filter(f => f.item === values.trxnType);
    const apiFee =
      fee?.[0].definition.map(e => ({
        cap: e.cap,
        type: e.type,
        value: e.value,
        vat_inclusive: e.vat_inclusive,
        range: e.range
      })) || [];
    const feeData = {
      item: values.trxnType,
      type: values.kind,
      currency: values.currency,
      definition: [
        ...apiFee,
        {
          cap: values.cap,
          type: values.type,
          value: values.type === 'flat' ? values.amount : values.percent,
          vat_inclusive: values.vat,
          range: [values.minimum, values.maximum]
        }
      ]
    };

    try {
      await merchantFeePut(id, { ...feeData });
      await refetchFees();
    } catch (error) {
      logError(error);
      const message = error?.response?.data?.message;
      feedbackInit({
        title: '',
        message: !message?.includes('request') ? message : 'This fee rule cannot be added right now',
        type: 'danger',
        componentLevel: true
      });
      throw error;
    }
  };

  const deleteFee = async () => {
    const feeKind = currencyFeesData?.[values.kind].filter(f => f.item === values.trxnType);
    const newFee = feeKind.map(f => {
      const tempFees = f.definition.filter(detail => detail.id !== values.feeId);
      const modifiedTempFees = tempFees.map(e => ({
        cap: e.cap,
        type: e.type,
        value: e.value,
        vat_inclusive: e.vat_inclusive,
        range: e.range
      }));
      if (modifiedTempFees.length !== 0) {
        const tempData = {
          item: f.item,
          type: f.type,
          definition: modifiedTempFees,
          currency: values.currency
        };
        return tempData;
      }
      return false;
    });

    if (!newFee[0]) {
      setState({ modalVisible: false });
      defaultScrollToTop();
      feedbackInit({
        title: '',
        message: 'A fee rule must exist for this kind of transaction',
        type: 'danger'
      });
      setTimeout(() => closeFeedback(), 2000);
    } else {
      try {
        await merchantFeePut(id, ...newFee);
        await refetchFees();
      } catch (error) {
        logError(error);
        defaultScrollToTop();
        feedbackInit({
          title: '',
          message: 'This fee cannot be deleted right now',
          type: 'danger'
        });
        throw error;
      }
    }
  };

  const resetFeeRule = async () => {
    try {
      await merchantFeeReset(id, values.feeId);
      await refetchFees();
    } catch (error) {
      logError(error);
      defaultScrollToTop();
      feedbackInit({
        title: '',
        message: 'There has been an error resetting your fee rules. Please try again',
        type: 'danger'
      });
      throw error;
    }
  };

  const editFee = async () => {
    const feeKind = currencyFeesData?.[values.kind].filter(kind => kind.item === values.trxnType);
    const newFee = feeKind.map(f => {
      const tempFees = f.definition.filter(detail => detail.id !== values.feeId);
      const modifiedTempFees = tempFees.map(e => ({
        cap: e.cap,
        type: e.type,
        value: e.value,
        vat_inclusive: e.vat_inclusive,
        range: e.range
      }));
      const tempData = {
        item: f.item,
        type: f.type,
        currency: activeCurrency,
        definition: [
          ...modifiedTempFees,
          {
            cap: values.cap,
            type: values.type,
            value: values.type === 'flat' ? values.amount : values.percent,
            vat_inclusive: values.vat,
            range: [values.minimum, values.maximum]
          }
        ]
      };
      return tempData;
    });
    try {
      await merchantFeePut(id, ...newFee);
      await refetchFees();
    } catch (error) {
      logError(error);
      const message = error?.response?.data?.message;
      feedbackInit({
        title: '',
        message: !message?.includes('request') ? message : 'This fee cannot be edited right now.',
        type: 'danger',
        componentLevel: true,
        action: {
          action: () => merchantFeePut(id, ...newFee),
          name: 'Try again'
        }
      });
      throw error;
    }
  };

  const resetFees = async () => {
    try {
      await merchantFeeResetAll(id);
      await refetchFees();
      defaultScrollToTop();
    } catch (error) {
      logError(error);
      defaultScrollToTop();
      feedbackInit({
        title: '',
        message: `There has been an error resetting this merchant's fee rule. Please try again`,
        type: 'danger'
      });
      throw error;
    }
  };

  const switchFeesModal = type => {
    let content;
    switch (type) {
      case 'reset':
        content = {
          heading: `Reset Merchant's Fees`,
          description: `Are you absolutely sure you want to reset all the fee rules for this merchant? This action cannot be undone.`,
          secondButtonText: 'Yes, Reset',
          size: 'sm',
          maxHeight: '75vh',
          secondButtonAction: resetFees,
          completedHeading: 'Done',
          completedDescription: 'All the fee rules for this merchant have been reset to the default rules.'
        };
        break;
      case 'new':
        content = {
          heading: 'Add a fee rule',
          description: `Fee rules define the amount charged as fees for any transaction. A fee rule would apply to transactions within a specific currencies, payment method and transaction range.`,
          content: (
            <NewFeesContent
              setDisabled={value => setDisabled(value)}
              setValues={v => setValues(prevValues => ({ ...prevValues, ...v }))}
              currencies={currencies}
              activeCurrency={activeCurrency}
            />
          ),
          secondButtonText: 'Save',
          size: 'md',
          maxHeight: '75vh',
          secondButtonAction: addFee,
          secondButtonDisable: disabled,
          completedHeading: 'Done',
          completedDescription: 'The fee rule has been successfully added.'
        };
        break;
      case 'edit':
        content = {
          heading: 'Edit fee rule',
          description: `Editing this fee rule will update the fees on future transactions that fall within this range for this merchant.`,
          content: (
            <EditFeesContent
              setDisabled={value => setDisabled(value)}
              setValues={v => setValues(prevValues => ({ ...prevValues, ...v }))}
              oldValue={values.oldValue}
            />
          ),
          secondButtonText: 'Update',
          size: 'md',
          secondButtonAction: editFee,
          secondButtonDisable: disabled,
          completedHeading: 'Done',
          completedDescription: 'The fee rule has been successfully updated.'
        };
        break;
      case 'delRule':
        content = {
          heading: 'Reset Fee Rule',
          description: `Are you absolutely sure you want to reset this fee rule? This action cannot be undone.`,
          secondButtonText: 'Yes, Reset',
          size: 'sm',
          secondButtonAction: resetFeeRule,
          completedHeading: 'Done',
          completedDescription: `The fee rules for this payment method have been reset.`
        };
        break;
      default:
        content = {
          themeColor: '#e65252',
          heading: 'Delete fee rule?',
          description: (
            <>
              Are you sure you want to <strong>delete</strong> this fee rule? This action cannot be undone.
            </>
          ),
          secondButtonText: 'Yes, Delete',
          secondaryButtonColor: '#F32345',
          size: 'sm',
          secondButtonAction: deleteFee,
          completedHeading: 'Deleted',
          completedImage: deleted,
          completedDescription: 'The fee rule has been deleted.'
        };
        break;
    }
    return {
      visible: state.modalVisible,
      close: () => {
        setState({ ...state, modalVisible: false });
        setDisabled(true);
      },
      completedHeading: 'Success',
      ...content
    };
  };

  const collectionFees = () => {
    const payinsRulesInfo = {
      bank_transfer: {
        title: 'Bank Transfers',
        description: 'These are the rules for the fees charged to the merchant when their customer chooses to pay with bank transfer.'
      },
      card: {
        title: 'Card Payments',
        description: 'These are the rules for the fees charged to the merchant when their customer chooses to pay with a debit/credit card.'
      },
      reserved_bank_account: {
        title: 'Reserved Bank Account',
        description:
          'These are the rules for the fees charged to the merchant when their customer chooses to pay through the reserved bank account assigned to them.'
      },
      mobile_money: {
        title: 'Mobile Money',
        description:
          'These are the rules for the fees charged to the merchant when their customer chooses to pay through a mobile money provider.'
      },
      virtual_bank_account: {
        title: 'VBA',
        description:
          'These are the rules for the fees charged to the merchant when their customer chooses to pay through the virtual account assigned to them.'
      },
      pay_with_bank: {
        title: 'Pay with Bank (Direct debit)',
        description:
          'These are the rules for the fees charged to the merchant when their customer chooses to pay through a direct debit on their bank account.'
      }
    };

    if (isLoading) return <LoadingPlaceholder type="table" background="#f5f6f6" />;

    if (!currencyFeesData?.charge) return null;

    return (
      <section className="merchants-fees__payins-w grey-section">
        <p className="grey-section__title">Fee Rules for Pay-ins</p>
        <div className="merchants-fees__payins grey-section__subsection">
          {currencyFeesData.charge?.map(fee => (
            <React.Fragment key={fee.item}>
              {payinsRulesInfo?.[fee.item] && (
                <div className="rule-container grey-section__subsection-group">
                  <div className="fee-item grey-section__subsection-item">
                    <div>
                      <p>{payinsRulesInfo[fee.item].title || ''}</p>
                      <p>{payinsRulesInfo[fee.item].description || ''}</p>
                    </div>
                    {fee.is_custom &&
                      (isAllowed(userAccess, ['merchant_fee_config.update']) ? (
                        <button
                          className="btn btn--link fee--custom-indicator"
                          type="button"
                          onClick={() => {
                            setState({ modalVisible: true, type: 'delRule' });
                            setValues({ feeId: fee.id });
                          }}
                        >
                          <i className="phosphor-icon phosphor-icon-rotate-ccw" />
                          <span className="ml-1"> Reset Fee Rules</span>
                          <span className="custom--indicator" />
                        </button>
                      ) : null)}
                  </div>
                  <div className="rule-group">
                    {fee.definition.map((feeDetails, index) => (
                      <div className="rule-box" key={feeDetails.id || index}>
                        <div className="rule-details">
                          <p>
                            {'A '}
                            <span className="--detail-green">
                              {feeDetails.type === 'flat' && capitalize(`${feeDetails.type} fee`)}
                              {feeDetails.type === 'percent' && capitalize(`${feeDetails.type}age fee`)}
                            </span>
                            {' of '}
                            <span className="--detail-green">
                              {feeDetails.type === 'flat'
                                ? `${activeCurrency} ${formatAmount(feeDetails.total_fee)} `
                                : `${formatAmount(feeDetails.total_fee)}% `}
                            </span>
                            {parseFloat(feeDetails.cap) > 1 && (
                              <>
                                capped at{' '}
                                <span className="--detail-grey">
                                  {activeCurrency} {formatAmount(feeDetails.cap)}
                                </span>
                              </>
                            )}
                            {' is charged for transactions between '}
                            <span className="--detail-grey">{`${activeCurrency} ${formatAmount(feeDetails.range[0])}`}</span>
                            {' and '}
                            <span className="--detail-grey">{`${activeCurrency} ${formatAmount(feeDetails.range[1])}`}</span>
                          </p>

                          {isAllowed(userAccess, ['merchant_fee_config.update']) ? (
                            <HoverMenu
                              title={`${activeCurrency} ${formatAmount(feeDetails.range[0])} to ${activeCurrency} ${formatAmount(
                                feeDetails.range[1]
                              )}`}
                            >
                              <li>
                                <button
                                  type="button"
                                  className="btn btn--link"
                                  onClick={() => {
                                    setState({ modalVisible: true, type: 'edit' });
                                    setValues({
                                      kind: 'charge',
                                      trxnType: fee.item,
                                      feeId: feeDetails.id,
                                      currency: activeCurrency,
                                      oldValue: feeDetails
                                    });
                                  }}
                                >
                                  <img src={edit} alt="edit icon" aria-hidden />
                                  <span className="ml-2">Edit</span>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="text-danger btn btn--link"
                                  type="button"
                                  onClick={() => {
                                    setState({ modalVisible: true, type: 'del' });
                                    setValues({ kind: 'charge', trxnType: fee.item, feeId: feeDetails.id, currency: activeCurrency });
                                  }}
                                >
                                  <img src={close} alt="close icon" aria-hidden />
                                  <span className="ml-2">Delete</span>
                                </button>
                              </li>
                            </HoverMenu>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </section>
    );
  };

  // const disbursementFees = () => {
  //   const payoutsRulesInfo = {
  //     bank_account: {
  //       title: 'Bank Payouts',
  //       description: 'These are the rules for the fees charged to the merchant when they make payouts to bank accounts.'
  //     },
  //     mobile_money: {
  //       title: 'Mobile Money',
  //       description:
  //         'These are the rules for the fees charged to the merchant when they pay their customer through a mobile money provider.'
  //     }
  //   };

  //   if (isLoading) return <LoadingPlaceholder type="table" background="#f5f6f6" />;

  //   if (!currencyFeesData?.disburse) return null;

  //   return (
  //     <section className="merchants-fees__payouts-w grey-section">
  //       <p className="grey-section__title">Fee Rules for Payouts</p>
  //       <div className="merchants-fees__payouts grey-section__subsection">
  //         {currencyFeesData.disburse?.map(fee => (
  //           <React.Fragment key={fee.item}>
  //             {payoutsRulesInfo[fee.item] && (
  //               <div className="rule-container grey-section__subsection-group">
  //                 <div className="fee-item grey-section__subsection-item">
  //                   <div>
  //                     <p>{payoutsRulesInfo[fee.item].title}</p>
  //                     <p>{payoutsRulesInfo[fee.item].description}</p>
  //                   </div>
  //                   {fee.is_custom &&
  //                     (isAllowed(userAccess, ['merchant_fee_config.update']) && (
  //                       <button
  //                         className="btn btn--link fee--custom-indicator"
  //                         type="button"
  //                         onClick={() => {
  //                           setState({ modalVisible: true, type: 'delRule' });
  //                           setValues({ feeId: fee.id });
  //                         }}
  //                       >
  //                         <i className="phosphor-icon phosphor-icon-rotate-ccw" />
  //                         <span className="ml-1"> Reset fee rule</span>
  //                         <span className="custom--indicator" />
  //                       </button>
  //                     ))}
  //                 </div>
  //                 <div className="rule-group">
  //                   {fee.definition.map(feeDetails => (
  //                     <div className="rule-box" key={feeDetails.id}>
  //                       <div className="rule-details">
  //                         <p>
  //                           {'A '}
  //                           <span className="--detail-green">
  //                             {feeDetails.type === 'flat' && capitalize(`${feeDetails.type} fee`)}
  //                             {feeDetails.type === 'percent' && capitalize(`${feeDetails.type}age fee`)}
  //                           </span>
  //                           {' of '}
  //                           <span className="--detail-green">
  //                             {feeDetails.type === 'flat'
  //                               ? `${activeCurrency} ${formatAmount(feeDetails.total_fee)} `
  //                               : `${formatAmount(feeDetails.total_fee)}% `}
  //                           </span>
  //                           {parseFloat(feeDetails.cap) > 1 && (
  //                             <>
  //                               capped at{' '}
  //                               <span className="--detail-grey">
  //                                 {activeCurrency} {formatAmount(feeDetails.cap)}
  //                               </span>
  //                             </>
  //                           )}
  //                           {' is charged for transactions between '}
  //                           <span className="--detail-grey">{`${activeCurrency} ${formatAmount(feeDetails.range[0])}`}</span>
  //                           {' and '}
  //                           <span className="--detail-grey">{`${activeCurrency} ${formatAmount(feeDetails.range[1])}`}</span>
  //                         </p>

  //                         {isAllowed(userAccess, ['merchant_fee_config.update']) ? (
  //                           <HoverMenu
  //                             title={`${activeCurrency} ${formatAmount(feeDetails.range[0])} to ${activeCurrency} ${formatAmount(
  //                               feeDetails.range[1]
  //                             )}`}
  //                           >
  //                             <li>
  //                               <button
  //                                 type="button"
  //                                 className="btn btn--link"
  //                                 onClick={() => {
  //                                   setState({ modalVisible: true, type: 'edit' });
  //                                   setValues({
  //                                     kind: 'disburse',
  //                                     trxnType: fee.item,
  //                                     feeId: feeDetails.id,
  //                                     currency: activeCurrency,
  //                                     oldValue: feeDetails
  //                                   });
  //                                 }}
  //                               >
  //                                 <img src={edit} alt="edit icon" aria-hidden />
  //                                 <span className="ml-2">Edit</span>
  //                               </button>
  //                             </li>
  //                             <li>
  //                               <button
  //                                 className="text-danger btn btn--link"
  //                                 type="button"
  //                                 onClick={() => {
  //                                   setState({ modalVisible: true, type: 'del' });
  //                                   setValues({ kind: 'disburse', trxnType: fee.item, feeId: feeDetails.id, currency: activeCurrency });
  //                                 }}
  //                               >
  //                                 <img src={close} alt="close icon" aria-hidden />
  //                                 <span className="ml-2">Delete</span>
  //                               </button>
  //                             </li>
  //                           </HoverMenu>
  //                         ) : null}
  //                       </div>
  //                     </div>
  //                   ))}
  //                 </div>
  //               </div>
  //             )}
  //           </React.Fragment>
  //         ))}
  //       </div>
  //     </section>
  //   );
  // };

  return (
    <div className="element-box">
      <>
        <div className="fees-heading-box">
          <div className="fees-heading-content">
            <h5 className="form-header">Fees</h5>
            <div className="form-desc" style={{ border: 'none', marginBottom: '0' }}>
              Configure the fees for this merchant here. Note that all fees have VAT implications. To change the default fees for all
              merchants, go to <Link to="/dashboard/settings/fees">Settings</Link>.
            </div>
          </div>
          <div className="fees-heading-buttons">
            <div className="fees-heading-buttons-w">
              {feesData?.hasCustomFees &&
                (isAllowed(userAccess, ['merchant_fee_config.update']) ? (
                  <>
                    <button
                      className="btn btn--link"
                      type="button"
                      onClick={() => {
                        setState({ modalVisible: true, type: 'reset' });
                      }}
                    >
                      <i className="phosphor-icon phosphor-icon-rotate-ccw" />
                      <span className="ml-1">Reset All Fee Rules</span>
                    </button>
                    <span className="divider-sm" />
                  </>
                ) : (
                  ''
                ))}

              {isAllowed(userAccess, ['merchant_fee_config.update']) && (
                <button
                  className="btn btn-success ml-2"
                  type="button"
                  onClick={() => {
                    setState({ modalVisible: true, type: 'new' });
                  }}
                >
                  <i className="phosphor-icon phosphor-icon-plus" />
                  <span>Add Fee Rule</span>
                </button>
              )}
            </div>
            {feesData?.hasCustomFees && (
              <div className="fees-custom--text d-flex justify-content-end">
                <div className="d-flex align-items-center">
                  <img src={warning} aria-hidden alt="warning icon" />
                  <p className="mb-0 ml-2">Some fee rules for this merchant have been modified.</p>
                </div>
              </div>
            )}
          </div>
        </div>

        {Object.keys(feesData?.fees || {}).length > 0 && (
          <CurrencyPicker
            options={Object.keys(feesData.fees)}
            onChange={value => {
              setActiveCurrency(value);
            }}
            className="merchants-fees__currency-toggle"
            activeCurrency={activeCurrency}
            label={<strong>Showing fees for:</strong>}
            id="merchant-fees__currency-switch"
          />
        )}

        {collectionFees()}
        {/* {disbursementFees()} */}

        {state.modalVisible && <Modal
          isScrollable={true}
          {...switchFeesModal(state.type)} />}
      </>
    </div>
  );
}
