import Office from '+assets/img/dashboard/office.svg';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { currencyType, modalState, productCategoriesType } from '+types';
import {
  availableProductCategories,
  capitalize,
  capitalizeRemovedash,
  getDate,
  getTime,
  history,
  isAllowed,
  productMapping,
  switchTrxnMessage
} from '+utils';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import RiskIndicator from '../components/RiskIndicator';
import MerchantCurrencyModal from '../MerchantsTable/components/MerchantCurrencyModal';
import './index.scss';
import Products from './Products';

const api = new APIRequest();

const MerchantCurrencyDetails = () => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const { currency, merchant_id } = useParams<{ currency: currencyType; merchant_id: string }>();
  const [activeCurrency, setActiveCurrency] = useState<currencyType>(currency);
  const [activeModal, setActiveModal] = useState<modalState>(null);
  const [productTab, setProductTab] = useState<productCategoriesType>('pay-ins');

  const params = {
    account_id: merchant_id,
    currency: activeCurrency,
    payment_method: undefined
  };
  const {
    data: configData,
    refetch: refetchConfig,
    isLoading
  } = useQuery(
    [`${activeCurrency}_PRODUCT`, merchant_id, activeCurrency, productTab],
    () => api.getProductConfiguration(currency, params),
    {
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error in getting this merchant's details`,
          type: 'danger',
          action: {
            action: () => refetchConfig(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const merchantData = configData?.data;
  const merchantsStatus = configData?.data?.setting?.enabled;
  const merchantCurrencyInfo = {
    [`Merchant's Email`]: merchantData?.account?.email,
    'Date Joined': `${getDate(merchantData?.account?.created_at)} ${getTime(merchantData?.account?.created_at)}`
  };

  const handleMerchantsStatus = (currentStatus: boolean) => setActiveModal(currentStatus ? 'disable-one' : 'enable-one');
  const getSelectedProduct = (product: productCategoriesType) => {
    const productConfig = merchantData?.setting?.transaction[productMapping[product]];
    return productConfig;
  };

  return (
    <>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
              <i className="phosphor-icon phosphor-icon-arrow-left" />
              <span>{`Back to ${activeCurrency} Merchants`}</span>
            </button>
          </div>
          {isLoading ? (
            <LoadingPlaceholder type="text" background="#f5f6f6" />
          ) : (
            <>
              <div className="top-row__container">
                <div className="__left">
                  <div>
                    <span>
                      <img src={Office} alt="office" />
                    </span>
                    <p>Merchant</p>
                  </div>
                  <div>
                    <h4>{capitalize(merchantData?.account?.name)}</h4>
                    <p>{activeCurrency}</p>
                    <span
                      className="status"
                      style={{
                        color: switchTrxnMessage[merchantsStatus ? 'enabled' : 'disabled']?.color,
                        backgroundColor: switchTrxnMessage[merchantsStatus ? 'enabled' : 'disabled']?.backgroundColor
                      }}
                    >
                      {switchTrxnMessage[merchantsStatus ? 'enabled' : 'disabled']?.name}
                    </span>
                  </div>
                  <div>
                    <p>{`ID: ${merchant_id}`}</p>
                    <span />
                    <p>Enterprise</p>
                  </div>
                </div>
                <div className="__right">
                  <CurrencyPicker
                    options={merchantData?.currencies}
                    onChange={(value) => {
                      setActiveCurrency(value);
                    }}
                    className="business-settings__currency-switch"
                    activeCurrency={activeCurrency?.toUpperCase()}
                    label={null}
                  />
                  {isAllowed(userAccess, ['transaction_config_details.update']) && (
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={() => handleMerchantsStatus(merchantsStatus)}
                      onKeyDown={() => handleMerchantsStatus(merchantsStatus)}
                    >
                      <p>{`${merchantsStatus ? 'Disable' : 'Enable'} this merchant’s ${activeCurrency} access`}</p>
                    </span>
                  )}
                </div>
              </div>
              <div className="details-container">
                <article>
                  <ul>
                    {Object.entries(merchantCurrencyInfo).map(([key, value]) => (
                      <li key={key}>
                        <span>{capitalize(key)}</span>
                        <span>{capitalizeRemovedash(value)}</span>
                      </li>
                    ))}
                  </ul>
                </article>
                <div>
                  <p>Risk Rating:</p>
                  <RiskIndicator riskLevel={merchantData?.account?.risk_level} />
                </div>
              </div>
            </>
          )}
          {/* Product section */}
          <div className="nav-content active">
            <section className="os-tabs-w">
              <div className="os-tabs-controls os-tabs-complex">
                <ul className="nav nav-tabs" role="tablist">
                  {Object.entries(availableProductCategories).map(([key, value]) => {
                    return (
                      <li
                        className="nav-item"
                        key={key}
                        role="tab"
                        onClick={() => {
                          setProductTab(key as productCategoriesType);
                        }}
                        onKeyDown={() => {
                          setProductTab(key as productCategoriesType);
                        }}
                      >
                        <div className={`nav-link ${key === productTab && 'active'}`}>{`${capitalizeRemovedash(value)} Product`}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
          </div>
          {isLoading ? (
            <LoadingPlaceholder type="text" background="#f5f6f6" />
          ) : (
            <Products
              type={productTab}
              config={getSelectedProduct(productTab)}
              merchantId={merchant_id}
              currency={activeCurrency}
              merchantsStatus={merchantsStatus}
            />
          )}
        </div>
      </div>
      {activeModal && (
        <MerchantCurrencyModal
          activeModal={activeModal}
          setActiveModal={setActiveModal}
          currency={activeCurrency}
          merchantId={merchant_id}
          hideButton
        />
      )}
    </>
  );
};

export default MerchantCurrencyDetails;
