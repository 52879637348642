import FeedbackIcon from '+assets/img/dashboard/feedback.svg';
import BusinessAvatar from '+assets/theme/img/biz-avatar.png';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  getDate,
  getTime,
  history,
  isAllowed,
  logError,
  queriesParams,
  switchStatus
} from '+utils';
import React from 'react';
import { useQuery } from 'react-query';
import MerchantCompliancePicker from '../MerchantKyc/components/MerchantCompliancePicker';
import './index.scss';

const apiRequest = new APIRequest();

const MerchantsCompliance = () => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;
  const activeTab = searchQuery.value.kycTab || 'Signup';

  const valuesToBeRemoved = [queriesParams.tab, queriesParams.page, queriesParams.limit, queriesParams.kycTab];
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const tabs = ['Signup', 'KYC', 'Feedback', 'Ready', 'Verified', 'Rejected', 'BVN'];
  const initialData = {
    data: [],
    paging: { current: 1, next: 2, count: 0, page_size: 10, total_items: 0 }
  };

  const { data: merchantStats, isLoading: merchantStatsLoading } = useQuery(
    `MERCHANT_STATISTICS`,
    () => apiRequest.getMerchantStatistics(),
    {
      refetchOnMount: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching merchants statistics.`,
          type: 'danger'
        });
      }
    }
  );

  const { data: liveMerchantsResponse, isLoading: liveMerchantsLoading } = useQuery(
    ['LIVE_MERCHANTS', queriesParams.verified, page, sortingParams, limit],
    () => apiRequest.getMerchants(queriesParams.verified, page, sortingParams, limit),
    {
      refetchOnMount: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching live merchants.`,
          type: 'danger'
        });
      }
    }
  );

  const { data: kycMerchantsResponse, isLoading: kycMerchantsLoading } = useQuery(
    ['KYC_MERCHANTS', queriesParams.kyc, page, sortingParams, limit],
    () => apiRequest.getMerchants(queriesParams.kyc, page, sortingParams, limit),
    {
      refetchOnMount: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching merchants without completed KYC.`,
          type: 'danger'
        });
      }
    }
  );

  const { data: feedbackMerchantsResponse, isLoading: feedbackMerchantsLoading } = useQuery(
    ['FEEDBACK_MERCHANTS', queriesParams.feedback, page, sortingParams, limit],
    () => apiRequest.getMerchants(queriesParams.feedback, page, sortingParams, limit),
    {
      refetchOnMount: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching merchants with feedbacks.`,
          type: 'danger'
        });
      }
    }
  );

  const { data: readyMerchantsResponse, isLoading: readyMerchantsLoading } = useQuery(
    ['READY_MERCHANTS', queriesParams.ready, page, sortingParams, limit],
    () => apiRequest.getMerchants(queriesParams.ready, page, sortingParams, limit),
    {
      refetchOnMount: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching ready merchants.`,
          type: 'danger'
        });
      }
    }
  );

  const { data: signupMerchantsResponse, isLoading: signupMerchantsLoading } = useQuery(
    ['SIGNUP_MERCHANTS', queriesParams.signup, page, sortingParams, limit],
    () => apiRequest.getMerchants(queriesParams.signup, page, sortingParams, limit),
    {
      refetchOnMount: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching signup merchants.`,
          type: 'danger'
        });
      }
    }
  );

  const { data: rejectedMerchantsResponse, isLoading: rejectedMerchantsLoading } = useQuery(
    ['REJECTED_MERCHANTS', queriesParams.rejected, page, sortingParams, limit],
    () => apiRequest.getMerchants(queriesParams.rejected, page, sortingParams, limit),
    {
      refetchOnMount: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching rejected merchants.`,
          type: 'danger'
        });
      }
    }
  );

  const { data: bvnMerchantsResponse, isLoading: bvnMerchantsLoading } = useQuery(
    ['BVN_MERCHANTS', queriesParams.submitted, page, sortingParams, limit],
    () => apiRequest.getMerchants(queriesParams.submitted, page, sortingParams, limit),
    {
      refetchOnMount: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching merchants.`,
          type: 'danger'
        });
      }
    }
  );

  const liveMerchants = liveMerchantsResponse?.data ?? initialData;
  const readyToGoLiveMerchants = readyMerchantsResponse?.data ?? initialData;
  const kycMerchants = kycMerchantsResponse?.data ?? initialData;
  const feedbackMerchants = feedbackMerchantsResponse?.data ?? initialData;
  const signupMerchants = signupMerchantsResponse?.data ?? initialData;
  const rejectedMerchants = rejectedMerchantsResponse?.data ?? initialData;
  const bvnMerchants = bvnMerchantsResponse?.data ?? initialData;

  const isLoading =
    merchantStatsLoading ||
    liveMerchantsLoading ||
    signupMerchantsLoading ||
    readyMerchantsLoading ||
    kycMerchantsLoading ||
    feedbackMerchantsLoading ||
    rejectedMerchantsLoading ||
    bvnMerchantsLoading;

  const editMerchant = (merchant) => {
    if (isAllowed(userAccess, ['merchant_compliance_details.view'])) {
      return history.push(`/dashboard/kyc-approval/${merchant.id}`);
    }
    return history.push(`/dashboard/access-denied`);
  };

  const handleTabSwitch = (tab) => {
    searchQuery.setQuery({ kycTab: tab, page: '1' });
  };

  const renderPagination = () => {
    if (activeTab === 'Ready') return [readyToGoLiveMerchants.paging.total_items, readyToGoLiveMerchants.paging.page_size];
    if (activeTab === 'Signup') return [signupMerchants.paging.total_items, signupMerchants.paging.page_size];
    if (activeTab === 'Feedback') return [feedbackMerchants.paging.total_items, feedbackMerchants.paging.page_size];
    if (activeTab === 'KYC') return [kycMerchants.paging.total_items, kycMerchants.paging.page_size];
    if (activeTab === 'Rejected') return [rejectedMerchants.paging.total_items, rejectedMerchants.paging.page_size];
    if (activeTab === 'BVN') return [bvnMerchants.paging.total_items, bvnMerchants.paging.page_size];
    return [liveMerchants.paging.total_items, liveMerchants.paging.page_size];
  };

  const headingSwitch = {
    Verified: `Verified merchants have completed the sign up process and have been verified to go live with full access to their dashboard to make payments.`,
    Ready: `Ready Merchants await verification to go live and make real payments on their dashboard. Their details and business documents need to be reviewed and verified before they can go live.`,
    KYC: `Here, you will discover prospective merchants who have initiated the KYC process but have not yet finished or submitted it.`,
    Signup: `The list below comprises prospective merchants who have registered with BvnkNG but have not yet verified their email addresses, and as a result, they haven't been able to initiate their KYC process.`,
    Feedback: `The list below consists of prospective merchants who initially submitted their KYC but received feedback to update one or more parameters.`,
    Rejected: `Below is a list of merchants who have registered or submitted their KYC but got rejected.`,
    BVN: `Existing merchants who have submitted their BVN will appear here`
  };

  const kycSwitch = {
    Verified: liveMerchants,
    Ready: readyToGoLiveMerchants,
    KYC: kycMerchants,
    Signup: signupMerchants,
    Feedback: feedbackMerchants,
    Rejected: rejectedMerchants,
    BVN: bvnMerchants
  };

  const compliance = {
    className: `--compliance-table ${activeTab === 'Rejected' ? 'compliance-rejected-table' : ''}`,
    rowURL: '/dashboard/kyc-approval',
    rowKey: 'id',
    emptyStateHeading: 'No Merchants yet',
    emptyStateMessage: 'It looks like there are no results yet.',
    annotations: 'merchant(s)',
    fields: (iter) => ({
      data: {
        merchant: (
          <>
            <img alt="business avatar" src={BusinessAvatar} />
            <span id="merchant-name" className={activeTab === 'Rejected' ? 'is-rejected' : ''}>
              {capitalize(iter.name)}
            </span>
          </>
        ),

        ...(activeTab !== 'Rejected'
          ? {
            status: (
              <>
                {iter.has_compliance_feedback === 1 && <img alt="" src={FeedbackIcon} className="feedback-notifier" />}
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            )
          }
          : {
            status: (
              <>
                <span className="status-pill smaller red" />
                <span>Declined</span>
              </>
            )
          }),

        ...(activeTab === 'Rejected'
          ? {
            reason_for_rejection: (
              <span title={iter.merchant_info?.compliance?.[0]?.rejected_reason}>
                {iter.merchant_info?.compliance?.[0]?.rejected_reason}
              </span>
            )
          }
          : { merchant_id: iter.id }),
        contact: <span className="lighter">{iter.email}</span>,
        date_created: (
          <>
            <span>{getDate(iter.created_at)}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {getTime(iter.created_at)}
            </span>
          </>
        )
      }
    }),
    rowFn: (iter) => editMerchant(iter)
  };

  const tableDataKeys = Object.keys(compliance.fields({}).data);

  const tabsCount = {
    Feedback: merchantStats?.kyc_status?.feedback || 0,
    Ready: merchantStats?.kyc_status?.ready || 0,
    Rejected: merchantStats?.kyc_status?.rejected || 0
  };

  const exportMerchantList = async (format, close, fieldsToExport) => {
    try {
      const res = await apiRequest.exportMerchants(fieldsToExport, format, {
        ...sortingParams,
        leadsClass: activeTab.toLowerCase()
      });

      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `${activeTab} Merchant list at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            {' '}
            - Successfully exported <strong>{renderPagination()[0]} records.</strong>
          </>
        ),
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your merchant list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  if (!userAccess) return <div />;

  return (
    <div className="element-box">
      <div className="element-box-heading settlements-settings__heading">
        <div className="heading-box-mmd">
          <h5 className="form-header">Merchant Compliance</h5>
          <div className="form-desc no-underline mb-2">
            Here’s where you can find and manage compliance-related settings for all merchants.
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <MerchantCompliancePicker
              options={tabs}
              onChange={(value) => handleTabSwitch(value)}
              activeTab={activeTab}
              tabCount={tabsCount}
              id="default-merchants-compliance__tab-switch"
            />

            <div className="form-desc compliance-heading-text">{headingSwitch[activeTab]}</div>

            <legend>
              <span />
            </legend>

            <Table
              className={compliance.className}
              loading={isLoading}
              data={kycSwitch[activeTab]?.data}
              renderFields
              hasPagination
              tableHeadings={tableDataKeys}
              annotation={compliance.annotations}
              current={parseInt(page, 10)}
              rowKey={compliance.rowKey}
              rowURL={compliance.rowURL}
              pageSize={renderPagination()[1] || 0}
              totalItems={renderPagination()[0] || 0}
              limitAction={(currentLimit) => searchQuery.setQuery({ limit: currentLimit })}
              actionFn={(current) => searchQuery.setQuery({ page: current })}
              emptyStateHeading={compliance.emptyStateHeading || ''}
              tableWrapperClassName="table-responsive table-wrapper"
              emptyStateMessage={compliance.emptyStateMessage || ''}
              type="Merchant Compliance"
              filterType="merchant"
              storedState={{ activeTab }}
              filterKeywordPlaceholder="Search..."
              filterQueryIDPlaceholder="Merchant ID"
              filterExportAction={exportMerchantList}
              filterTotalCount={renderPagination()[0]}
              filterShowExport={isAllowed(userAccess, ['merchants.export'])}
              filterExportModalHeading="Export Merchants"
              filterExportModalDescription="Choose how you would like to export these merchants"
              isRowClickable={isAllowed(userAccess, ['merchant_compliance_details.view'])}
            >
              {compliance.fields}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantsCompliance;
