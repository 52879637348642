/* eslint-disable camelcase */
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import FilterComponent from '+shared/Filter';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { APIDownload, capitalizeFirst, filteredOutObjectProperty, getDate, getTime, history, isAllowed, logError } from '+utils';
import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
import Table from '../../Shared/Table';
import './index.scss';

const apiRequest = new APIRequest();

const RolesList = () => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const status = searchQuery.value.status || [];

  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, ['current', 'limit', 'status'])
  };

  const {
    data: resolvedData,
    refetch,
    isFetching
  } = useQuery(['ROLES', page, limit, sortingParams], () => apiRequest.getAllRoles(page, limit, sortingParams), {
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        title: 'Roles',
        message: 'There has been an error getting roles',
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    }
  });

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging || [];
  const exportRoles = async (format, close, fieldsToExport) => {
    try {
      const res = await apiRequest.exportReversals(format, fieldsToExport, sortingParams);
      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Roles Report for ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            {' '}
            - Successfully exported <strong>{paging?.total_items} roles.</strong>
          </>
        ),
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your roles file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const navigateToRole = useCallback((slug) => {
    if (!isAllowed(userAccess, ['custom_roles.update', 'my_custom_roles.update', 'system_roles.update'])) return null;
    return history.push(`/dashboard/users/roles/${slug}`);
  });

  const renderRoles = () => (
    <div className="div-table-tablebody">
      {data.map((value) => {
        return (
          <div
            key={`reversal_${value?.id}`}
            className="div-table --users-table --row --white"
            role="button"
            tabIndex={0}
            onClick={() => navigateToRole(value?.id)}
            onKeyDown={() => navigateToRole(value?.id)}
          >
            <div style={{ display: 'flex' }}>
              <span className="body-row-header">Role:</span>
              <span role="button" tabIndex="0" className="smaller no-ellipsis">
                {capitalizeFirst(value?.name)}
              </span>
            </div>
            <div>
              <span className="body-row-header">Creator Name/Email:</span>
              <span className="smaller no-ellipsis" style={{ color: 'rgb(116, 71, 253)' }} role="button" tabIndex="0">
                {(
                  <span>
                    {`${value?.createdBy?.first_name ?? 'N/A'} ${value?.createdBy?.last_name ?? ''}`}{' '}
                    {value?.createdBy?.email ? (
                      <span className="user-email-list" style={{ color: '#979797' }}>
                        ({value?.createdBy?.email})
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                ) || 'Not available'}
              </span>
            </div>
            <div>
              <span className="body-row-header">Date Created:</span>
              <span>{getDate(value?.createdAt)}</span>
              <span className="annotation">{getTime(value?.createdAt)}</span>
            </div>
            <div>
              <span className="body-row-header">Last Modified:</span>
              <span>{getDate(value?.updatedAt)}</span>
              <span className="annotation">{getTime(value?.updatedAt)}</span>
            </div>
          </div>
        );
      })}
    </div>
  );

  const rolesTable = () => {
    return (
      <Table
        className="--users-table div-table-tablehead"
        hasPagination
        header={false}
        tableHeadings={['Role', 'Creator Name/Email', 'Date Created', 'Last Modified']}
        totalItems={paging?.total_items || 0}
        pageSize={paging?.page_size || 0}
        loading={isFetching}
        current={parseInt(page, 10)}
        actionFn={(num) => searchQuery.setQuery({ page: num })}
        limitAction={(no) => searchQuery.setQuery({ limit: no })}
        annotation="Roles"
        tableWrapperClassName="table-responsive"
        hasFilter={false}
      >
        {renderRoles()}
      </Table>
    );
  };

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12">
            <button type="button" className="btn btn-link mb-2 px-0" onClick={() => history.push('/dashboard/users')}>
              <i className="phosphor-icon phosphor-icon-arrow-left" />
              <span>Back</span>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 users__page">
            <div className="element-wrapper">
              <h6 className="element-header">Dashboard Roles</h6>
              <FilterComponent type="roles" hasAdvancedFilter={false} exportAction={exportRoles} totalCount={paging?.total_items} />
              <div>{isFetching ? <LoadingPlaceholder type="table" /> : rolesTable()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesList;
