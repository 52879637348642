import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';

export interface INotificationSettings {
  paginate: boolean;
  read?: boolean;
  frequency?: string;
  notificationEnabled?: boolean;
  type?: string;
}

interface Notification {
  utilities: boolean;
  read: boolean;
  id: string;
  subject: string;
  body: string;
  createdAt: string;
}

interface UseNotificationsResult {
  showNotification: boolean;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
  notifications: Notification[];
  isNotificationUnread: boolean;
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
  notificationCount: number;
  setNotificationCount: React.Dispatch<React.SetStateAction<number>>;
  notificationSettings: INotificationSettings;
  setNotificationSettings: React.Dispatch<React.SetStateAction<INotificationSettings>>;
  sortingParams: INotificationSettings;
  setSortingParams: React.Dispatch<React.SetStateAction<INotificationSettings>>;
  handleNotificationRefetch: (paginate: boolean, read: boolean, otherParams?: Record<string, any>, isNotificationEnabled: boolean) => void;
  handleShowNotification: () => void;
  handleOutsideClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const useNotifications = (): UseNotificationsResult => {
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationSettings, setNotificationSettings] = useState<INotificationSettings>({});
  const [isNotificationUnread, setIsNotificationUnread] = useState(false);
  const { feedbackInit } = useFeedbackHandler();
  // useEffect(() => {}, []);
  const [sortingParams, setSortingParams] = useState<INotificationSettings>({
    paginate: false,
    read: false,
    frequency: 'daily'
  });

  const handleNotificationRefetch = (
    paginate: boolean,
    read: boolean,
    otherParams: Record<string, any> | undefined,
    isNotificationEnabled: boolean
  ) => {
    setSortingParams({
      paginate,
      ...(read === false && { read }),
      ...(otherParams || {})
    });
    setNotificationSettings({
      ...notificationSettings,
      paginate,
      read,
      ...(otherParams || {}),
      notificationEnabled: isNotificationEnabled
    });
    fetchAllNotifications();
  };

  const handleShowNotification = () => {
    setShowNotification(true);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLElement>) => {
    // if (!event.target.closest('.notification-sheet') && !event.target.closest('.onboarding-modal')) {
    setShowNotification(false);
    // }
  };

  const errorDispatch = (message: string) =>
    feedbackInit({
      message,
      type: 'danger'
    });

  const notificationConfigKey = 'NOTIFICATION_SETTINGS';

  useQuery(notificationConfigKey, () => api.getNotificationConfig(), {
    cacheTime: 0,
    onSuccess: configData => {
      const notificationConfig = configData?.data?.config;
      if (notificationConfig?.notificationEnabled) {
        setSortingParams({
          paginate: false,
          ...(notificationConfig?.read === false && { read: notificationConfig?.read }),
          type: notificationConfig?.type,
          frequency: notificationConfig?.frequency
        });
      } else {
        setSortingParams({
          paginate: false,
          frequency: 'daily'
        });
      }
      setNotificationSettings(notificationConfig);
    }
    // onError: () => errorDispatch(`There has been an error fetching notification settings.`)
  });

  const { refetch: fetchAllNotifications } = useQuery(
    [
      `ALL_NOTIFICATIONS_${sortingParams.type ?? 'all'}`,
      sortingParams.paginate,
      sortingParams.read,
      sortingParams.type,
      sortingParams.frequency
    ],
    () => api.getNotifications(sortingParams),
    {
      enabled: !!notificationConfigKey,
      cacheTime: 0,
      onSuccess: notificationData => {
        notificationData?.notifications?.forEach((notification: { utilities: boolean; read: boolean }) => {
          if (notification?.utilities === true) {
            sessionStorage.setItem('isUtility', 'true');
          }
          if (notification?.read === false) {
            setIsNotificationUnread(true);
          }
        });
        if (notificationSettings?.notificationEnabled) {
          setNotifications(notificationData?.notifications);
          setNotificationCount(notificationData?.count);
        } else {
          setNotifications([]);
          setNotificationCount(0);
        }
      }
    }
  );

  return {
    showNotification,
    setShowNotification,
    notifications,
    isNotificationUnread,
    setNotifications,
    notificationCount,
    setNotificationCount,
    notificationSettings,
    setNotificationSettings,
    sortingParams,
    setSortingParams,
    handleNotificationRefetch,
    handleShowNotification,
    handleOutsideClick
  };
};

export default useNotifications;

export const useOutsideClick = callback => {
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        if (!e.target.closest('.notification-sheet') && !e.target.closest('.onboarding-modal')) {
          callback();
        }
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);
  return ref;
};
