/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import Icon from '+containers/Dashboard/Shared/Icons';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import EmptyState from '+shared/EmptyState';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Modal from '+shared/Modal';
import SecondaryDetails from '+shared/SecondaryDetails';
import { capitalizeFirst, getDate, getTime, history, isAllowed, logError } from '+utils';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ConfirmInvitation from '../components/ConfirmInvitation';
import Assignees from './components/Assignees';
import RolePermission from './components/RolePermission';
import './index.scss';

const apiRequest = new APIRequest();

export default function RoleDetails() {
  const { id } = useParams();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const [roleName, setRoleName] = useState('');

  const [modal, setModal] = useState({
    modalType: null,
    modalInfo: null
  });

  const [tabState, setTabState] = useState({
    isActive: true,
    activeTab: 'Assignees',
    tabs: ['Assignees', 'Permissions']
  });

  const {
    data: detailsData,
    isLoading: isFetchingDetails,
    refetch: refetchDetails
  } = useQuery(`${id}_ROLE_DETAILS`, () => apiRequest.getRole(id), {
    onError: () => {
      feedbackInit({
        message: "There has been an error getting this role's information",
        type: 'danger',
        action: {
          action: () => refetchDetails(),
          name: 'Try again'
        }
      });
    }
  });
  const switchTabs = () => {
    switch (tabState.activeTab) {
      case 'Assignees':
        return <Assignees roleDetails={detailsData} />;
      case 'Permissions':
        return <RolePermission roleDetails={detailsData} />;
      default:
        return <Assignees roleDetails={detailsData} />;
    }
  };

  const updateRoleMutation = useMutation(
    () => apiRequest.updateRole(id, { name: roleName, permissions: [...new Set(detailsData.permissions)] }),
    {
      onSuccess: () => {
        refetchDetails();
      },
      onError: (error) => {
        logError(error);
        feedbackInit({
          message: `There has been an error updating this role`,
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const setEditRoleNameModal = () => {
    setModal({
      modalType: 'edit-role',
      modalInfo: {
        heading: 'Edit role name?',
        description: 'You are about to edit the name of the role.',
        content: <ConfirmInvitation saveState getRoleName={(value) => setRoleName(value)} roleName={roleName} />,
        completedHeading: 'Confirmed!',
        completedDescription: 'The changes you made to this role’s name have been confirmed.',
        action: () => {
          closeFeedback();
          return updateRoleMutation.mutateAsync();
        },
        secondButtonText: 'Save & Edit',
        completedModalSize: 'base',
        equalFooterBtn: true,
        headerBottomBorder: false
      }
    });
  };
  const getRoleDetails = () => {
    const roleDetails = [
      {
        'Role Name': `${capitalizeFirst(detailsData?.name)}`,
        'Role Type': `${capitalizeFirst(detailsData?.category ?? 'N/A')}`,
        'Number of assignees': `${detailsData?.numberOfAssignees}`
      },
      {
        'Date created': (
          <>
            {getDate(detailsData.createdAt)}
            <span className="smaller lighter" style={{ marginLeft: '5px' }}>
              {getTime(detailsData.createdAt)}
            </span>
          </>
        ),
        'Last modified': (
          <>
            {getDate(detailsData.createdAt)}
            <span className="smaller lighter" style={{ marginLeft: '5px' }}>
              {getTime(detailsData.updatedAt)}
            </span>
          </>
        )
      }
    ];
    return roleDetails;
  };

  return (
    <div className="content-box">
      <div className="row">
        <div className="col-sm-12">
          <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
            <i className="phosphor-icon phosphor-icon-arrow-left" />
            <span className="back-to-roles">Back to Roles</span>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="content-details-box">
                <div className="content-details-head --stl-details-head mb-0">
                  <div className="content-details-title">
                    <div className="cd-title ml-3">
                      <div className="cd-maintitle-btn">
                        <h4 className="cd-maintitle">{capitalizeFirst(detailsData?.name ?? '')}</h4>
                        {!isFetchingDetails &&
                          isAllowed(userAccess, ['custom_roles.update', 'my_custom_roles.update', 'system_roles.update']) &&
                          detailsData?.category !== 'system' && (
                            <button type="button" onClick={setEditRoleNameModal}>
                              <span>
                                <Icon name="editPen" />
                              </span>
                              Edit role name
                            </button>
                          )}
                      </div>
                      <div className="cd-subtitle">{capitalizeFirst(detailsData?.category ?? 'N/A')}</div>
                    </div>
                  </div>
                </div>
                <div className="content-details-body --stl-details-body">
                  <div className="row">
                    <div className="col-sm-12 mb-5">
                      <div className="os-tabs-w">
                        <div className="os-tabs-controls os-tabs-complex os-rolesummary-tabs mb-0">
                          <div className="os-rolesummary os-rolesummary-box">
                            {isFetchingDetails ? (
                              <LoadingPlaceholder type="text" content={4} />
                            ) : (
                              <>
                                {Object.keys(detailsData || {}).length > 0 ? (
                                  <SecondaryDetails title="Role Summary" data={getRoleDetails()} />
                                ) : (
                                  <EmptyState message="There is an issue getting this role information" />
                                )}
                              </>
                            )}
                          </div>

                          <div className="os-rolesummary">
                            <ul className="nav nav-tabs mx-3" style={{ paddingLeft: 0, paddingBottom: 0, flexWrap: 'nowrap' }}>
                              {tabState.tabs.map((tab) => {
                                return (
                                  <li className="nav-item" key={tab}>
                                    <button
                                      type="button"
                                      onClick={() => setTabState({ ...tabState, activeTab: tab })}
                                      className={`nav-link ${tabState.activeTab === tab && 'active'}`}
                                      style={{ fontWeight: 600 }}
                                    >
                                      {tab}
                                    </button>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                        {switchTabs()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal.modalType && (
        <Modal
          close={() => setModal({ modalType: null, modalInfo: {} })}
          heading={modal.modalInfo?.heading}
          description={modal.modalInfo?.description}
          content={modal.modalInfo?.content}
          size="md"
          secondButtonText={modal.modalInfo?.secondButtonText}
          secondButtonAction={() => modal.modalInfo?.action()}
          completedHeading={modal.modalInfo?.completedHeading}
          completedDescription={modal.modalInfo?.completedDescription}
          secondaryCompletedModal
          completedModalSize={modal.modalInfo?.completedModalSize}
          equalFooterBtn={modal.modalInfo?.equalFooterBtn}
          headerBottomBorder={modal.modalInfo?.headerBottomBorder}
          secondButtonDisable={!roleName}
        />
      )}
    </div>
  );
}
