/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import info from '+assets/img/dashboard/info.svg';
import minus from '+assets/img/dashboard/minus.svg';
import plus from '+assets/img/dashboard/plus.svg';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import CustomCheckbox from '+shared/CustomCheckbox';
import Modal from '+shared/Modal';
import { capitalizeRemovedash, defaultScrollToTop, isAllowed, logError, swapArrayPositions } from '+utils';
import React, { useLayoutEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import EmptyStateComponent from './EmptyState';
import RiskLevel from './RiskLevel';
import './SettlementsConfig.scss';
import SettlementsConfigInfoModal from './SettlementsConfigInfoModal';

const api = new APIRequest();

interface ISettlementsConfigProps {
  data?: Record<string, any> | null;
  activeCurrency: string;
  getConfig: () => void;
  source: string;
  merchantID?: string | null;
  merchantRiskLevel?: string;
}
function SettlementsConfig({ data, activeCurrency, getConfig, source, merchantID, merchantRiskLevel }: ISettlementsConfigProps) {
  const { feedbackInit } = useFeedbackHandler();
  const [defaultConfig, setDefaultConfig] = useState({
    isSavingModalVisible: false,
    isResetModalVisible: false,
    isLoading: false,
    isEditing: false,
    cycle: {
      mobile_money: {},
      card: {},
      bank_transfer: {},
      virtual_bank_account: {},
      pay_with_bank: {}
    },
    destination: 'disbursement_wallet'
  });
  const [infoModalVisible, setInfoModalVisible] = useState({
    type: '',
    visible: false
  });

  const location = useLocation();
  const userAccess = useSetUserAccess(location);

  const [activePaymentMethod, setActivePaymentMethod] = useState('bank_transfer');

  const switchRiskLevel = {
    low_risk: 'Low-Risk',
    medium_risk: 'Medium-Risk',
    above_average_risk: 'Semi-High-Risk',
    high_risk: 'High-Risk'
  };

  // Only use this when you want to edit the default name
  const switchPaymentMethod = {
    card: 'Card Payments',
    virtual_bank_account: 'VBA',
    pay_with_bank: 'Pay with Bank (Direct debit)',
    mobile_money: 'Mobile Money'
  };

  const destinationOptions = {
    NGN: [{ name: 'Settlement Bank Account', value: 'settlement_account' }],
    USD: [{ name: 'Settlement Bank Account', value: 'settlement_account' }],
    KES: [{ name: 'Settlement Bank Account', value: 'settlement_account' }],
    GHS: [{ name: 'Settlement Bank Account', value: 'settlement_account' }],
    ZAR: [{ name: 'Settlement Bank Account', value: 'settlement_account' }]
  };
  // const destinationOptions = {
  //   NGN: [{ name: 'BvnkNG Balance', value: 'disbursement_wallet' }, { name: 'Settlement Bank Account', value: 'settlement_account' }],
  //   USD: [{ name: 'BvnkNG Balance', value: 'disbursement_wallet' }],
  //   KES: [{ name: 'BvnkNG Balance', value: 'disbursement_wallet' }, { name: 'Settlement Bank Account', value: 'settlement_account' }],
  //   GHS: [{ name: 'BvnkNG Balance', value: 'disbursement_wallet' }],
  //   ZAR: [{ name: 'BvnkNG Balance', value: 'disbursement_wallet' }]
  // };

  const validateParsedValue = value => {
    if (value > 50) {
      return 50;
    }
    if (!value || value < 0) {
      return 0;
    }
    return value;
  };

  const checkEditing = () => {
    if (!defaultConfig.isEditing) {
      setDefaultConfig(prevConfig => ({
        ...prevConfig,
        isEditing: true
      }));
    }
  };

  useLayoutEffect(() => {
    if (data) {
      const currencyConfig = data?.[activeCurrency];
      if (currencyConfig) {
        setDefaultConfig(prevConfig => ({
          ...prevConfig,
          cycle: currencyConfig?.cycle || prevConfig.config,
          destination: currencyConfig?.destination || prevConfig.destination
        }));
      }
    }
  }, [data, activeCurrency]);

  useLayoutEffect(() => {
    if (defaultConfig.isEditing) {
      setDefaultConfig(prevConfig => ({
        ...prevConfig,
        isEditing: false
      }));
    }
    if (activeCurrency === 'NGN') {
      setActivePaymentMethod('bank_transfer');
    } else if (activeCurrency === 'GHS') {
      setActivePaymentMethod('mobile_money');
    } else {
      // Update this with the default payment method per currency if required
      setActivePaymentMethod('card');
    }
  }, [activeCurrency]);

  const onError = () => {
    feedbackInit({
      type: 'danger',
      message: 'Unable to update settlement configurations'
    });
  };

  const updateDefaultSettlementsConfigMutation = useMutation(settlementData => api.updateDefaultSettlementsConfig(settlementData), {
    onError
  });
  const resetMerchantSettlementsConfigMutation = useMutation(id => api.resetMerchantSettlementsConfig(id), { onError });
  const updateMerchantSettlementsConfigMutation = useMutation(update => api.updateMerchantSettlementsConfig(update.id, update.data), {
    onError
  });

  const updateSettlementsConfig = async () => {
    const newSettlementData = {
      currency: activeCurrency,
      settings: {
        destination: defaultConfig.destination,
        cycle: defaultConfig.cycle
      }
    };
    function checkUpdate() {
      return source === 'default'
        ? updateDefaultSettlementsConfigMutation.mutateAsync(newSettlementData)
        : updateMerchantSettlementsConfigMutation.mutateAsync({ id: merchantID, data: newSettlementData });
    }
    try {
      await checkUpdate();
      getConfig();
      feedbackInit({
        message: `Successfully updated ${source} settlements configuration`,
        type: 'success'
      });
      setDefaultConfig(prevConfig => ({
        ...prevConfig,
        isEditing: false
      }));
    } catch (error) {
      logError(error);
      defaultScrollToTop();
      feedbackInit({
        message: error?.response?.data?.message || 'There was an error updating the settlements configuration',
        type: 'danger'
      });
      throw error;
    }
  };

  const resetConfig = async () => {
    try {
      resetMerchantSettlementsConfigMutation.mutate(merchantID);
      getConfig();
    } catch (error) {
      logError(error);
      feedbackInit({
        message: error?.response?.data?.message,
        type: 'danger'
      });
      throw error;
    }
  };

  const settlementData = data?.[activeCurrency];

  const defaultSettlementTiming = () => {
    const settlementTiming = defaultConfig.cycle[activePaymentMethod];
    const riskLevels = Object.keys(settlementTiming || {});

    return (
      <>
        {riskLevels?.map(riskLevel => (
          <div className="grey-section__subsection-group" key={riskLevel}>
            <div className="grey-section__subsection-item">
              <div>
                <p>
                  {switchRiskLevel[riskLevel]} Merchants{' '}
                  <RiskLevel riskLevel={riskLevel} hideTitle hideDivider className="settlements-settings__risk-level" />
                </p>
                <p>
                  All <span className="text-lowercase">{switchRiskLevel[riskLevel]}</span> merchants would receive settlement based on this
                  timing.
                </p>
              </div>
              <div>
                <div className="timing-controls__group d-flex align-items-center">
                  <p>T +</p>
                  <div className="form-group mb-0">
                    <input
                      className="form-control"
                      value={settlementTiming[riskLevel]}
                      type="number"
                      onChange={e => {
                        const { value } = e.target;
                        const parsedValue = parseInt(value || 0, 10);
                        checkEditing();
                        setDefaultConfig(prevConfig => ({
                          ...prevConfig,
                          cycle: {
                            ...prevConfig.cycle,
                            [activePaymentMethod]: {
                              ...prevConfig.cycle[activePaymentMethod],
                              [riskLevel]: validateParsedValue(parsedValue)
                            }
                          }
                        }));
                      }}
                    />
                  </div>
                  <div className="timing-controls__group d-flex align-items-center">
                    <button
                      onClick={() => {
                        checkEditing();
                        if (settlementTiming[riskLevel] >= 50) return;
                        setDefaultConfig(prevConfig => ({
                          ...prevConfig,
                          cycle: {
                            ...prevConfig.cycle,
                            [activePaymentMethod]: {
                              ...prevConfig.cycle[activePaymentMethod],
                              [riskLevel]: prevConfig.cycle[activePaymentMethod][riskLevel] + 1
                            }
                          }
                        }));
                      }}
                      type="button"
                      className="btn btn-primary"
                      disabled={settlementTiming[riskLevel] >= 50}
                    >
                      <span className="sr-only">{`Increase ${switchRiskLevel[riskLevel]} merchants settlement time`}</span>
                      <img src={plus} alt="increase settlement time" aria-hidden />
                    </button>
                    <button
                      onClick={() => {
                        checkEditing();
                        if (settlementTiming[riskLevel] <= 0) return;
                        setDefaultConfig(prevConfig => ({
                          ...prevConfig,
                          cycle: {
                            ...prevConfig.cycle,
                            [activePaymentMethod]: {
                              ...prevConfig.cycle[activePaymentMethod],
                              [riskLevel]: prevConfig.cycle[activePaymentMethod][riskLevel] - 1
                            }
                          }
                        }));
                      }}
                      type="button"
                      className="btn btn-primary"
                      disabled={settlementTiming[riskLevel] === 0}
                    >
                      <span className="sr-only">{`Reduce ${switchRiskLevel[riskLevel]} merchants settlement time`}</span>
                      <img src={minus} alt="reduce settlement time" aria-hidden />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const merchantSettlementTiming = () => {
    return (
      <div className="grey-section__subsection-group">
        <div className="grey-section__subsection-item">
          <div>
            <p>
              {switchRiskLevel[merchantRiskLevel] || 'All'} Merchant{' '}
              <RiskLevel riskLevel={merchantRiskLevel} hideTitle hideDivider className="settlements-settings__risk-level" />
            </p>
            <p>
              Based on their risk rating, this merchant would receive settlement for this payment method at the timing you have set.
              However, you can choose to modify this timing.
            </p>
          </div>
          <div>
            <div className="timing-controls__group d-flex align-items-center">
              <p>T +</p>
              <div className="form-group mb-0">
                <input
                  className="form-control"
                  value={defaultConfig.cycle[activePaymentMethod] || 0}
                  type="number"
                  onChange={e => {
                    const { value } = e.target;
                    const parsedValue = parseInt(value || 0, 10);
                    checkEditing();
                    setDefaultConfig(prevConfig => ({
                      ...prevConfig,
                      cycle: {
                        ...prevConfig.cycle,
                        [activePaymentMethod]: validateParsedValue(parsedValue)
                      }
                    }));
                  }}
                />
              </div>
              <div className="timing-controls__group d-flex align-items-center">
                <button
                  onClick={() => {
                    checkEditing();
                    if (defaultConfig.cycle[activePaymentMethod] >= 50) return;
                    setDefaultConfig(prevConfig => ({
                      ...prevConfig,
                      cycle: {
                        ...prevConfig.cycle,
                        [activePaymentMethod]: prevConfig.cycle[activePaymentMethod] + 1
                      }
                    }));
                  }}
                  type="button"
                  className="btn btn-primary"
                  disabled={defaultConfig.cycle[activePaymentMethod] >= 50}
                >
                  <span className="sr-only">{`Increase ${switchRiskLevel[merchantRiskLevel]} merchants settlement time`}</span>
                  <img src={plus} alt="increase settlement time" aria-hidden />
                </button>
                <button
                  onClick={() => {
                    checkEditing();
                    if (defaultConfig.cycle[activePaymentMethod] <= 0) return;
                    setDefaultConfig(prevConfig => ({
                      ...prevConfig,
                      cycle: {
                        ...prevConfig.cycle,
                        [activePaymentMethod]: prevConfig.cycle[activePaymentMethod] - 1
                      }
                    }));
                  }}
                  type="button"
                  className="btn btn-primary"
                  disabled={defaultConfig.cycle[activePaymentMethod] === 0}
                >
                  <span className="sr-only">{`Reduce ${switchRiskLevel[merchantRiskLevel]} merchants settlement time`}</span>
                  <img src={minus} alt="reduce settlement time" aria-hidden />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {settlementData ? (
        <>
          <legend>
            <span />
          </legend>

          <div className="settlements-settings__heading-sub">
            <div>
              <h5 className="form-header pt-0">{activeCurrency} Settlements Configuration by Payment Methods</h5>
              <div className="form-desc no-underline mb-1">
                Each payment method has a unique configuration. This is the configuration applied to{' '}
                {source === 'default' ? 'all merchants with access to this currency' : 'this merchant for this currency'}.
              </div>
            </div>
            <span className="settlements-settings__controls">
              {source === 'merchant' &&
                (isAllowed(userAccess, ['merchant_settlement_config.update']) && (
                  <>
                    <button
                      className="btn btn--link"
                      type="button"
                      onClick={() => {
                        setDefaultConfig(prevConfig => ({
                          ...prevConfig,
                          isResetModalVisible: true
                        }));
                      }}
                    >
                      <i className="phosphor-icon phosphor-icon-rotate-ccw" />
                      <span>Reset Configuration</span>
                    </button>
                    <span className="divider-sm" />
                  </>
                ))}

              {(source === 'merchant'
                ? isAllowed(userAccess, ['merchant_settlement_config.update'])
                : isAllowed(userAccess, ['default_settlement_config.update'])) && (
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      if (!defaultConfig.isEditing) return;
                      setDefaultConfig(prevConfig => ({
                        ...prevConfig,
                        isSavingModalVisible: true
                      }));
                    }}
                    disabled={!defaultConfig.isEditing}
                  >
                    <i className="phosphor-icon phosphor-icon-save" />
                    <span>Save Changes</span>
                  </button>
                )}
            </span>
          </div>

          {Object.keys(settlementData?.cycle || {}).length > 0 && (
            <div className="os-tabs-controls os-tabs-complex mx-0">
              <ul className="nav nav-tabs px-0 mb-2">
                {swapArrayPositions(Object.keys(settlementData?.cycle || {}), 0, 1)?.map(paymentMethod => (
                  <li className="nav-item" key={paymentMethod}>
                    <button
                      type="button"
                      key={paymentMethod}
                      className={activePaymentMethod === paymentMethod ? 'nav-link active' : 'nav-link'}
                      data-toggle="tab"
                      onClick={() => setActivePaymentMethod(paymentMethod)}
                    >
                      <span className="tab-label">{switchPaymentMethod[paymentMethod] || capitalizeRemovedash(paymentMethod)}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="settlements-settings__dest-w grey-section">
            <h6 className="grey-section__title">
              Settlement Destination
              <button onClick={() => setInfoModalVisible({ type: 'destination', visible: true })} type="button" className="ml-2">
                <span className="sr-only">Read more about settlement destination</span>
                <img src={info} alt="more information" aria-hidden />
              </button>
            </h6>
            <div className="grey-section__subsection">
              <div className="grey-section__subsection-group">
                <div className="grey-section__subsection-item">
                  <div>
                    <p>Where should {activeCurrency} payments be settled?</p>
                    <p>
                      This is where {source === 'default' ? 'merchants' : 'this merchant'} would receive settlement for their{' '}
                      {activeCurrency} payments.
                    </p>
                  </div>
                  <div>
                    <div className="form-group mb-0">
                      <select
                        name="destination"
                        className="form-control"
                        onChange={e => {
                          const { value } = e.target;
                          checkEditing();
                          setDefaultConfig(prevConfig => ({
                            ...prevConfig,
                            destination: value
                          }));
                        }}
                        value={defaultConfig.destination}
                      >
                        {destinationOptions[activeCurrency]?.map(each => {
                          return (
                            <option key={each.value} value={each.value}>
                              {each.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {Object.keys(settlementData?.cycle || {}).length > 0 ? (
            <div className="settlements-settings__timing-w grey-section">
              <h6 className="grey-section__title">
                Settlement Cycle/Timing
                <button onClick={() => setInfoModalVisible({ type: 'timing', visible: true })} type="button" className="ml-2">
                  <span className="sr-only">Read more about settlement timing</span>
                  <img src={info} alt="more information" aria-hidden />
                </button>
              </h6>
              <div className="grey-section__subsection">
                {source === 'default' && defaultSettlementTiming()}
                {source === 'merchant' && merchantSettlementTiming()}
              </div>
            </div>
          ) : (
            <EmptyStateComponent
              heading="No settlement cycle available"
              message="There seems to be no settlement cycle configuration for this merchant for this currency"
            />
          )}
        </>
      ) : (
        <EmptyStateComponent
          heading="No settlement configurations available"
          message="There seems to be an error getting the settlement configuration "
        />
      )}

      {infoModalVisible.visible && (
        <SettlementsConfigInfoModal
          type={infoModalVisible.type}
          closeModal={() => setInfoModalVisible({ type: '', visible: false })}
          visible
          source={source}
        />
      )}

      {defaultConfig.isSavingModalVisible && (
        <Modal
          heading="Save changes?"
          description={
            <>
              <p className="mb-0 py-3">Please confirm that you want to save the changes made. This action cannot be undone.</p>
              {source === 'merchant' && (
                <p className="mb-0 pb-3">
                  <em>This{` merchant's `}configuration will be treated as custom</em>
                </p>
              )}
              {source === 'default' && (
                <div className="mt-1 mb-2">
                  <CustomCheckbox
                    className="settlements-settings__merchants-checkbox"
                    disabled
                    type="checkbox"
                    checked={false}
                    text="Include merchants with modified configuration"
                  />
                </div>
              )}
            </>
          }
          close={() =>
            setDefaultConfig(prevConfig => ({
              ...prevConfig,
              isSavingModalVisible: false
            }))
          }
          secondButtonText="Save"
          size="sm"
          secondButtonAction={updateSettlementsConfig}
          completedHeading="Saved"
          completedDescription={`The settlement configuration ${source !== 'default' ? 'for this merchant' : ''} has been saved.`}
        />
      )}

      {defaultConfig.isResetModalVisible && (
        <Modal
          heading="Reset configuration?"
          description={
            <>
              <p className="mb-0 py-3">
                Are you sure you want to reset this merchant’s settlement configuration for this currency? This action cannot be undone.
              </p>
            </>
          }
          close={() =>
            setDefaultConfig(prevConfig => ({
              ...prevConfig,
              isResetModalVisible: false
            }))
          }
          secondButtonText="Yes, Reset"
          size="sm"
          secondButtonAction={resetConfig}
          completedHeading="Done"
          completedDescription="The settlement configuration for this merchant has been reset."
        />
      )}
    </>
  );
}

export default SettlementsConfig;
