/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useQueryStorage from '+hooks/useQueryStorage';
import APIRequest from '+services/api-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import { capitalizeRemovedash, durationMonth, durationToday, durationWeek, durationYear, formatAmount, storageDataKey } from '+utils';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import './index.scss';

const api = new APIRequest();

export default function GeneralTab({ sentinalData }) {
  const { id } = useParams();
  const { feedbackInit } = useFeedbackHandler();
  const { getQueryData } = useQueryStorage();

  const availableCurrencies = getQueryData(storageDataKey.AVAILABLE_CURRENCIES);

  const [duration, setDuration] = useState('all_time');
  const [activeCurrency, setActiveCurrency] = useState('ngn');
  const overviewDuration = {
    all_time: [null, null],
    today: durationToday(),
    last_week: durationWeek(),
    last_30_days: durationMonth(),
    last_year: durationYear()
  };

  const errorDispatch = (message) =>
    feedbackInit({
      message,
      type: 'danger'
    });

  const { data: trxnData } = useQuery(
    [`${id}_TRANSACTION_ANALYTICS`, duration, activeCurrency],
    () => api.getMerchantTransactionAnalytics(id, ...overviewDuration[duration], activeCurrency?.toUpperCase()),
    {
      keepPreviousData: true,
      onError: () => errorDispatch(`There has been an error fetching transaction analytics data.`)
    }
  );

  const { data: performanceData } = useQuery(
    [`${id}_PERF_ANALYTICS`, duration, activeCurrency],
    () => api.getMerchantPerformanceAnalytics(id, ...overviewDuration[duration], activeCurrency),
    {
      keepPreviousData: true,
      onError: () => errorDispatch(`There has been an error fetching performance analytics data.`)
    }
  );

  const { data: merchantKYCInfo } = useQuery(`${id}_MERCHANT_KYC`, () => api.getMerchant(id), {
    keepPreviousData: true,
    onError: () => errorDispatch(`There has been an error fetching merchant kyc data.`)
  });

  return (
    <div className="nav-content active">
      <div className="element-box business__info">
        <div className="element-box-heading">
          <div className="heading-box-mmd">
            <h4 className="form-header">Overview</h4>
            <div className="form-desc no-underline">Here, you’ll find the general details of this merchant.</div>
          </div>
        </div>
        <div className="content-details-box">
          <div className="content-details-body" style={{ borderTop: 'none' }}>
            <div className="element-wrapper">
              <div className="element-actions">
                <form className="form-inline justify-content-sm-end merchant-details-g-menu">
                  <select
                    className="form-control form-control-sm rounded"
                    style={{ alignSelf: 'flex-start' }}
                    onChange={(e) => setDuration(e.target.value)}
                  >
                    {Object.keys(overviewDuration).map((key) => (
                      <option value={key} key={key}>
                        {capitalizeRemovedash(key)}
                      </option>
                    ))}
                  </select>

                  <CurrencyPicker
                    options={availableCurrencies}
                    onChange={(value) => {
                      setActiveCurrency(value.toLowerCase());
                    }}
                    className="business-settings__currency-switch"
                    activeCurrency={activeCurrency.toUpperCase()}
                    label={null}
                  />
                </form>
              </div>
              <h6 className="element-header mb-4">Transaction Overview</h6>
            </div>
            <div className="detail-box">
              <div className="invoice-body">
                <div className="invoice-desc">
                  <div className="el-tablo">
                    <div className="label">Total Pay-ins</div>
                    <div className="value">{formatAmount(trxnData?.data?.payinsTotal || 0)}</div>
                  </div>
                </div>
                {/* <div className="invoice-desc">
                  <div className="el-tablo">
                    <div className="label">Total Payouts</div>
                    <div className="value">{formatAmount(trxnData?.data?.payoutsTotal || 0)}</div>
                  </div>
                </div> */}
                <div className="invoice-desc">
                  <div className="el-tablo">
                    <div className="label">Success Rate</div>
                    <div className="value">{performanceData?.data?.successRate || 0}%</div>
                  </div>
                </div>
                <div className="invoice-desc">
                  <div className="el-tablo">
                    <div className="label">Transaction Count</div>
                    <div className="value">{performanceData?.data?.allTransactionsCount || 0}</div>
                  </div>
                </div>
                <div className="invoice-desc">
                  <div className="el-tablo">
                    <div className="label">Successful Transactions</div>
                    <div className="value">{performanceData?.data?.successfulTransactionsCount || 0}</div>
                  </div>
                </div>
                <div className="invoice-desc">
                  <div className="el-tablo">
                    <div className="label">Failed Transactions</div>
                    <div className="value text-danger">{performanceData?.data?.failedTransactionsCount || 0}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-details-body mb-3">
            <div className="detail-box">
              <div className="invoice-body smaller">
                <div className="invoice-desc">
                  <div className="desc-label">Business Description</div>
                  <div className="desc-value">{merchantKYCInfo?.description || 'Not available'}</div>
                </div>
                <div className="invoice-desc">
                  <div className="desc-label">Business Email</div>
                  <div className="desc-value">{merchantKYCInfo?.email || 'Not available'}</div>
                </div>
                <div className="invoice-desc">
                  <div className="desc-label">Website</div>
                  <div className="desc-value">
                    <a href={merchantKYCInfo?.website || ''} target="_blank" rel="noopener noreferrer">
                      {merchantKYCInfo?.website || 'Not available'}
                    </a>
                  </div>
                </div>
                <div className="invoice-desc">
                  <div className="desc-label">Phone</div>
                  <div className="desc-value">{merchantKYCInfo?.support_phone || 'Not available'}</div>
                </div>
                {sentinalData?.classification &&
                  <div className="invoice-desc">
                    <div className="desc-label">Sentinal Classification</div>
                    <div className="desc-value">{sentinalData?.classification || 'Not available'}</div>
                  </div>
                }
                {sentinalData?.identifier &&
                  <div className="invoice-desc">
                    <div className="desc-label">Sentinal ID</div>
                    <div className="desc-value">{sentinalData?.identifier || 'Not available'}</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
