import { getDate, getTime } from '+utils';
import React from 'react';

// import logo from '+assets/theme/img/pactis-circled-logo.png';

interface IComplianceFeedbacksProps {
  details?: Record<string, any> | null;
  tabs?: Record<string, any> | null;
}
export default function ComplianceFeedbacks({ details, tabs }: IComplianceFeedbacksProps) {
  const {
    documents: docsFeedback = [],
    settlement_accounts: settlementsFeedback = [],
    representatives: repsFeedback = [],
    business_profile: profileFeedback = []
  } = details?.feedback ?? {};

  const rejectedReasonFeedback = () => {
    if (!details?.rejected_reason) return null;
    return (
      <div className="feedback-group">
        <div className="feedback-group__logo">
          {/* <p className='text-black-50'>BvnkNG</p> */}
          {/* <img alt="" src={logo} /> */}
        </div>
        <div className="feedback-group__details">
          <span>
            {getDate(details?.last_review_date)} {getTime(details?.last_review_date)}
          </span>
          <span>
            {' '}
            <b>KYC Rejected</b> - {details?.rejected_reason}
          </span>
        </div>
      </div>
    );
  };

  const getFeedbackCount = (list) => list?.length + (details?.rejected_reason ? 1 : 0);

  return (
    <>
      {tabs.activeKycTab === 'business_profile' && (
        <div>
          {profileFeedback?.length > 0 || Boolean(details?.rejected_reason) ? (
            <div className="feedback-kyc">
              <p className="feedback-kyc__text">Feedback sent ({getFeedbackCount(profileFeedback)})</p>
              {rejectedReasonFeedback()}
              {profileFeedback.map((feedback) => (
                <div className="feedback-group" key={feedback.date_added}>
                  <div className="feedback-group__logo">
                    <img alt="" src={logo} />
                  </div>
                  <div className="feedback-group__details">
                    <span>
                      {getDate(feedback.date_added)} {getTime(feedback.date_added)}
                    </span>
                    <span>{feedback.note}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="feedback-kyc">
              <p className="feedback-kyc__text">No feedback has been sent</p>
            </div>
          )}
        </div>
      )}

      {tabs.activeKycTab === 'documents' && (
        <div>
          {docsFeedback?.length > 0 || Boolean(details?.rejected_reason) ? (
            <div className="feedback-kyc">
              <p className="feedback-kyc__text">Feedback sent ({getFeedbackCount(docsFeedback)})</p>
              {rejectedReasonFeedback()}
              {docsFeedback.map((feedback) => (
                <div className="feedback-group" key={feedback.date_added}>
                  <div className="feedback-group__logo">
                    <img alt="" src={logo} />
                  </div>
                  <div className="feedback-group__details">
                    <span>
                      {getDate(feedback.date_added)} {getTime(feedback.date_added)}
                    </span>
                    <span>{feedback.note}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="feedback-kyc">
              <p className="feedback-kyc__text">No feedback has been sent</p>
            </div>
          )}
        </div>
      )}

      {tabs.activeKycTab === 'representatives' && (
        <div>
          {repsFeedback?.length > 0 || Boolean(details?.rejected_reason) ? (
            <div className="feedback-kyc">
              <p className="feedback-kyc__text">Feedback sent ({getFeedbackCount(repsFeedback)})</p>
              {rejectedReasonFeedback()}
              {repsFeedback.map((feedback) => (
                <div className="feedback-group" key={feedback.date_added}>
                  <div className="feedback-group__logo">
                    <img alt="" src={logo} />
                  </div>
                  <div className="feedback-group__details">
                    <span>
                      {getDate(feedback.date_added)} {getTime(feedback.date_added)}
                    </span>
                    <span>{feedback.note}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="feedback-kyc">
              <p className="feedback-kyc__text">No feedback has been sent</p>
            </div>
          )}
        </div>
      )}

      {tabs.activeKycTab === 'settlement_accounts' && (
        <div>
          {settlementsFeedback?.length > 0 || Boolean(details?.rejected_reason) ? (
            <div className="feedback-kyc">
              <p className="feedback-kyc__text">Feedback sent ({getFeedbackCount(settlementsFeedback)})</p>
              {rejectedReasonFeedback()}
              {settlementsFeedback.map((feedback) => (
                <div className="feedback-group" key={feedback.date_added}>
                  <div className="feedback-group__logo">
                    <img alt="" src={logo} />
                  </div>
                  <div className="feedback-group__details">
                    <span>
                      {getDate(feedback.date_added)} {getTime(feedback.date_added)}
                    </span>
                    <span>{feedback.note}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="feedback-kyc">
              <p className="feedback-kyc__text">No feedback has been sent</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
