import useBreakpoint from '+hooks/useBreakPoint';
import useClickOutside from '+hooks/useClickOutside';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import Modal from './Modal';
import './TabSwitch.scss';

interface ITabOption {
  [key: string]: string;
}
interface ITabSwitchPropsBase {
  setTab: (tab: string | ITabOption) => void;
  activeTab: string;
  className?: string;
  id?: string;
  maxVisibleOptions?: number;
  tabCount?: Record<string, number>;
  modalHeader?: string;
}
interface ITabSwitchPropsWithObject extends ITabSwitchPropsBase {
  options: Array<ITabOption>;
  identifier: string;
}
interface ITabSwitchPropsWithoutObject extends ITabSwitchPropsBase {
  options: Array<string>;
  identifier?: string;
}
export type ITabSwitchPropsType = ITabSwitchPropsWithObject | ITabSwitchPropsWithoutObject;

const queries = {
  isMobile: '(max-width: 650px)'
};

const TabSwitch: React.FC<ITabSwitchPropsType> = ({
  options,
  activeTab,
  setTab,
  id,
  identifier = '',
  className,
  maxVisibleOptions,
  tabCount,
  modalHeader = 'Select an option'
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [modalSelectedItem, setModalSelectedItem] = useState<ITabOption | string>(activeTab);
  const breakpoint = useBreakpoint(queries);
  const isMobile = (breakpoint as unknown as { isMobile: boolean })?.isMobile;

  const wrapperRef = useClickOutside(() => {
    setDropdownVisible(false);
  });

  const tabsWithMaxVisibleOptions = isMobile ? 3 : maxVisibleOptions;
  const visibleOptions = useMemo(() => {
    let visible = options?.slice(0, tabsWithMaxVisibleOptions);
    if (visible?.length > 0 && !visible?.includes(activeTab as ITabOption & string) && tabsWithMaxVisibleOptions) {
      visible = [...visible.slice(0, tabsWithMaxVisibleOptions - 1), activeTab] as ITabOption[] | string[];
    }
    return visible;
  }, [options, tabsWithMaxVisibleOptions, activeTab]);
  const hasDropdown = visibleOptions?.length < options?.length;

  useEffect(() => {
    if (!isMobile) {
      setDropdownVisible(false);
    }
  }, [activeTab]);

  const getOptionText = (option: ITabOption | string) => (typeof option === 'object' ? option[identifier] : option);

  const toggleDropdown = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  const handleModalItemSelect = () => {
    setTab(modalSelectedItem);
    toggleDropdown();
  };

  return (
    <div className={`tab-switch ${className} tab-switch__dropdown`} id={id} ref={wrapperRef as RefObject<HTMLDivElement>}>
      <ul className="tab-switch__list">
        {visibleOptions?.map((option) => (
          <li key={getOptionText(option)}>
            <button
              type="button"
              className={`tab-switch__button ${getOptionText(option) === activeTab ? 'active' : ''}`}
              onClick={() => setTab(option)}
            >
              {getOptionText(option)}{' '}
              {tabCount && tabCount?.[getOptionText(option)] > 0 && (
                <span className="tab-switch__count">{tabCount?.[getOptionText(option)]}</span>
              )}
            </button>
          </li>
        ))}
        {hasDropdown && (
          <button className="tab-switch__button dropdown" type="button" onClick={toggleDropdown} data-testid="dropdown">
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="#7447FD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        )}
      </ul>

      {hasDropdown && dropdownVisible && !isMobile && (
        <div className="tab-switch__dropdown__content" role="menu">
          {options?.map((option) => (
            <button
              type="button"
              className={`tab-switch__button ${getOptionText(option) === activeTab ? 'active' : ''}`}
              onClick={() => setTab(option)}
              key={getOptionText(option)}
              role="menuitem"
              aria-label={`Select ${getOptionText(option)}`}
            >
              {getOptionText(option)}
            </button>
          ))}
        </div>
      )}

      {hasDropdown && dropdownVisible && isMobile && (
        <Modal
          heading={modalHeader}
          size="sm"
          secondButtonText="Select"
          secondButtonAction={handleModalItemSelect}
          close={toggleDropdown}
          isScrollable
          secondButtonActionIsTerminal
          description=""
          content={
            <div className="tab-switch__modal" role="menu">
              {options?.map((item) => {
                const isSelected = getOptionText(modalSelectedItem) === getOptionText(item);
                return (
                  <button
                    type="button"
                    role="menuitem"
                    key={getOptionText(item)}
                    className={`tab-switch__modal__item ${isSelected ? 'active' : ''}`}
                    onClick={() => setModalSelectedItem(item)}
                    aria-label={`Select ${getOptionText(item)}`}
                  >
                    <div>
                      <span>{getOptionText(item)}</span>&nbsp;
                      {tabCount && tabCount?.[getOptionText(item)] > 0 && (
                        <span className="tab-switch__count">{tabCount?.[getOptionText(item)]}</span>
                      )}
                    </div>

                    <input type="radio" checked={isSelected} readOnly />
                  </button>
                );
              })}
            </div>
          }
        />
      )}
    </div>
  );
};
export default TabSwitch;
